import React from "react"
import { DepartmentSubHeaderWrapper, DepartmentSubHeaderContainer, DepartmentsSubHeaderItem, DepartmentsSubHeaderItemText, DropdownBtn, DepartmentPopupWrapper, DepartmentPopup, DepartmentIconWrapper, FormWrapper, DepartmentPopupTitle, DepartmentCancelBtn, DepartmentAddBtn, Control, DepartmentSubHeaderSchedule, TableFooterRow, TableEditBtn } from "../branch.presets"
import { Icon } from "../../../icons/icon"
import { observer } from "mobx-react"
import { SpecificHeader } from "../../../components/specific-header"
import { Loading } from "../../../components/spinner"
import { DailyDashboardTable } from "../components/dailyDashboardTable"
import { Form, Dropdown, Button } from "react-bootstrap"
import moment from "moment-timezone"
let status = false
@observer
export class DailyDashboardPage extends React.Component<{
  t: any
  loadMore: any
  hideLodaMore: any,
  departmentStore: any
  globalStore: any
  tab: string
  isLoading: boolean
  goToUserInfo: (e) => void
  search: (e, v, x?) => void
  addEmployee: (e) => void
  changeValue: (e,v) => void
  changePhone: (e) => void
}>{

  userData: any
  interval: any
  constructor(props){
    super(props)
    this.userData = []
    this.interval = null
  }

  state = {
    chosenItem: 1,
    type: "all",
    globalCheck: false,
    tempFlag: false,
    newDepartmentId: "",
    newDepartmentName: "",
    showMovePopup: false,
    showDeletePopup: false,
    from: "",
    to: "",
    zoneName: moment.tz.guess(),
    zoneOffset: moment().tz(moment.tz.guess()).format('Z'),
    timeZone: `(GMT${moment().tz(moment.tz.guess()).format('Z')}) ${moment.tz.guess()}`,
    reset: false
  }

  async componentDidMount() {
    await this.props.departmentStore.getReport()
  }

  onChangeTempFlag = (trigger) => this.setState({tempFlag: trigger})

  onChangeUserDataByType = (userId: string, trigger) => {
    trigger
      ? this.userData.push(userId as never)
      : this.userData = this.userData.filter(item => item !== userId)
  }

  move = async () => {
    await this.props.departmentStore.moveUserToDepartment(this.state.newDepartmentId, this.userData)
    this.userData = []
    this.setState({showMovePopup: false, reset: true}, () => {
      this.setState({reset: false})
    })
  }

  delete = async () => {
    await this.props.departmentStore.removeUserFromDepartment(this.userData)
    this.userData = []
    this.setState({showDeletePopup: false, reset: true}, () => {
      this.setState({reset: false})
    })
  }

  getReport = async () => {
    const {reportStatus, generateReport, getReport, reportUrl} = this.props.departmentStore
    if(reportStatus === "undefined"){
      const isProcess = await generateReport()
      if(isProcess) {
        this.interval = setInterval(async () => {
          if(!status){
            status = await getReport()
          } else {
            status = false
            clearInterval(this.interval)
          }
        }, 2000)
      }
    }
    if(reportStatus === "completed") {
      window.open(reportUrl)
    }
  }

  handleChange = (e) => {
    if(!e) return
    const zoneOffset = moment().tz(e).format("Z")
    const zoneName = e
    this.setState({
      timeZone: `(GMT${zoneOffset}) ${zoneName}`.replace("_", " "),
      zoneOffset,
      zoneName: e
    })
  }

  renderMovePopup = () => {
    const {currentDepartment} = this.props.globalStore
    const {moveList} = this.props.departmentStore
    //@ts-ignore
    const CustomToggle: any = React.forwardRef(({ onClick, children }, ref) => (
      <DropdownBtn
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        id="dropdown-basic"
      >
        {children}
      </DropdownBtn>
    ));
    const {t} = this.props
    return this.state.showMovePopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showMovePopup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
              {t("dashboard.branch.dailyDashboard.popup.move.title")}
            </DepartmentPopupTitle>
            <div style={{
              fontSize: "14px",
              color: "#212121",
              textAlign: "center",
              paddingBottom: "25px"
            }}>{t("dashboard.branch.dailyDashboard.popup.move.subTitle")}</div>
            <Form.Group controlId="formGroupScheduleFrom">
              <Form.Label style={{color: "#212121"}}>
                {t("dashboard.branch.dailyDashboard.popup.from")}
              </Form.Label>
              <Control
                showerror={"false"}
                type="text"
                style={{color: "#616161"}}
                placeholder={currentDepartment ? currentDepartment.name : ""}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formGroupScheduleTo">
              <Form.Label style={{color: "#212121"}}>
                {t("dashboard.branch.dailyDashboard.popup.to")}
              </Form.Label>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  {this.state.newDepartmentName || "Choose a department"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {moveList.map((item: any) => <Dropdown.Item
                  onClick={() => this.setState({newDepartmentId: item.id, newDepartmentName: item.name})}
                  >{item.name}</Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentCancelBtn
              variant="outline-secondary"
              onClick={() => {
                this.setState({showMovePopup: false})
              }}
            >{t("dashboard.cancel")}</DepartmentCancelBtn>
            <DepartmentAddBtn
              variant="primary"
              onClick={() => this.move()}
              disabled={!(this.state.newDepartmentId.length)}
            >{t("dashboard.branch.dailyDashboard.popup.move.btn")}</DepartmentAddBtn>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  renderDeletePopup = () => {
    const {t} =this.props
    return this.state.showDeletePopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showDeletePopup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
            {t("dashboard.branch.dailyDashboard.popup.delete.title1")} {this.props.globalStore.currentDepartment.name} {t("dashboard.branch.dailyDashboard.popup.delete.title2")}?
            </DepartmentPopupTitle>
            <div style={{
              fontSize: "14px",
              color: "#212121",
              textAlign: "center",
              paddingBottom: "25px"
            }}>{t("dashboard.branch.dailyDashboard.popup.delete.subTitle")}</div>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentCancelBtn
              variant="outline-secondary"
              onClick={() => {
                this.setState({showDeletePopup: false})
              }}
            >{t("dashboard.cancel")}</DepartmentCancelBtn>
            <Button
              variant="outline-danger"
              onClick={() => this.delete()}
            >{t("dashboard.branch.dailyDashboard.popup.delete.btn")}</Button>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  renderDepartmentSubHeader = () => {
    const {employees, filterBy} = this.props.departmentStore
    const {t} = this.props
    const items = [
      {
        id: 1,
        name: `${t("dashboard.branch.dailyDashboard.statuses.all")} • ${employees.length}`,
        background: "rgba(56, 105, 158, 0.1)",
        color: "#38699E",
        border: "",
        icon: "list",
        onClick: () => {
          this.setState({chosenItem: 1, type: "all"})
          filterBy("all")
        }
      },{
        id: 2,
        name: `${t("dashboard.branch.dailyDashboard.statuses.risk")} • ${employees.filter(item => item.status === "risk").length}`,
        background: "rgba(191, 54, 12, 0.1)",
        color: "#BF360C",
        border: "",
        icon: "risk",
        onClick: () => {
          this.setState({chosenItem: 2, type: "risk"})
          filterBy("risk")
        }
    }
      // },{
      //   id: 3,
      //   name: `${t("dashboard.branch.dailyDashboard.statuses.normal")} • ${employees.filter(item => item.status === "normal").length}`,
      //   background: "rgba(46, 125, 50, 0.1)",
      //   color: "#2E7D32",
      //   border: "",
      //   icon: "normal",
      //   onClick: () => {
      //     this.setState({chosenItem: 3, type: "normal"})
      //     filterBy("normal")
      //   }
      // },{
      //   id: 4,
      //   name: `${t("dashboard.branch.dailyDashboard.statuses.pending")} • ${employees.filter(item => item.status === "pending").length}`,
      //   background: "white",
      //   color: "#38699E",
      //   border: "0.5px solid rgba(0,0,0,0.1)",
      //   icon: "pending",
      //   onClick: () => {
      //     this.setState({chosenItem: 4, type: "pending"})
      //     filterBy("pending")
      //   }
      // }
    ]
    return(
      <DepartmentSubHeaderWrapper>
        <DepartmentSubHeaderSchedule>

        </DepartmentSubHeaderSchedule>
        <DepartmentSubHeaderContainer>
          {items.map(item => (
            <DepartmentsSubHeaderItem
              key={item.id}
              background={item.background}
              border={item.id === this.state.chosenItem ? "1.5px solid #38699E" : item.border}
              onClick={() => item.onClick()}
            >
              <Icon icon={item.icon as any}/>
              <DepartmentsSubHeaderItemText color={item.color}>{item.name}</DepartmentsSubHeaderItemText>
            </DepartmentsSubHeaderItem>
          ))}
        </DepartmentSubHeaderContainer>
      </DepartmentSubHeaderWrapper>
    )
  }

  renderDailyDashboardTable = () => {
    return(
      <DailyDashboardTable
        t={this.props.t}
        reset={this.state.reset}
        onChangeTempFlag={this.onChangeTempFlag}
        changeUserData={(user,trigger) => this.onChangeUserDataByType(user,trigger)}
        globalCheck={this.state.globalCheck}
        changeGlobalCheck={(e) => this.setState({globalCheck: e})}
        departmentStore={this.props.departmentStore}
        type={this.state.type}
        users={this.userData.length}
        goToUserInfo={this.props.goToUserInfo}
      />
    )
  }

  renderDepartmentHeader = () => {
    const { employees, reportStatus } = this.props.departmentStore
    const {t} = this.props
    return(
      <SpecificHeader
        t={t}
        title={employees.length ? `${employees.length} ${t("dashboard.branch.dailyDashboard.employeeText")}` : t("dashboard.branch.dailyDashboard.noEmployeesText")}
        showSearch={!!employees.length}
        onChangeSearch={(e) => this.props.search(e, "sortedEmployees", this.state.type)}
        onClick={() => this.setState({showAddEmployeePopup: true})}
        searchPlaceholder={"Search Employee"}
        showReport={false}
        getReport={this.getReport}
        reportStatus={reportStatus}
        btnText={t("dashboard.branch.dailyDashboard.addTitle")}
        showBtn={false}
        firstBtn={{title: `${t("dashboard.branch.dailyDashboard.fBtn")} • ${this.userData.length}`, onClick: () => this.setState({showMovePopup: true})}}
        secondBtn={{title: `${t("dashboard.branch.dailyDashboard.sBtn")} • ${this.userData.length}`, onClick: () => this.setState({showDeletePopup: true})}}
        showBtns={this.userData.length}
      />
    )
  }

  renderLoadMore = () => {
    return(
      <TableFooterRow>
        <TableEditBtn
          variant="outline-secondary"
          onClick={() => {
            this.props.loadMore()
          }}
        >{this.props.t("dashboard.loadMoreBtn")}</TableEditBtn>
      </TableFooterRow>
    )
  }

  render(){
    return(
      <>
      {this.renderDepartmentSubHeader()}
      {this.renderDepartmentHeader()}
      {this.props.isLoading ? <Loading/> : this.renderDailyDashboardTable()}
      {!this.props.hideLodaMore && this.renderLoadMore()}
      {this.renderMovePopup()}
      {this.renderDeletePopup()}
      </>
    )
  }
}