import { types, getEnv, getRoot } from "mobx-state-tree"
import { Environment } from "../../app/environment"
import { RootStore } from "../../app/root-store"
import moment from "moment"

export const MedicalUserStoreModel = types.model("MedicalUserStore")
  .props({
    measurements: types.optional(types.frozen(), []),
    saturations: types.optional(types.frozen(), []),
    bp: types.optional(types.frozen(), []),
    hr: types.optional(types.frozen(), []),
    ecg: types.optional(types.frozen(), []),
    reminders: types.optional(types.frozen(), []),
    filtersInfo: types.optional(types.frozen(), []),
    serial_number: types.optional(types.frozen(), ''),
    deviceError: types.optional(types.frozen(), ''),
    showAddDevicePopup: types.optional(types.frozen(), false),
    listPatientsDevices: types.optional(types.frozen(), []),
    showDisconnectDevicePopup: types.optional(types.frozen(), false),
  })
  .actions((self) => ({
    setFiltersInfo(filtersInfo) {
      self.filtersInfo = filtersInfo
    },
    setReminders(reminders) {
      self.reminders = reminders
    },
    setMeasurements(measurements){
      self.measurements = measurements
    },
    setSaturations(saturations){
      self.saturations = saturations
    },
    setBp(bp) {
      self.bp = bp
    },
    setHeartRate(hr) {
      self.hr = hr
    },
    setECG(ecg) {
      self.ecg = ecg
    },
    setSerialNumber(sn) {
      self.serial_number = sn
    },
    setDeviceError(de) {
      self.deviceError = de
    },
    setListPatientsDevices(list) {
      self.listPatientsDevices = list
    },
    setShowAddDevicePopup(value) {
      self.showAddDevicePopup = value
    },
    setShowDisconnectDevicePopup(value) {
      self.showDisconnectDevicePopup = value
    },
  }))
  .actions((self) => ({
    async getTemperature(type: "day" | "month" | "year", date) {
      const getDateByType = () => {
        switch(type) {
          case "day": return moment(date).format("YYYY-MM-DD");
          case "month": return moment(date).format("YYYY-MM");
          case "year": return moment(date).format("YYYY");
        }
      }
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentEmployee} = root.globalStore
      if(!currentEmployee) return
      const dataReq = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
        date: getDateByType(),
        type
      }
      const managerResp = await env.api.measurement.getTemperature(dataReq)
      if(managerResp) {
        const data = managerResp.data
        if(!data) return
        self.setMeasurements(data.data)
      }
    },

    async getSaturation(type: "day" | "month" | "year", date) {
      const getDateByType = () => {
        switch(type) {
          case "day": return moment(date).format("YYYY-MM-DD");
          case "month": return moment(date).format("YYYY-MM");
          case "year": return moment(date).format("YYYY");
        }
      }
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentEmployee} = root.globalStore
      if(!currentEmployee) return
      const dataReq = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
        date: getDateByType(),
        type
      }
      const resp = await env.api.dashboard.getSaturation(dataReq)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setSaturations(data.data)
      }
    },

    async getBp(type: "day" | "month" | "year", date) {
      const getDateByType = () => {
        switch(type) {
          case "day": return moment(date).format("YYYY-MM-DD");
          case "month": return moment(date).format("YYYY-MM");
          case "year": return moment(date).format("YYYY");
        }
      }
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentEmployee} = root.globalStore
      if(!currentEmployee) return
      const dataReq = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
        date: getDateByType(),
        type
      }
      const resp = await env.api.dashboard.getBP(dataReq)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setBp(data.data)
      }
    },

    async getHeartRate(type: "day" | "month" | "year", date) {
      const getDateByType = () => {
        switch(type) {
          case "day": return moment(date).format("YYYY-MM-DD");
          case "month": return moment(date).format("YYYY-MM");
          case "year": return moment(date).format("YYYY");
        }
      }
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentEmployee} = root.globalStore
      if(!currentEmployee) return
      const dataReq = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
        date: getDateByType(),
        type
      }
      const resp = await env.api.dashboard.getHeartRate(dataReq)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setHeartRate(data.data)
      }
    },

    async getECG(type: "day" | "month" | "year", date) {
      const getDateByType = () => {
        return moment(date).format("YYYY-MM")
      }
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentEmployee} = root.globalStore
      if(!currentEmployee) return
      const dataReq = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
        date: getDateByType(),
        type
      }
      const resp = await env.api.dashboard.getECG(dataReq)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setECG(data.data)
      }
    },
    async getReminders() {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const data = {
        id_user: root.globalStore.currentEmployee.unique_id
      }
      const resp = await env.api.dashboard.getReminders(data)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setReminders(data.data)
      }
    },
    async getFilters() {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const data = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id
      }
      const resp = await env.api.dashboard.getFilters(data)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setFiltersInfo(data.data)
      }
    },
    async setICD(code) {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const data = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
        code
      }
      const resp = await env.api.dashboard.setICDCode(data)
      if(resp) {
        const data = resp.data
        if(!data) return
        root.branchStore.goToUserInfo({
          id: root.globalStore.currentEmployee.unique_id,
          screening: root.globalStore.currentEmployee.screening,
          status: root.globalStore.currentEmployee.status
        })
      }
    },
    async setBilling(code) {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const data = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
        code
      }
      const resp = await env.api.dashboard.setBillingCode(data)
      if(resp) {
        const data = resp.data
        if(!data) return
        root.branchStore.goToUserInfo({
          id: root.globalStore.currentEmployee.unique_id,
          screening: root.globalStore.currentEmployee.screening,
          status: root.globalStore.currentEmployee.status
        })
      }
    },
    async setFilters(filters) {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      filters.id_user = root.globalStore.currentEmployee.unique_id
      const resp = await env.api.dashboard.setFilters(filters)
      if(resp) {
        const data = resp.data
        if(!data) return
        root.branchStore.goToUserInfo({
          id: root.globalStore.currentEmployee.unique_id,
          screening: root.globalStore.currentEmployee.screening,
          status: root.globalStore.currentEmployee.status
        })
      }
    },
    async isDeviceAlreadyExist() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_company: root.globalStore.currentCompany.id,
        serial_number: self.serial_number,
      };

      const resp = await env.api.device.isDeviceExist(data)
      if(resp) {
        if (!resp.data.data.is_exists) {
          self.setDeviceError('Device is not exist');
          return;
        }

        if (!resp.data.data.is_connectable) {
          self.setDeviceError('Device is not connectable');
          return;
        }

        if (resp.data.data.is_exists && resp.data.data.is_connectable) {
          const data = {
            id_unique: root.globalStore.currentEmployee.unique_id,
            id_company: root.globalStore.currentCompany.id,
            serial_number: self.serial_number,
          };
    
          const isDeviceConnected = await env.api.device.connectDevice(data);

          if (isDeviceConnected.data.data.device_type === "mawi_patch") {
            await env.api.dashboard.createPatientRecordPatch({
              id_unique: root.globalStore.currentEmployee.unique_id,
              id_department: root.globalStore.currentDepartment.id,
              device_serial_number: self.serial_number,
              type: isDeviceConnected.data.data.product_type,
              device_type: isDeviceConnected.data.data.device_type,
              duration: isDeviceConnected.data.data.max_duration,
            })
          }

          if (isDeviceConnected.data) {
            const data = {
              id_unique: root.globalStore.currentEmployee.unique_id,
            };
      
            const resp = await env.api.device.listPatientsDevices(data)
            if(resp) {
              const data = resp.data
              if(!data) return
              self.setDeviceError('');
              self.setSerialNumber('');
              self.setShowAddDevicePopup(false);
              self.setListPatientsDevices(data.data)
            }
          }
        }
      }
    },
    async connectDeviceToPatient() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_unique: root.globalStore.currentEmployee.unique_id,
        id_company: root.globalStore.currentCompany.id,
        serial_number: self.serial_number,
      };

      const resp = await env.api.device.connectDevice(data)
      if(resp) {
        console.log('connectDevice', resp)
      }
    },
    async getListPatientsDevices() {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const data = {
        id_unique: root.globalStore.currentEmployee.unique_id,
      };

      const resp = await env.api.device.listPatientsDevices(data)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setListPatientsDevices(data.data)
      }
    },
    async disconnectDeviceFromPatient() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_unique: root.globalStore.currentEmployee.unique_id,
        id_company: root.globalStore.currentCompany.id,
        serial_number: self.serial_number,
      };

      const resp = await env.api.device.disconnectDevice(data)
      if(resp) {
        const data2 = resp.data
        if(!data2) return

        if (data2) {
          const resp2 = await env.api.device.listPatientsDevices(data)

          if(resp2) {
            const data3 = resp2.data
            if(!data3) return
            self.setSerialNumber('');
            self.setShowDisconnectDevicePopup(false);
            self.setListPatientsDevices(data3.data)
          }
        }
      }
    },
  }))
  .actions((self) => ({
    async updateReminders(reminders) {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const data = {
        reminders,
        id_user: root.globalStore.currentEmployee.unique_id
      }
      const resp = await env.api.dashboard.setReminders(data)
      if(resp) {
        const data = resp.data
        if(!data) return
        await self.getReminders()
      }
    }
  }))

export type MedicalUserStore = typeof MedicalUserStoreModel.Type