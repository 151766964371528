import React from 'react'
import { Chart } from 'react-charts'

export default function ChartComponent({measurements, typeChart, t}) {
  const renderData = measurements.map(item => {
    return {x: typeChart === "Day" ? new Date(item.date).getHours() : new Date(item.date), y: item.temperature, zone: item.zone}
  })
  const data = [
    {
      label: t("dashboard.user.healthboard.thirdTitle"),
      data: renderData
    }
  ]

  const defs = (
    <defs>
      <linearGradient id="gradient" x1="0" x2="0" y1="1" y2="0">
        <stop offset="0%" stopColor="green" />
        <stop offset="100%" stopColor="#df4081" />
      </linearGradient>
    </defs>
  )

  // const getSeriesStyle = React.useCallback(
  //   series => ({
  //     color: `url(#gradient)`,
  //     opacity: series.index === 1 ? 0 : 1
  //   }),
  //   []
  // )

  // const getDatumStyle = React.useCallback(
  //   datum => ({
  //     strokeWidth: 5,
  //     color: datum.originalDatum.zone
  //   }),
  //   []
  // )

  const calcDate = () => {
    switch(typeChart) {
      case "Day": return {hardMin: 0 , hardMax: 24};
      case "Month": return {hardMin: new Date().setDate(-1) , hardMax: new Date().setDate(31)};
      case "Year": return {hardMin: new Date().setMonth(-1) , hardMax: new Date().setMonth(12)};
      default: return {hardMin: new Date().setHours(0) , hardMax: new Date().setHours(24)};
    }
  }

  const axes = [
    { primary: true, type: typeChart === "Day" ? "linear" : 'time', position: 'bottom', ...calcDate() },
    { type: 'linear', position: 'right', hardMin: 30, hardMax: 44 }
  ]

  const lineChart = (
    <div
      style={{
        paddingTop: "20px",
        width: '100%',
        height: '350px'
      }}
    >
      <Chart
        tooltip
        data={data}
        axes={axes}
        getSeriesStyle={series => ({
          color: `url(#gradient)`,
          opacity: series.index === 1 ? 0 : 1
        })}
        getDatumStyle={datum => ({
          strokeWidth: 5,
          color: datum.originalDatum.zone
        })}
        renderSVG={() => defs}
      />
    </div>
  )
  return lineChart
}