import { ApiBase } from "../api-base"

export class ReportsApi extends ApiBase {

  get apiName() {
    return "reports"
  }

  async getReport(data) {
    return await this.post("/get_daily_report/", { body: data }, true, "data")
  }

  async generateReport(data) {
    return await this.post("/generate_daily_report/", { body: data }, true, "data")
  }

}