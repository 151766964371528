import React from "react"
import { renderModuleRouting } from "../utils/renderHelpers"
import { Router } from "react-router"
import { History } from "history"
import { GlobalStore } from "../modules/global/global.store"
import { AppRoutes } from "./app.routes"


export class NavigationRouter extends React.Component<{ navigation: History<any>, globalStore: GlobalStore }> {

  render() {
    AppRoutes.forEach(item => {
      if(item.path === "/"){
        if(localStorage.token){
          const { role, account_type } = this.props.globalStore.user
          role === "owner"
          ? item.redirect = "/companies"
          : item.redirect = account_type === 'rpm' ? "/branches" : "/departments"
        }
      }
    })
    return(
      <Router history={this.props.navigation}>
        {renderModuleRouting("", AppRoutes)}
      </Router>
    )
  }
}