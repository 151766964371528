import * as styledComponents from "styled-components"
import { ThemedStyledComponentsModule } from "styled-components"

import { DGTheme } from "./theme"

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as ThemedStyledComponentsModule<DGTheme>

export { css, createGlobalStyle, keyframes, ThemeProvider, styled }

