import i18next from "i18next";
import { initReactI18next } from "react-i18next"
import detector from "i18next-browser-languagedetector";
import * as resources from "./locales";

i18next
.use(detector)
.use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: resources.en
      },
      ru: {
        translation: resources.ru
      }
    },
    fallbackLng: "en",
    debug: true,
    load: "languageOnly",
    detection : {
      order: ["querystring", "navigator", "htmlTag", "path", "subdomain"],
      cashes: [],
      cookieMinutes: 0,
    }
  });

  export default i18next;