import React from "react"
import { Icon } from "../../icons/icon"
import { CompaniesHeader, CompaniesTitle, CompaniesSearch, Search, IconWrapper, CompaniesButton, CompaniesButtonText, ReportButton, ReportButtonText } from "./presets"
import { Col, Button } from "react-bootstrap"
import { TableBodyCol, TableBtns, TableEditBtn } from "../../modules/department/department.presets"

interface SpecificHeaderProps {
  title: string,
  onClick: () => void,
  onChangeSearch?: (e) => void,
  searchPlaceholder: string,
  btnText: string,
  showSearch: boolean,
  showBtn?: boolean,
  firstBtn?: any,
  secondBtn?: any,
  showReport?: boolean,
  reportStatus?: string,
  getReport?: () => void,
  showBtns?: boolean,
  t: any
}

export class SpecificHeader extends React.Component<SpecificHeaderProps>{

  renderBtns = () => {
    return(
      <TableBodyCol style={{padding: 0}}>
        <TableBtns>
          <TableEditBtn variant="outline-secondary"
            onClick={this.props.firstBtn.onClick}
          >{this.props.firstBtn.title}</TableEditBtn>
          <Button variant="outline-danger"
            onClick={this.props.secondBtn.onClick}
          >{this.props.secondBtn.title}</Button>
        </TableBtns>
      </TableBodyCol>
    )
  }

  renderAllBtns = () => {
    const {
      onClick,
      btnText,
      getReport,
      reportStatus,
      t
    } = this.props
    const getReportUIByStatus = () => {
      switch(reportStatus){
        case "undefined": return {color: "#38699E", icon: "reportBlue", disabled: false, text: t("report.generate")};
        case "pending": return {color: "#757575", icon: "reportGray", disabled: true, text: t("report.preparing")};
        case "completed": return {color: "#2E7D32", icon: "reportGreen", disabled: false, text: t("report.download")};
        default: return {color: "#38699E", icon: "reportBlue", disabled: false, text: t("report.generate")};
      }
    }
    const status = getReportUIByStatus()
    if(this.props.showBtns) {
      return this.renderBtns()
    } else {
      if(this.props.showBtn) {
        return <CompaniesButton onClick={onClick}>
          <Icon icon="plus"/>
          <CompaniesButtonText>{btnText}</CompaniesButtonText>
        </CompaniesButton>
      } else if(this.props.showReport) {
        return <ReportButton variant="light" onClick={getReport} disabled={status.disabled}>
          <Icon icon={status.icon as any}/>
          <ReportButtonText color={status.color}>{status.text}</ReportButtonText>
        </ReportButton>
      }
      return null
    }
  }

  render(){
    const {
      title,
      onChangeSearch,
      searchPlaceholder,
      showSearch
    } = this.props
    return(
      <CompaniesHeader>
        <CompaniesTitle>
          {title}
        </CompaniesTitle>
        {showSearch ? <CompaniesSearch>
          <Search placeholder={searchPlaceholder} onChange={onChangeSearch}/>
          <IconWrapper>
            <Icon icon="search"/>
          </IconWrapper>
        </CompaniesSearch> : null}
        <Col style={{padding: 0, display: "flex", justifyContent: "flex-end"}}>
          {this.renderAllBtns()}
        </Col>
      </CompaniesHeader>
    )
  }
}