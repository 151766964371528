import axios from "axios"
import { getGeneralApiProblem } from "./api-problem"
import * as Types from "./api.types"
import { ErrorTracking } from "../../lib/error-tracking"
import { API_BASE_URL, API_USER_URL, API_DATA_URL, API_DEVICE_URL } from "../../app/environment-variables"
import { load, clear } from "../../lib/storage"
import { toast } from 'react-toastify'

export abstract class ApiBase {
  abstract get apiName(): string
  lastApiRequest: any = {}

  async getAuthHeaders() {
    let token: any = await load("token")
    return `JWT ${token.access}`
  }

  getUrlByType(type){
    switch(type){
      case "base": return API_BASE_URL
      case "user": return API_USER_URL
      case "data": return API_DATA_URL
      case "device": return API_DEVICE_URL
      default: return API_BASE_URL
    }
  }

  generateErrorByCode = (code) => {
    switch(code){
      case "INVALID_DATA": return "Invalid data";
      case "MISSING_DATA": return "Missing fields";
      case "NOT_EXISTS": return "Doesn't exists";
      case "ALREADY_EXISTS": return "Already exists";
      case "PASSWORD_ERROR": return "Password error";
      case "MISSING_PARENT_OBJECT": return "Missing parent object";
      case "ACCESS_FORBIDDEN": return "Access denied";
      default: return "Something went wrong";
    }
  }

  async execute(
    method: Types.ApiMethods,
    url: string,
    params: Types.ApiParameters = {},
    authorize: boolean = true,
    urlType: "device" | "base" | "user" | "data" = "base"
  ): Promise<Types.ApiResult> {
    this.lastApiRequest = {
      method, url, params, authorize
    }
    try {
      ErrorTracking.setLastApiRequest(this.apiName, url, params)
      const consistUrl = `${this.getUrlByType(urlType)}${this.apiName}${url}`
      const response: any = await axios({
        method: method as any,
        url: consistUrl,
        data: params.body,
        headers: { Authorization: authorize ? await this.getAuthHeaders() : ""}
      })
      ErrorTracking.setLastApiSuccessResponse(response)
      return { ok: true, data: response.data }
    } catch (error) {
      let errorResponse: any = getGeneralApiProblem(error.response)
      if(errorResponse.data){
        if(errorResponse.data.code === "token_not_valid") {
          clear()
          window.location.pathname = "/"
        } else {
          toast.error(errorResponse.data.code ? this.generateErrorByCode(errorResponse.data.code) : errorResponse.data.detail )
        }
      }
      ErrorTracking.setLastApiErrorResponse(errorResponse)
      return errorResponse
    }
  }

  async get(url: string, params: Types.ApiParameters = {}, authorize: boolean = true, urlType?: any) {
    return await this.execute(Types.ApiMethods.get, url, params, authorize, urlType)
  }

  async post(url: string, params: Types.ApiParameters = {}, authorize: boolean = true, urlType?: any) {
    return await this.execute(Types.ApiMethods.post, url, params, authorize, urlType)
  }

  async put(url: string, params: Types.ApiParameters = {}, authorize: boolean = true, urlType?: any) {
    return await this.execute(Types.ApiMethods.put, url, params, authorize, urlType)
  }

  async delete(url: string, params: Types.ApiParameters = {}, authorize: boolean = true, urlType?: any) {
    return await this.execute(Types.ApiMethods.delete, url, params, authorize, urlType)
  }

}
