import React from "react"
import ChartComponent from "./ecgChart"
import { Col, Row } from "react-bootstrap"
import { Btn, TableItem } from "../user.presets"
import { Icon } from "../../../icons/icon"
import moment from "moment"
import { Document, Page, pdfjs } from "react-pdf"
import { ReportButton, ReportButtonText } from "../../../components/specific-header/presets"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export class EcgChartWrapper extends React.Component<{
  measurements: any,
  recordType: string,
  setOpenPdf: any,
  supervisors: any,
  setSupervisors: any
  pdfIsOpen: boolean,
  t: any
},{
  showMore: number,
  symptoms: Array<any>,
  url: string,
  numPages: any
}>{

  state = {
    showMore: -1,
    symptoms: [],
    numPages: null,
    url: ""
  }

  renderTableHeader = (header) => {
    const style = {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: "#757575"
    }
    return(
      header.map(item => <Col style={style}>{item}</Col>)
    )
  }

  getProblem = (problem) => {
    switch(problem){
      case "normal": return <Btn bgColor={"rgba(46, 125, 50, 0.1)"} color="#2E7D32">Not Detected</Btn>;
      case "afib": return <Btn bgColor={"rgba(191, 54, 12, 0.1)"} color="#BF360C">Possible Atrial Fibrillation</Btn>;
      case "tachycardia": return <Btn bgColor={"rgba(191, 54, 12, 0.1)"} color="#BF360C">Tachycardia</Btn>;
      case "bradycardia": return <Btn bgColor={"rgba(191, 54, 12, 0.1)"} color="#BF360C">Bradycardia</Btn>;
      case "unreadable": return <Btn bgColor={"lightgray"} color="#2d2b2a">Unreadable</Btn>;
      case "unclassified": return <Btn bgColor={"lightgray"} color="#2d2b2a">Unclassified</Btn>;
      default: return <Btn bgColor={"rgba(46, 125, 50, 0.1)"} color="#2E7D32">Not Detected</Btn>
    }
  }

  getHeartRate = (heartRate) => {
    if(!heartRate) return <Btn bgColor={"rgba(191, 54, 12, 0.1)"} color="#BF360C">NaN</Btn>
    const {zone, value} = heartRate
    const title = value ? `${value.toFixed(2)} BPM` : "NaN"
    if(zone === "red") return <Btn bgColor={"rgba(191, 54, 12, 0.1)"} color="#BF360C">{title}</Btn>
    if(zone === "green") return <Btn bgColor={"rgba(46, 125, 50, 0.1)"} color="#2E7D32">{title}</Btn>
    return <Btn bgColor={"rgba(46, 125, 50, 0.1)"} color="#2E7D32">{title}</Btn>
  }

  showMore = (symptoms, index) => {
    if(symptoms.length < 4) return
    this.setState({symptoms, showMore: index})
  }

  renderSymptoms = (symptoms, sindex) => {
    const noSymptoms = {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "#757575"
    }
    const symptomStyle = {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "#212121"
    }

    const moreStyle = {
      ...symptomStyle,
      cursor: "pointer",
      color: "#007bff"
    }

    if(!symptoms.length || (symptoms.length === 1 && symptoms[0] === "-")) return <span style={noSymptoms}>No symptoms</span>
    const data = symptoms.length > 3 ? symptoms.slice(0, 3) : symptoms
    symptoms.length > 3 && data.push("more...")
    const symptomsData = data.map((text, index) => 
      <span style={text === "more..." ? moreStyle : symptomStyle} onClick={
        () => text === "more..." ? this.showMore(symptoms, sindex) : {}
      }>
        {text}{index === symptoms.length - 1 ? "" : ","} </span>
      )
    return symptomsData
  }

  renderFullSymptoms = () => {
    const symptomStyle = {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "#212121"
    }
    const symptomsData = this.state.symptoms.map((text, index) => 
      <span style={symptomStyle}>
        {text}{index === this.state.symptoms.length - 1 ? "" : ","}
      </span>
      )
    return symptomsData
  }

  renderTableBody = () => {
    let data = this.props.measurements.filter(item => item.analysis_result).map(item => {
      return {
        type: item.analysis_result.zone,
        date: moment(item.date).format("DD MMM YYYY • HH:mm"),
        problem: this.getProblem(item.analysis_result.description_key),
        heartRate: this.getHeartRate(item.heart_rate),
        url: item.report_url,
        symptoms: item.symptoms
      }
    })

    const getRecordType = () => {
      switch(this.props.recordType){
        case "all": return data
        case "red": return data.filter(item => item.type === "red")
        case "grey": return data.filter(item => item.type === "grey")
        default: return data
      }
    }

    data = getRecordType()

    const style = {
      paddingTop: "20px",
      paddingBottom: "20px"
    }

    return(
      data.map((item, index) => {
      return(
        <TableItem onClick={() => {
          this.setState({url: item.url}, () => this.props.setOpenPdf(true))
        }}>
          <Col style={style}>
            <span style={{color: "black"}}>{item.date}</span>
          </Col>
          <Col style={style}>
            <div>{item.problem}</div>
          </Col>
          <Col style={style}>
            <div>{item.heartRate}</div>
          </Col>
          <Col style={style}>
            <div>{this.renderSymptoms(item.symptoms, index)}</div>
            {this.state.showMore === index && <div
              style={{
                position: "absolute",
                background: "#FFFFFF",
                padding: "10px",
                right: 10,
                top: 5,
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
                boxShadow: "0px 5px 15px 5px rgba(0,0,0,0.15)"
              }}>
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                  <Icon icon="close" onClick={() => this.setState({showMore: -1, symptoms: []})}/>
                </div>
                {this.renderFullSymptoms()}
              </div>}
          </Col>
        </TableItem>
      )
      })
    )
  }

  renderSymptomsTable = () => {
    const header = [
      "Date",
      "Problem",
      "Heart Rate",
      "Symptoms"
    ]
    return(
      <div style={{borderTop: "1px solid #E0E0E0", marginTop: "20px", marginLeft: "-30px", marginRight: "-30px", padding: "30px"}}>
        <Row style={{borderBottom: "1px solid #E0E0E0", marginLeft: "-30px", marginRight: "-30px"}}>
          {this.renderTableHeader(header)}
        </Row>
        {this.renderTableBody()}
      </div>
    )
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  getReport = () => {
    window.open(this.state.url)
  }

  eSignature = () => {

  }

  assignProvider = () => {

  }

  changeDiagnosis = () => {

  }

  renderPDF = () => {
    return(
      <div className={"ecgPDFwrap"}>
        <div style={{ border: "1px solid rgba(0,0,0,0.1)", width: "800px" }}>
          <Document
              file={this.state.url}
              onLoadSuccess={this.onDocumentLoadSuccess}
            >
              <Page pageNumber={this.state.numPages} width={800}/>
            </Document>
        </div>
        <div>
          <div className={"ecgBtns"}>
        {/* <ReportButton variant="light" onClick={this.changeDiagnosis} style={{margin: "10px"}}>
          <Icon icon={"pen"}/>
          <ReportButtonText color={"#38699E"}>Change Diagnosis</ReportButtonText>
        </ReportButton> */}
        <ReportButton variant="light" onClick={this.getReport} style={{margin: "10px"}}>
          <Icon icon={"reportBlue"}/>
          <ReportButtonText color={"#38699E"}>Download Report</ReportButtonText>
        </ReportButton>
        {/* <ReportButton variant="light" onClick={this.eSignature} style={{margin: "10px"}}>
          <Icon icon={"sign"}/>
          <ReportButtonText color={"#38699E"}>eSignature</ReportButtonText>
        </ReportButton>
        <ReportButton variant="light" onClick={this.assignProvider} style={{margin: "10px"}}>
          <Icon icon={"people"}/>
          <ReportButtonText color={"#38699E"}>Assign Other Provider</ReportButtonText>
        </ReportButton> */}
          </div>
        </div>
      </div>
    )
  }

  render(){
    return(
      <>
      {this.props.pdfIsOpen ?
      this.renderPDF()
      : <div style={{border: "1px solid #E0E0E0", padding: "30px", borderRadius: "5px"}}>
        <ChartComponent
          recordType={this.props.recordType}
          measurements={this.props.measurements}
          t={this.props.t}
        />
        {this.renderSymptomsTable()}
      </div>}
      </>
    )
  }
}