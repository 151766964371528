import { styled } from "../../shared/theme"
import { Col, Form, Button } from "react-bootstrap"

export const CompaniesHeader = styled.div`
padding: 20px 0;
display: flex;
justify-content: space-between;
flex-direction: row;
align-items: center;
width: 100%;
`

export const CompaniesTitle = styled(Col)`
font-style: normal;
font-weight: 500;
font-size: 20px;
padding: 0;
line-height: 24px;
letter-spacing: 0.15px;
color: #757575;
`

export const CompaniesSearch = styled(Col)`
margin-top: -6px;
padding: 0;
position: relative;
`

export const Search = styled(Form.Control)`
width: 100%;
padding-left: 30px;
:focus {
  border-width: 2px;
  border-color: #38699E;
  box-shadow: none;
}
`

export const IconWrapper = styled.div`
width: 10%;
margin-top: -32px;
margin-left: 10px;
`

export const CompaniesButton = styled(Button)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
background-color: #38699E;
border: none;
:focus {
  border-width: 2px;
  background-color: #38699E;
  border-color: #38699E;
  box-shadow: none;
}
`

export const ReportButton = styled(Button)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
background-color: transparent;
border: 1px solid #E0E0E0;
:hover {
  border-radius: 4px;
  background-color: #F5F5F5;
  box-shadow: none;
}
`

export const ReminderButton = styled(Button)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
background-color: #38699E;
border: 1px solid #E0E0E0;
:hover {
  border-radius: 4px;
  background-color: #38699E;
  box-shadow: none;
}
`

export const CompaniesButtonText = styled.span`
padding: 3px;
padding-left: 10px;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
text-align: center;
color: #FFFFFF;
`

export const ReportButtonText = styled.span`
padding: 3px;
padding-left: 10px;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 19px;
text-align: center;
color: ${props => props.color};
`