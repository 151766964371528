import { GeneralApiProblem } from "../../services/api"
import _ from "lodash"

export class ErrorParsers {

  static pd(e: GeneralApiProblem) {
    let message = e.message && _.replace(e.message, "PD_", "")
    return {
      status: e.status,
      message,
      code: message,
      data: _.get(e.data, "data")
    }
  }

  static ui(code: string) {
    return {
      message: code,
      code
    }
  }
}
