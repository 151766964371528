import React from "react"
import {
  FormWrapper,
  StepTitle,
  DepartmentsCancelBtn,
  DepartmentsAddBtn,
  Control} from "../add_patient.presets"
import { Form } from "react-bootstrap"
import moment from 'moment';

class FiveStep extends React.Component<{
  goNext: (obj) => void,
  goPrev: () => void,
  fBtnTitle: string,
  sBtnTitle: string,
  data: any,
  addReminder: () => void,
  removeReminder: (index: number) => void,
  changeReminderValue: (index: number, value: string) => void
}>{

  goNext = () => {
    this.props.goNext({
      reminders: this.props.data.reminders
                  .filter(item => !!item.trigger_time)
                  .map(item => ({
                    trigger_time: moment(item.trigger_time, "HH:mm").utc().format("HH:mm")
                  }))
    })
  }

  renderStep = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Reminders
          </StepTitle>
          <div style={{
              fontSize: "14px",
              color: "#212121",
              textAlign: "center"
            }}>
              Set time when your patients will receive push notifications regarding their recordings
            </div>
          <FormWrapper>
            {
              this.props.data.reminders.map((item, i) => (
                <Form.Group controlId="formGroupScheduleFrom" key={`form-${i}`}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "25px"}}>
                <Form.Label style={{color: "#212121"}}>Time</Form.Label>
                <div 
                  style={{color: "red"}} 
                  onClick={() => this.props.removeReminder(i)}
                >
                    Delete
                </div>
                </div>
                <Control
                  showerror={"false"}
                  type="time"
                  placeholder="--:--"
                  defaultValue={item.trigger_time}
                  onChange={(e)=> this.props.changeReminderValue(i, e.target.value)}
                />
              </Form.Group>
              ))
            }
          </FormWrapper>
          <div style={{display: "flex", justifyContent: "flex-end"}}>
          <DepartmentsCancelBtn
            variant="outline-secondary"
            onClick={() => this.props.addReminder()}
          >
            Add
          </DepartmentsCancelBtn>
          </div>
        </FormWrapper>
      </div>
    )
  }

  renderBtns = () => {
    return(
      <div style={{width: "400px", margin: "20px auto", display: "flex", justifyContent: "space-between"}}>
        <DepartmentsCancelBtn
          variant="outline-secondary"
          onClick={() => this.props.goPrev()}
        >
          {this.props.fBtnTitle}
        </DepartmentsCancelBtn>
        <DepartmentsAddBtn
          variant="primary"
          onClick={() => this.goNext()}
          disabled={false}
        >
          {this.props.sBtnTitle}
        </DepartmentsAddBtn>
      </div>
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  render(){
    return <>
      {this.renderStep()}
      {this.line()}
      {this.renderBtns()}
    </>
  }
}

export default FiveStep