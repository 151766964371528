import { types, getEnv, getRoot } from "mobx-state-tree"
import { CompaniesDataModel, NewCompanyDataModel, constructCompanies, SortedCompaniesDataModel } from "./types/dashboard.model"
import _ from "lodash"
import { toJS } from "mobx"
import { Environment } from "../../app/environment"
import { RootStore } from "../../app/root-store"

export const CompaniesStoreModel = types.model("CompaniesStore")
  .props({
    companies: types.optional(types.array(CompaniesDataModel), []),
    sortedCompanies: types.optional(types.array(SortedCompaniesDataModel), []),
    newCompany: types.optional(NewCompanyDataModel, {}),
  })
  .volatile(() => ({
    sortedType: "asc",
    sortedValue: "",
    defaultLenght: 7
  }))
  .actions((self) => ({
    setNewCompany(newCompany) {
      self.companies = [newCompany, ...self.companies] as any
    },
    setNewCompanyData(value: string, type: string) {
      self.newCompany[type] = value
    },
    sortBy(item: any) {
      if(self.sortedValue === item) {
        self.sortedType === "desc" ? self.sortedType = "asc" : self.sortedType = "desc"
      } else {
        self.sortedValue = item
        self.sortedType = item === "name" ? "asc" : "desc"
      }
      self.sortedCompanies = _.orderBy(toJS(self.sortedCompanies), item, (self.sortedType as any) ) as any
    },
    setSortedCompanies(companies) {
      self.sortedCompanies = [] as any
      self.sortedCompanies = toJS(companies).slice(0, self.defaultLenght)
    }
  }))
  .actions((self) => ({
    setCompanies(companies: any) {
      self.companies = companies as any
    },
    loadMore() {
      self.defaultLenght += 5
      self.setSortedCompanies(self.companies)
    },
    search(value: string) {
      if(value) {
        const findItems = toJS(self.companies).filter(item => item.name.includes(value))
        self.sortedCompanies = findItems as any
      } else {
        self.setSortedCompanies(self.companies)
      }
    },
    goToDepartment(currentCompany) {
      const root = getRoot(self) as RootStore
      root.globalStore.setCurrent(toJS(currentCompany), "currentCompany")
      root.globalStore.setCurrent(currentCompany.company_type, "companyType")
      root.globalStore.goTo(currentCompany.company_type === "medical" ? "branches" : "departments")
    }
  }))
  .actions((self) => ({
    async addCompany() {
      const env: Environment = getEnv(self)
      const companyResp = await env.api.company.initCompany(toJS(self.newCompany))
      if(companyResp) {
        const {data} = companyResp.data
        data && self.setNewCompany(constructCompanies(data))
        self.setSortedCompanies(self.companies)
      }
    },
    async getCompanies() {
      const env: Environment = getEnv(self)
      const companiesResp = await env.api.company.getCompanies()
      if(companiesResp){
        const data = companiesResp.data
        if(!data) return
        self.setCompanies(data.map(item => constructCompanies(item)))
        self.setSortedCompanies(self.companies)
      }
    }
  }))

export type CompaniesStore = typeof CompaniesStoreModel.Type