
export enum ApiMethods {
  get = "get",
  post = "post",
  put = "put",
  delete = "del"
}

export enum ApiTokenTypes {
  access = "access",
  refresh = "refresh"
}

export interface ApiParameters {
  queryStringParameters?: any,
  body?: any
  headers?: any
}

export interface ApiErrorResponse {
  status: number,
  data: {
    message: string | undefined,
    Message: string | undefined
    error: string | undefined
  }
}

export enum ErrorResponseStatus {
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  badRequest = 400,
  serverError = 500,
  badGateway = 502,
  cannotConnect = 0,
  unknown = -1
}

export type GeneralApiProblem = { ok: false, status: ErrorResponseStatus, message?: string, isUnauthorized: boolean, data?: object}

export type ApiResult = { ok: true, data: any, status?: ErrorResponseStatus, message?: string } | GeneralApiProblem
