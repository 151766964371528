

/** Trim emoji and other non-letter characters from text */
export function getCleanText(text?: string): string {
  if (!text) {
    return ""
  }
  return text
    .replace(/[^a-zA-Z _]+/g, "")
    .trim()
}

export function getInitials(firstName: string, lastName?: string): string {
    firstName = getCleanText(firstName)
    lastName = getCleanText(lastName)

    let result: string = ""

    if (!!firstName) {
      result = firstName.charAt(0)
    }

    if (!!lastName) {
      result += lastName.charAt(0)
    } else {
      let words = firstName.split(" ")
      if (words.length > 1) {
        result += words[1].charAt(0)
      }
    }

    return result
}

export function generateId(length: number) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  const charactersLength = characters.length
  let result = ""
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export function getUrlParams(search) {
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  const params = {}
  hashes.forEach(hash => {
    const [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })
  return params
}

export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

export function convertUTC (value: string, type: "fromUTC" | "toUTC", tz?) {
  if(!value) return ""
  const offset = tz ? -tz.split(":")[0] : new Date().getTimezoneOffset() / 60
  let timeArray = value.split(":")
  if(type === "toUTC") {
    let time = +timeArray[0] + offset
    timeArray[0] = Math.sign(time) === -1 ? `${24 + time}` : `${time}`
  } else if (type === "fromUTC") {
    let time = +timeArray[0] - offset
    timeArray[0] = time > 24 ? `${time - 24}` : Math.sign(time) === -1 ? `${24 + time}` : `${time}`
  }
  timeArray[0] = +timeArray[0] === 24 && +timeArray[1] > 0 ? "00" : timeArray[0]
  return timeArray.join(":")
}