import { NavigationRoutes } from "./navigation.types"
import { Signin } from "../modules/auth/pages/signin"
import { Signup } from "../modules/auth/pages/signup"
import { ForgotPassword } from "../modules/auth/pages/forgot-password"
import { NewPassword } from "../modules/auth/pages/new-password"
import { Companies } from "../modules/companies"
import { Departments } from "../modules/departments"
import { Department } from "../modules/department"
import { User } from "../modules/user"
import { Branches } from "../modules/branches"
import { Branch } from "../modules/branch"
import { AddPatient } from "../modules/add_patient"
import { AddCaregiver } from "../modules/add_caregiver"
import { Patient } from "../modules/patient"
const LoginFlow = [
  {
    path: "/signin",
    component: Signin
  },
  {
    path: "/signup",
    component: Signup
  },
  {
    path: "/forgot-password",
    component: ForgotPassword
  },
  {
    path: "/new-password",
    component: NewPassword
  }
]

const CompaniesFlow = [
  {
    path: "/companies",
    component: Companies
  }
]

const DepartmentsFlow = [
  {
    path: "/departments",
    component: Departments
  },
  {
    path: "/department",
    component: Department
  }
]

const BranchesFlow = [
  {
    path: "/branches",
    component: Branches
  },
  {
    path: "/branch",
    component: Branch
  },
  {
    path: "/add_patient",
    component: AddPatient
  },
  {
    path: "/add_caregiver",
    component: AddCaregiver
  }
]

const UserFlow = [
  {
    path: "/user",
    component: User
  },
  {
    path: "/patient",
    component: Patient
  }
]

export const AppRoutes: NavigationRoutes = [
  ...LoginFlow,
  ...CompaniesFlow,
  ...DepartmentsFlow,
  ...BranchesFlow,
  ...UserFlow,
  {
    path: "/",
    redirect: "/signin"
  }
]