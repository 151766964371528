import React from "react"
import {
  DashboardWrapper,
  DashboardTitle,
  DashboardContainer,
  CompanyTitle,
  Wrapper} from "./add_patient.presets"
import { smartInject } from "../../utils/smartInject"
import { GlobalProps } from "../global/global.props"
import { DashboardHeader } from "../../components/dashboard-header"
import { withTranslation, WithTranslation } from 'react-i18next'
import { Breadcrumbs } from "../../shared/Breadcrumbs"
import Stepper from "./Stepper"
import FirstStep from "./FirstStep"
import SecondStep from "./SecondStep"
import ThirdStep from "./ThirdStep"
import FourthStep from "./FourthStep"
import FiveStep from "./FiveStep"
import "./style.css"
import SixStep from "./SixStep"
import { isPatchPlan } from '../../utils/plans';

@(withTranslation() as any)
@smartInject(GlobalProps)
export class AddPatient extends React.Component<GlobalProps & WithTranslation> {

  state = {
    current: 1,
    nextStep: 2,
    firstStep: {
      MRN: "",
      gender: "",
      phone: "",
      supervisor: "Choose your supervisor",
      firstName: "",
      lastName: "",
      birthday: "",
      supervisor_id: "",
    },
    secondStep: {
      street: "",
      city: "",
      state: "",
      zipcode: "",
      plan: "Choose your connection plan",
      plan_id: "",
    },
    thirdStep: {
      icd10: [],
      cpt_code: null,
      icd_code: null,
    },
    fourthStep: {
      AFIb: false,
      AFIbheartrate: false,
      unclassResult: false,
      heartRateUnder: false,
      heartRateUnderValue: "",
      heartRateOver: false,
      heartRateOverValue: "",
      tempUnder: false,
      tempUnderValue: "",
      tempOver: false,
      tempOverValue: "",
      pressureUnderSys: false,
      pressureUnderSysValue: "",
      pressureOverSys: false,
      pressureOverSysValue: "",
      pressureUnderDis: false,
      pressureUnderDisValue: "",
      pressureOverDis: false,
      pressureOverDisValue: "",
      SpO2Under: false,
      SpO2UnderValue: "",
      AFIbheartrateValue: "",
      recordings: "Custom"
    },
    fifthStep: {
      reminders: [
        {
          trigger_time: ''
        }
      ]
    }
  }

  async componentDidMount(){
    await this.props.patientStore.getSupervisors()
    await this.props.patientStore.getBilling()
    await this.props.patientStore.getConnectionPlanList()
  }

  fetchICD10Codes = async (query) => {
    if(query.length < 3) return
    const resp = await fetch(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token=&terms=${query}`)
    const icd10 = await resp.json()
    const data: any = []
    icd10 && icd10[3].forEach(item => {
      data.push({ title: `${item[0]} - ${item[1]}` })
    })
    
    this.setState({icd10: data, icdError: ""})

    this.setState({
      thirdStep: {
        ...this.state.thirdStep,
        icd10: data
      }
    })
  }

  changedRecordings = (type) => {
    if(type === "All"){
      this.setState({
        fourthStep: {
          ...this.state.fourthStep,
          AFIb: true,
          AFIbheartrate: true,
          unclassResult: true,
          heartRateUnder: true,
          heartRateOver: true,
          tempUnder: true,
          tempOver: true,
          pressureUnderSys: true,
          pressureOverSys: true,
          pressureUnderDis: true,
          pressureOverDis: true,
          SpO2Under: true,
          recordings: type
        }
      })
    } else {
      this.setState({
        fourthStep: {
          ...this.state.fourthStep,
          AFIb: false,
          AFIbheartrate: false,
          unclassResult: false,
          heartRateUnder: false,
          heartRateOver: false,
          tempUnder: false,
          tempOver: false,
          pressureUnderSys: false,
          pressureOverSys: false,
          pressureUnderDis: false,
          pressureOverDis: false,
          SpO2Under: false,
          recordings: type
        }
      })
    }
  }


  setCurrentState = (state: string, value: number) => {
    this.setState({[state]: value})
  }

  changeFirstStepValue = (type: string, value: string) => {
    const supervisor = this.props.patientStore.supervisors.find((item: any) => item.id === value) as any;

    if (type === 'supervisor_id') {
      this.setState({
        firstStep: {
          ...this.state.firstStep,
          supervisor_id: value,
          supervisor: supervisor ? supervisor.name : 'Choose your supervisor'
        }
      })

      return;
    }

    this.setState({
      firstStep: {
        ...this.state.firstStep,
        [type]: value
      }
    })
  }

  changeSecondStepValue = (type: string, value: string) => {
    const plan = this.props.patientStore.planList.find((item: any) => item.id === value) as any;

    if (type === 'plan_id') {
      this.setState({
        secondStep: {
          ...this.state.secondStep,
          plan_id: value,
          plan: plan ? plan.description : 'Choose your connection plan'
        }
      })

      return;
    }

    this.setState({
      secondStep: {
        ...this.state.secondStep,
        [type]: value
      }
    })
  }

  changeThirdStepValue = (type: string, value: any) => {
    this.setState({
      thirdStep: {
        ...this.state.thirdStep,
        [type]: value
      }
    })
  }

  changeFourthStepValue = (type: string, value: string | boolean) => {
    this.setState({
      fourthStep: {
        ...this.state.fourthStep,
        [type]: value
      }
    })
  }

  changeFourthStepValues = (values: any) => {
    this.setState({
      fourthStep: {
        ...this.state.fourthStep,
        ...values,
      }
    })
  }

  addReminder = () => {
    this.state.fifthStep.reminders.push({ trigger_time: '' })
    this.setState({
      fifthStep: {
        reminders: this.state.fifthStep.reminders
      }
    })
  }

  removeReminder = (index: number) => {
    this.state.fifthStep.reminders.splice(index, 1)
    this.setState({
      fifthStep: {
        reminders: this.state.fifthStep.reminders
      }
    })
  }

  changeReminderValue = (index: number, value: string) => {
    this.state.fifthStep.reminders[index].trigger_time = value;
    this.setState({
      fifthStep: {
        reminders: this.state.fifthStep.reminders
      }
    })
  }

  goNext = async (obj?: any) => {
    if(obj) {
      Object.entries(obj).forEach(item => this.props.patientStore.changeValue(item[0], (item[1] as any)))
    }
    if(this.state.nextStep === 7) {
      this.props.globalStore.goTo("branch")
      return
    }

    console.log(this.props.patientStore.planList)
    console.log(this.state.secondStep)

    // @ts-ignore
    const currentPlan = this.props.patientStore.planList.find(item => item.id === this.state.secondStep.plan_id);

    console.log(currentPlan)

    // @ts-ignore
    if (currentPlan && isPatchPlan(currentPlan.biosignals_included) && this.state.current !== 1) {
      await this.props.patientStore.createPatient();
      this.setState({
        current: 6,
        nextStep: 7,
      })
      return;
    }

    if(obj && obj.reminders) {
      await this.props.patientStore.createPatient()
    }
    this.setState({
      current: this.state.nextStep,
      nextStep: this.state.nextStep + 1
    })
  }

  goPrev = () => {
    if(this.state.current === 1) this.props.globalStore.goTo("branch")
    else this.setState({
      current: this.state.current - 1,
      nextStep: this.state.nextStep - 1
    })
  }

  prevBtnName = () => {
    switch(this.state.current){
      case 2: return "Step 1"
      case 3: return "Step 2"
      case 4: return "Step 3"
      case 5: return "Step 4"
      case 6: return "Step 5"
      default: return "Branch Page"
    }
  }

  constructNavData = () => {
    const { t } = this.props
    const { currentCompany, user, currentDepartment } = this.props.globalStore
    const name = currentDepartment ? currentDepartment.name : ""
    const ownerData = [
      {
        goTo: "companies",
        name: t("dashboard.branch.home")
      },{
        goTo: "branches",
        name: currentCompany ? currentCompany.name : ""
      },{
        goTo: "branch",
        name: name
      },{
        name: "Add New Patient"
      }
    ]
    const doctorData = [
      {
        goTo: "branches",
        name: t("dashboard.branch.home")
      },{
        goTo: "branch",
        name: name
      },{
        name: "Add New Patient"
      }
    ]
    const defaultData = [
      {
        goTo: "branches",
        name: currentCompany ? currentCompany.name : ""
      },{
        goTo: "branch",
        name: name
      },{
        name: "Add New Patient"
      }
    ]
    switch (user.role) {
      case "owner": return ownerData
      case "companyowner": return defaultData
      case "doctor": return doctorData
      case "govdoctor": return doctorData
      case "support": return doctorData
      default: return defaultData
    }
  }

  renderNavigation = () => {
    return(
      <Breadcrumbs
        data={this.constructNavData()}
        globalStore={this.props.globalStore}
      />
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  renderDashboard = () => {
    return(
      <DashboardWrapper>
        <DashboardTitle>
          {this.renderNavigation()}
        </DashboardTitle>
        <DashboardContainer>
          <CompanyTitle>Add New Patient</CompanyTitle>
          <Stepper data={[{
            title: "Step One",
            subtitle: "Patient information",
            isComplete: this.state.current >= 2
          },{
            title: "Step Two",
            subtitle: "Connection plan",
            isComplete: this.state.current >= 3
          },{
            title: "Step Three",
            subtitle: "Reports and billing",
            isComplete: this.state.current >= 4
          },{
            title: "Step Four",
            subtitle: "Recordings",
            isComplete: this.state.current >= 5
          },{
            title: "Step Five",
            subtitle: "Reminders",
            isComplete: this.state.nextStep >= 6
          },{
            title: "Step Six",
            subtitle: "ID number",
            isComplete: this.state.nextStep === 7
          }]}/>
          {this.line()}
          {this.state.current === 1 && <FirstStep
            supervisors={this.props.patientStore.supervisors}
            isMRNRegistered={this.props.patientStore.isMRNRegistered}
            mrnExists={this.props.patientStore.mrnExists}
            goNext={this.goNext}
            goPrev={this.goPrev}
            fBtnTitle={`Back to ${this.prevBtnName()}`}
            sBtnTitle={"Next"}
            data={this.state.firstStep}
            changeValue={this.changeFirstStepValue}
          />}
          {this.state.current === 2 && <SecondStep
            connectionPlanList={this.props.patientStore.planList}
            goNext={this.goNext}
            goPrev={this.goPrev}
            fBtnTitle={`Back to ${this.prevBtnName()}`}
            sBtnTitle={"Next"}
            data={this.state.secondStep}
            changeValue={this.changeSecondStepValue}
          />}
          {this.state.current === 3 && <ThirdStep
            billingCodes={this.props.patientStore.billingCodes}
            goNext={this.goNext}
            goPrev={this.goPrev}
            fBtnTitle={`Back to ${this.prevBtnName()}`}
            sBtnTitle={"Next"}
            data={this.state.thirdStep}
            changeValue={this.changeThirdStepValue}
            fetchICD10Codes={this.fetchICD10Codes}
          />}
          {this.state.current === 4 && <FourthStep
            goNext={this.goNext}
            goPrev={this.goPrev}
            fBtnTitle={`Back to ${this.prevBtnName()}`}
            sBtnTitle={"Next"}
            data={this.state.fourthStep}
            changedRecordings={this.changedRecordings}
            changeValue={this.changeFourthStepValue}
            changeValues={this.changeFourthStepValues}
          />}
          {this.state.current === 5 && <FiveStep
            goNext={this.goNext}
            goPrev={this.goPrev}
            fBtnTitle={`Back to ${this.prevBtnName()}`}
            sBtnTitle={"Next"}
            data={this.state.fifthStep}
            addReminder={this.addReminder}
            removeReminder={this.removeReminder}
            changeReminderValue={this.changeReminderValue}
          />}
          {this.state.current === 6 && <SixStep
            id={this.props.patientStore.newPatient.id}
            goNext={this.goNext}
            goPrev={this.goPrev}
            fBtnTitle={`Back to ${this.prevBtnName()}`}
            sBtnTitle={"Done"}
          />}
        </DashboardContainer>
      </DashboardWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        <DashboardHeader globalStore={this.props.globalStore} t={this.props.t}/>
        {this.renderDashboard()}
      </Wrapper>
    )
  }
}