import { types } from "mobx-state-tree"

export const AuthDataModel = types.model("AuthData").props({
  email: types.optional(types.string, ""),
  password: types.optional(types.string, "")
})

export const AuthOwnerDataModel = types.model("AuthData").props({
  id: types.optional(types.string, ""),
  email: types.optional(types.string, ""),
  password: types.optional(types.string, ""),
  fullName: types.optional(types.string, ""),
  companyName: types.optional(types.string, ""),
  companyDesc: types.optional(types.string, "")
})

export type AuthData = typeof AuthDataModel.Type