import React from "react"
import { Icon } from "../../../icons/icon"
import "./style.css"

class Stepper extends React.Component<{
  data: any
}>{

  renderNumIcon = (num) => <div style={{
    width: "25px",
    height: "25px",
    display: "flex",
    borderRadius: "25px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#757575",
    color: "white"
  }}>{num}</div>

  renderStep = ({title, subtitle, isComplete, num, showSeparatorLine}) => {
    return(
        <div style={{display: "flex", flexDirection: "row", marginRight: "10px", height: "100px"}} key={num}>
          {isComplete ? <Icon icon={"complete"}/> : this.renderNumIcon(num)}
          <div style={{marginLeft: "10px"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
              <p className="stepperTitle">{title}</p>
              {/* {showSeparatorLine && <div className="separatorLine" />} */}
            </div>
            <p className="subTitle">{subtitle}</p>
          </div>
        </div>
    )
  }

  renderStepper = () => {
    return this.props.data.map((item, index) => this.renderStep({
      title: item.title,
      subtitle: item.subtitle,
      isComplete: item.isComplete,
      num: index + 1,
      showSeparatorLine: this.props.data.length - 1 !== index
    }))
  }

  render(){
    return(
      <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
        {this.renderStepper()}
      </div>
    )
  }
}

export default Stepper