import { types, getEnv } from "mobx-state-tree"
import { Environment } from "../app/environment"
import {
  NavigationRoutes,
  NavigationAction,
  NavigationUnsubscribeFn,
  NavigationSubscription,
  NavigationSubscriptionHandlerFn
} from "./navigation.types"
import queryString from "query-string"
import { AppRoutes } from "./app.routes"

export const NavigationStoreModel = types
  .model("NavigationStore")
  .props({
    redirectUrl: types.maybe(types.string)
  })
  .actions(self => ({
    initializeSubscribers() {
      const subscribers = {}
      subscribers[NavigationAction.Back] = new Set()
      subscribers[NavigationAction.Forward] = new Set()
      subscribers[NavigationAction.Change] = new Set()
      return subscribers
    }
  }))
  .volatile(self => ({
    navigationUnsubscribeFn: null as NavigationUnsubscribeFn | null,
    subscribers: self.initializeSubscribers()
  }))
  .views(self => ({
    get currentPath(): string {
      const env: Environment = getEnv(self)
      return env.navigation.location.pathname || ""
    }
  }))
  .actions(self => ({
    getComponentPath(component: React.ComponentType<any>): string | null {
      const item = AppRoutes.find(route => route.component === component)
      return item ? item.path : null
    }
  }))
  .actions(self => ({
    getModulePaths(
      module: React.ComponentType<any>,
      moduleRoutes: NavigationRoutes
    ): Array<string> {
      const modulePath = self.getComponentPath(module)
      if (!modulePath) {
        return []
      }
      return moduleRoutes.map(item => modulePath + item.path)
    }
  }))
  .actions(self => ({
    isCurrentModulePath(
      module: React.ComponentType<any>,
      moduleRoutes: NavigationRoutes
    ): boolean {
      const paths = self.getModulePaths(module, moduleRoutes)
      return paths.includes(self.currentPath)
    },
    setRedirectUrl(url?: string) {
      self.redirectUrl = url
    }
  }))
  .actions(self => ({
    catchRedirectUrl(): void {
      const params = queryString.parse(window.location.search)
      if (params.redirectUrl) {
        self.setRedirectUrl(params.redirectUrl.toString())
      }
    }
  }))
  .actions(self => ({
    handleNavigationChange(location: any, action: string) {
      self.subscribers[action].forEach((subscriber: any) => {
        subscriber(location)
      })
    },
    addListener(
      action: NavigationAction,
      handler: NavigationSubscriptionHandlerFn
    ): NavigationSubscription {
      self.subscribers[action].add(handler)
      return {
        remove: () => self.subscribers[action].delete(handler)
      }
    }
  }))
  .actions(self => ({
    afterCreate() {
      const env: Environment = getEnv(self)
      self.navigationUnsubscribeFn = env.navigation.listen(
        self.handleNavigationChange
      )
    },
    beforeDestroy() {
      if (self.navigationUnsubscribeFn) {
        self.navigationUnsubscribeFn()
      }
    }
  }))

  export type NavigationStore = typeof NavigationStoreModel.Type