import React, { Fragment } from "react"
import { 
  Wrapper,
  DashboardWrapper,
  DashboardTitle,
  DashboardContainer,
  CompanyTitle,
  DashboardHeaderRow,
  DashboardName,
  DashboardDate,
  ChartBtn,
  Btn,
} from "./user.presets"
import { smartInject } from "../../utils/smartInject"
import { Tabs, Tab, Row, Col, Form } from "react-bootstrap"
import { GlobalProps } from "../global/global.props"
import { DashboardHeader } from "../../components/dashboard-header"
import { withTranslation, WithTranslation } from 'react-i18next'
import moment from "moment"
import { Icon } from "../../icons/icon"
import { DepartmentsSubHeaderItem, DepartmentsSubHeaderItemText, FormWrapper, DepartmentPopup, DepartmentPopupWrapper, DepartmentAddBtn, DepartmentCancelBtn, Control, DepartmentIconWrapper, DepartmentPopupTitle } from "../department/department.presets"
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ErrorMessageWrapper, ErrorMessageText } from "../branch/branch.presets"
import DateFnsUtils from '@date-io/date-fns'
import "./style.css"
import { Breadcrumbs } from "../../shared/Breadcrumbs"
import { ReportButtonText, ReminderButton } from "../../components/specific-header/presets"
import { StatisticChartWrapper } from "./components/statChartWrapper"
import { EcgChartWrapper } from "./components/ecgChartWrapper"
import { DepartmentsCancelBtn } from "../add_patient/add_patient.presets"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FiltersPopup } from "./components/filtersPopup"
import { MeasurmentsTable } from "./components/patientsMeasurmentsTable"
import { DevicesTable } from "./components/patientDevicesTable"
import { isPatchPlan, isCaregiver } from '../../utils/plans';
import * as _ from 'lodash';
var QRCode = require('qrcode.react')

@(withTranslation() as any)
@smartInject(GlobalProps)
export class Patient extends React.Component<GlobalProps & WithTranslation> {
  state = {
    currentChartPage: "statistics",
    date: this.props.globalStore.userInfoCurrentDate || moment(),
    record: "all",
    showFiltersPopup: false,
    reminders: [] as any,
    currentBtn: "Day",
    currentInputs: 0,
    currentChart: "Temperature",
    isOpenPDF: false,
    showBillingCodePopup: false,
    showICD10Popup: false,
    showAddSchedulePopup: false,
    cpt_code: "",
    icd_code: "",
    icd10: []
  }

  setNewChart = (type) => {
    this.setState({currentChart: type}, async () => {
      const api = this.getApiByType()
      await this.props.medicalUserStore[api](
        (this.state.currentBtn.toLowerCase() as any),
        this.state.date
      )
    })
  }

  async componentDidMount() {
    await this.props.medicalUserStore.getFilters()
    await this.props.medicalUserStore.getReminders()
    await this.props.medicalUserStore.getListPatientsDevices()
    await this.props.patientStore.getBilling()
    await this.props.patientStore.getSupervisors()
    await this.props.patientStore.getPatientPatchRecords()
    this.chooseBtn({btnTitle: "Day"})
  }


  componentWillUnmount(){
    !this.state.isOpenPDF && this.removeCustomDateStyles()
    this.props.globalStore.setCurrent(this.props.globalStore.todaysDate, "userInfoCurrentDate")
    this.props.medicalUserStore.setListPatientsDevices([]);
    this.props.medicalUserStore.setSerialNumber('');
    this.props.medicalUserStore.setDeviceError('')
  }

  updateReminders = async () => {
    const rems = [...this.state.reminders];
    rems.forEach(item => {
      if(!isNaN(+item.id)){
        delete item.id
      }
    })

    let filteredRems = rems.filter(item => item.trigger_time !== "");
    filteredRems.filter(item => item.is_deleted && !_.has('id', item));

    const formattedReminders = filteredRems.map(item => ({
      ...item,
      trigger_time: moment(item.trigger_time, "HH:mm").utc().format("HH:mm")
    }));

    await this.props.medicalUserStore.updateReminders(formattedReminders)
    this.setState({showAddSchedulePopup: false})
  }

  setCustomDateStyles = () => {
    const el: any = document.querySelectorAll(".MuiInputBase-input")
    const el2: any = document.querySelectorAll(".MuiFormControl-root")
    el.length && el[0].setAttribute("id", "CustomUserMuiWrapper");
    el2.length && el2[0].setAttribute("id", "CustomUserContainer");
  }

  removeCustomDateStyles = () => {
    const el: any = document.querySelectorAll(".MuiInputBase-input")
    const el2: any = document.querySelectorAll(".MuiFormControl-root")
    el.length && el[0].setAttribute("id", "");
    el2.length && el2[0].setAttribute("id", "");
  }

  getApiByType = () => {
    switch(this.state.currentChart) {
      case "Temperature": return "getTemperature";
      case "Heart Rate": return "getHeartRate";
      case "Blood Pressure": return "getBp";
      case "SpO2": return "getSaturation";
      case "ECG": return "getECG";
      default: return "getTemperature"
    }
  }

  chooseBtn = async (item) => {
    this.state.currentChartPage === "statistics" && this.setCustomDateStyles()
    this.setState({currentBtn: item.btnTitle})
    const api = this.getApiByType()
    await this.props.medicalUserStore[api](item.btnTitle.toLowerCase(), this.state.date)
  }

  chooseDate = (e) => {
    const api = this.getApiByType()
    this.setState({date: e}, async () => {
      await this.props.medicalUserStore[api](
        (this.state.currentBtn.toLowerCase() as any),
        this.state.date
      )
    })
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  renderInfo = () => {
    const { currentEmployee } = this.props.globalStore
    if(!currentEmployee) return
    const values = [
      {title: "Full name", value: `${currentEmployee.first_name} ${currentEmployee.last_name}`},
      {title: "Sex", value: this.capitalize(currentEmployee.gender) || '-'},
      {title: "Date of birth", value: currentEmployee.birthday || '-'},
      {title: "Phone number", value: currentEmployee.phone || '-'}
    ]
    return(
      <Row style={{padding: "40px 20px"}}>
        <Col>
          {values.map((item, index) => 
            <div key={index} style={{display: "flex", flexDirection: "row"}}>
              <div style={{paddingBottom: "20px", width: "170px"}}>
                {item.title}
              </div>
              <div style={{paddingBottom: "20px", color: "black", display: "flex", flexDirection: "row"}}>
                {item.value}
                {item.title === "Full name" && <div
                  style={{cursor: "pointer", marginLeft: "10px"}}
                  onClick={() => navigator.clipboard.writeText(currentEmployee.unique_id)}>
                    <span style={{
                      fontSize: "10px",
                      lineHeight: "16px",
                      letterSpacing: "1.5px",
                      textTransform: "uppercase",
                      color: "#757575",
                      paddingRight: '10px'
                    }}>ID {currentEmployee.unique_id}</span>
                  <Icon icon={"copy"}/>
                </div>}
              </div>
            </div>
          )}
        </Col>
        <Col style={{flexDirection: "row-reverse", display: "flex"}}>
          <div style={{width: "300px", display: "flex", justifyContent: "center"}}>
            <QRCode value={currentEmployee.unique_id} size={200}/>
          </div>
        </Col>
      </Row>
    )
  }

  renderDeviceList = () => {
    const {t}=this.props
    const { listPatientsDevices } = this.props.medicalUserStore;

    return listPatientsDevices.length ? (
      <Fragment>
        <h4 style={{ marginTop: '20px' }}>Devices list</h4>
        <DevicesTable
          t={t}
          data={listPatientsDevices}
          medicalUserStore={this.props.medicalUserStore}
        />  
      </Fragment>  
    ) : (
      <h4 style={{ marginTop: '20px' }}>No devices connected</h4>
    )
  }

  renderStatus = () => {
    const { currentEmployee } = this.props.globalStore
    if(!currentEmployee) return
    const {status, screening} = currentEmployee
    const {t}=this.props
    function backgroundByStatus(status){
      switch(status){
        case "pending": return "white";
        case "normal": return "rgba(46, 125, 50, 0.1)";
        case "risk": return "rgba(191, 54, 12, 0.1)";
        default: return "rgba(46, 125, 50, 0.1)"
      }
    }
    function colorTextByStatus(status){
      switch(status){
        case "pending": return "#38699E";
        case "normal": return "#2E7D32";
        case "risk": return "#BF360C";
        default: return "#2E7D32"
      }
    }
    return(
    <Btn bgColor={backgroundByStatus(status)} color={colorTextByStatus(status)}>{
      !screening && status === "risk" 
            ? t("dashboard.user.noMeasured")
            : status.charAt(0).toUpperCase() + status.slice(1)
    }</Btn>
    )
  }

  renderSchedulePopup = () => {
    const data: any = this.state.reminders.filter(item => !item.is_deleted).map((item, index) => <Form.Group key={index} controlId="formGroupScheduleFrom">
    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "25px"}}>
    <Form.Label style={{color: "#212121"}}>Time</Form.Label>
    <div style={{color: "red"}} onClick={() => {
      const newReminders = [...this.state.reminders]
      newReminders.forEach(rem => {
        if(rem.id === item.id){
          rem.is_deleted = true
        }
      })
      this.setState({reminders: newReminders})
    }}>
      Delete
    </div>
    </div>
    <Control
      showerror={"false"}
      type="time"
      value={item.trigger_time}
      placeholder="--:--"
      onChange={(e) => {
        const value = e.target.value
        const newReminders = [...this.state.reminders]
        newReminders.forEach(rem => {
          if(rem.id === item.id){
            rem.trigger_time = value
          }
        })
        this.setState({reminders: newReminders})
      }}
    />
  </Form.Group>)
    return this.state.showAddSchedulePopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showAddSchedulePopup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
              Reminders
            </DepartmentPopupTitle>
            <div style={{
              fontSize: "14px",
              color: "#212121",
              textAlign: "center"
            }}>
              Set time when your patients will receive push notifications regarding their recordings
            </div>
            <div style={{maxHeight: "500px", overflowY: "scroll"}}>
              {data}
            </div>
            <div style={{display: "flex", justifyContent: "flex-end", marginBottom: "20px"}}>
              <DepartmentsCancelBtn
                variant="outline-secondary"
                onClick={() => {
                  const newReminders = [...this.state.reminders]
                  newReminders.push({trigger_time: "", id: +new Date()})
                  this.setState({reminders: newReminders})
                }}
              >
                Add
              </DepartmentsCancelBtn>
            </div>
            <div style={{height: "1px", backgroundColor: "rgba(0,0,0,0.1", width: "100%", marginBottom: "20px"}}></div>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentCancelBtn
              variant="outline-secondary"
              onClick={() => {
                this.setState({showAddSchedulePopup: false})
              }}
            >Cancel</DepartmentCancelBtn>
            <DepartmentAddBtn
              variant="primary"
              onClick={() => this.updateReminders()}
            >Done</DepartmentAddBtn>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  renderHealthboarder = () => {
    const { currentEmployee } = this.props.globalStore
    if(!currentEmployee) return
    const textStyle = {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#212121",
      paddingBottom: "11px"           
    }
    const {t}=this.props
    const { reminders } = this.props.medicalUserStore
    return(
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "20px"}}>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
          <div>
            <div style={textStyle}>
              {t("dashboard.user.healthboard.firstTitle")}
            </div>
            {this.renderStatus()}
          </div>
          {reminders.length ? <div style={{color: "black"}}>Current reminders: {reminders && reminders.map((item,index) => 
            <span key={index} style={{color: "black"}}>
              {moment.utc(item.trigger_time, 'hh:mm').local().format('HH:mm')} {index === reminders.length - 1 ? "" : " • "} 
            </span>)}
          </div> : null}
          {
             !isCaregiver(currentEmployee.account_type) && (
              <DepartmentsSubHeaderItem
                  style={{marginRight: 0}}
                  background={"white"}
                  border={"0.5px solid rgba(0,0,0,0.1)"}
                  onClick={() => 
                    this.setState({
                      showAddSchedulePopup: true, 
                      reminders: this.props.medicalUserStore.reminders.map(reminder => ({
                        ...reminder,
                        trigger_time: moment.utc(reminder.trigger_time, 'hh:mm').local().format('HH:mm')
                      }))
                    })}
                >
                  <Icon icon={"ring"}/>
                  <DepartmentsSubHeaderItemText color={"#38699E"}>Customize Reminders</DepartmentsSubHeaderItemText>
              </DepartmentsSubHeaderItem>
            )
          }
        </div>
      </div>   
    )
  }

  renderReminder = () => {
    return(
      <ReminderButton variant="light" onClick={() => {}} style={{marginLeft: "20px"}}>
        <Icon icon={"reminder"}/>
        <ReportButtonText color={"white"}>Customize Reminders</ReportButtonText>
      </ReminderButton>
    )
  }

  getDateInfo = () => {
    if(this.state.currentBtn === "Day"){
      return {
        format: "day",
        dateTitle: moment(this.state.date).format("MMM DD") === moment().format("MMM DD")
        ? `Today, ${moment(this.state.date).format("MMM DD")}`
        : `${moment(this.state.date).format("YYYY")}, ${moment(this.state.date).format("MMM DD")}`
      }
    }
    if(this.state.currentBtn === "Month"){
      return {
        format: "month",
        dateTitle: `${moment(this.state.date).format("YYYY")}, ${moment(this.state.date).format("MMM")}`
      }
    }
    if(this.state.currentBtn === "Year"){
      return {
        format: "year",
        dateTitle: `Year, ${moment(this.state.date).format("YYYY")}`
      }
    }
    return {
      format: "day",
      dateTitle: moment(this.state.date).format("MMM DD") === moment().format("MMM DD")
      ? `Today, ${moment(this.state.date).format("MMM DD")}`
      : `${moment(this.state.date).format("YYYY")}, ${moment(this.state.date).format("MMM DD")}`
    }
  }

  renderChartHeader = () => {
    const {t}=this.props
    const btns = [{
      id: 1,
      btnTitle: "Day",
      title: t("dashboard.user.healthboard.calendar.day")
    }, {
      id: 2,
      btnTitle: "Month",
      title: t("dashboard.user.healthboard.calendar.month")
    }, {
      id: 3,
      btnTitle: "Year",
      title: t("dashboard.user.healthboard.calendar.year")
    }]
    const dateInfo: any = this.getDateInfo()
    return(
      <Row style={{display: "flex",flexWrap: "nowrap", alignItems: "center", padding: "20px"}}>
        <Col>
          <Row style={{flexWrap: "nowrap"}}>
            <ChartBtn style={{marginRight: "20px",
            borderColor: this.state.currentChartPage === "statistics"
                          ? "#38699E" : "#E0E0E0"
            }}
            onClick={() => this.setState({currentChartPage: "statistics", currentChart: "Temperature"})}>
              Statistics
            </ChartBtn>
            <ChartBtn  style={{
            borderColor: this.state.currentChartPage === "ecg"
                          ? "#38699E" : "#E0E0E0"
            }}onClick={() => this.setState(
              {currentChartPage: "ecg", currentChart: "ECG"},
              () => this.chooseBtn({btnTitle: "Day"})
            )}>
              ECG Records
            </ChartBtn>
          </Row>
        </Col>
        <Col md={4} style={{alignItems: "center", justifyContent: "center", display: "flex"}}>
        <span
          style={{color: "#38699E", cursor: "pointer", paddingRight: "10px", paddingBottom: "4px", fontWeight: 500}}
          onClick={() => this.chooseDate(+moment(this.state.date).subtract(1, dateInfo.format))}>
            {"<"}
        </span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={this.state.date}
              onChange={(e) => this.chooseDate(e)}
              labelFunc={() => dateInfo.dateTitle}
              InputProps={{ style: {textAlign: "center", color: "#38699E", cursor: "pointer"}, disableUnderline: true }}
            />
          </MuiPickersUtilsProvider>
        <span
          style={{color: "#38699E", cursor: "pointer", paddingLeft: "10px", paddingBottom: "4px", fontWeight: 500}}
          onClick={() => this.chooseDate(+moment(this.state.date).add(1, dateInfo.format))}>
            {">"}
        </span>
        </Col>
        <Col>
          <div style={{display: "flex", marginRight: "-15px", marginLeft: "-15px"}}>
            {btns.map((item,index) => <Col
              key={index}
              onClick={() => this.chooseBtn(item)}
              style={{
                border: item.btnTitle === this.state.currentBtn ? "1px solid #38699E" : "",
                cursor: "pointer", padding: "6.5px 10px", background: "#F5F5F5", borderRadius: "4px",
                textAlign: "center", margin: "0 10px", color: "#38699E"
              }}
            >{item.title}</Col>)}
          </div>
        </Col>
      </Row>
    )
  }

  renderChart = () => {
    return(
      this.state.currentChartPage === "statistics"
      ? <StatisticChartWrapper
        date={this.state.date}
        currentChart={this.state.currentChart}
        setNewChart={this.setNewChart}
        saturations={this.props.medicalUserStore.saturations}
        measurements={this.props.medicalUserStore.measurements}
        bp={this.props.medicalUserStore.bp}
        hr={this.props.medicalUserStore.hr}
        typeDate={this.state.currentBtn}
        t={this.props.t}
      />
      : <EcgChartWrapper
        supervisors={this.props.patientStore.supervisors}
        setSupervisors={this.props.patientStore.setSupervisors}
        pdfIsOpen={this.state.isOpenPDF}
        setOpenPdf={(trigger) => this.setState({isOpenPDF: trigger})}
        recordType={this.state.record}
        measurements={this.props.medicalUserStore.ecg}
        t={this.props.t}
      />
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  renderText = (text, type?) => {
    const style = type ?
    {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      paddingBottom: "10px",
      marginBottom: 0,
      color: "#757575"
    } : {
      fontSize: "16px",
      lineHeight: "18px",
      paddingBottom: 0,
      marginBottom: 0
    }
    return <p style={style}>
      {text || '-'}
    </p>
  }

  fetchICD10Codes = async (query) => {
    if(query.length < 3) return
    const resp = await fetch(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&authenticity_token=&terms=${query}`)
    const icd10 = await resp.json()
    const data: any = []
    icd10 && icd10[3].forEach(item => {
      data.push({ title: `${item[0]} - ${item[1]}` })
    })
    this.setState({icd10: data, icdError: ""})
  }

  changeValue = (type: string, value: string | boolean | any) => {
    this.setState({
      [type]: value
    })
  }

  renderICD10 = () => {
    return(
      <Form.Group controlId="formICD-10">
        <Form.Label style={{color: "#212121"}}>
          ICD-10 code
        </Form.Label>
        <Autocomplete
          id="combo-box-demo"
          options={this.state.icd10}
          getOptionLabel={(option: any) => option.title}
          style={{ width: 400 }}
          onInputChange={(event, newInputValue) => this.changeValue("icd_code", newInputValue)}
          renderInput={(params) => <TextField {...params} placeholder={this.state.icd_code} onChange={(e) => this.fetchICD10Codes(e.target.value)} variant="outlined" />}
        />
      </Form.Group>
    )
  }

  renderBillingCode = () => {
    const billing = this.props.patientStore.billingCodes ? this.props.patientStore.billingCodes : []
    return(
      <Form.Group controlId="formBillingCode">
        <Form.Label style={{color: "#212121"}}>
          Billing code
        </Form.Label>
        <Autocomplete
          id="combo-box-demo2"
          options={billing}
          getOptionLabel={(option: any) => option.text}
          style={{ width: 400 }}
          onInputChange={(event, newInputValue) => this.changeValue("cpt_code", newInputValue && newInputValue.match(/\d+/)![0])}
          renderInput={(params) => <TextField {...params} placeholder={this.state.cpt_code} variant="outlined" />}
        />
      </Form.Group>
    )
  }

  primaryIndicationEdit = () => {
    return this.state.showICD10Popup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showICD10Popup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
              Reminders
            </DepartmentPopupTitle>
            {this.renderICD10()}
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentCancelBtn
              variant="outline-secondary"
              onClick={() => {
                this.setState({showICD10Popup: false})
              }}
            >Cancel</DepartmentCancelBtn>
            <DepartmentAddBtn
              variant="primary"
              onClick={() => {
                this.props.medicalUserStore.setICD(this.state.icd_code)
                this.setState({showICD10Popup: false})
              }}
            >Edit</DepartmentAddBtn>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  setNewFilters = async (filters) => {
    await this.props.medicalUserStore.setFilters(filters)
    await this.props.medicalUserStore.getFilters()
    this.setState({showFiltersPopup: false})
  }

  filtersPopup = () => {
    const {first_name, last_name, mrn} = this.props.globalStore.currentEmployee
    return this.state.showFiltersPopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup style={{width: "70%", height: "80%", overflow: "auto"}}>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showFiltersPopup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <div style={{width: "100%", height: "100%", overflow: "auto"}}>
            <DepartmentPopupTitle>
              <h2 style={{color: "black"}}>{first_name} {last_name}</h2>
              <span style={{color: "black"}}>MRN {mrn}</span>
            </DepartmentPopupTitle>
            <FiltersPopup
              currentFilters={this.props.medicalUserStore.filtersInfo}
              sBtnTitle="Edit"
              fBtnTitle="Cancel"
              goNext={this.setNewFilters}
              goPrev={() => {
                this.setState({showFiltersPopup: false})
              }}
            />
          </div>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  billingCodeEdit = () => {
    return this.state.showBillingCodePopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showBillingCodePopup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
              Billing Code
            </DepartmentPopupTitle>
            {this.renderBillingCode()}
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentCancelBtn
              variant="outline-secondary"
              onClick={() => {
                this.setState({showBillingCodePopup: false})
              }}
            >Cancel</DepartmentCancelBtn>
            <DepartmentAddBtn
              variant="primary"
              onClick={() => {
                this.props.medicalUserStore.setBilling(this.state.cpt_code)
                this.setState({showBillingCodePopup: false})
              }}
            >Edit</DepartmentAddBtn>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  renderDeviceField = () => {
    return(
      <Form.Group controlId="deviceId">
        <Form.Label style={{color: "#212121"}}>
          Device ID
        </Form.Label>
        <Control
          showerror={this.props.medicalUserStore.deviceError.length ? "true" : "false"}
          onFocus={() => this.props.medicalUserStore.setDeviceError('')}
          type="text"
          placeholder={"Enter device ID"}
          value={this.props.medicalUserStore.serial_number}
          onChange={(e) => {
            this.props.medicalUserStore.setSerialNumber(e.target.value)
          }}
        />
        <ErrorMessageWrapper>
          <ErrorMessageText>
            {this.props.medicalUserStore.deviceError}
          </ErrorMessageText>
        </ErrorMessageWrapper>
      </Form.Group>
    )
  }

  renderAddDevicePopup = () => {
    return this.props.medicalUserStore.showAddDevicePopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.props.medicalUserStore.setShowAddDevicePopup(false)
            this.props.medicalUserStore.setDeviceError('')
            this.props.medicalUserStore.setSerialNumber('')
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            {this.renderDeviceField()}
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
              <DepartmentAddBtn
                variant="primary"
                onClick={() => {
                  this.props.medicalUserStore.isDeviceAlreadyExist();
                }}
              >
                Add device
              </DepartmentAddBtn>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  renderPersonalInfo = (t) => {
    const textStyle = {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#212121",
      paddingBottom: "11px"        
    }
    const {currentEmployee} = this.props.globalStore
    const {filtersInfo} = this.props.medicalUserStore
    const {connection_plan, icd_code, cpt_code} = currentEmployee
    const plan = connection_plan ? connection_plan.connection_plan : ""
    const diapasonDate = connection_plan ? `${moment(connection_plan.date_start).format("MM/DD/YYYY")} to ${moment(connection_plan.date_expire).format("MM/DD/YYYY")}` : ""
    return(
      <>
      <div 
        style={{
          margin: "20px 0",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{width:'200px'}}>
          <div style={textStyle}>
            {t("dashboard.user.healthboard.firstTitle")}
          </div>
          {this.renderStatus()}
        </div>
        <div>
          <DepartmentsSubHeaderItem
            background={"white"}
            border={"0.5px solid rgba(0,0,0,0.1)"}
            style={{ maxWidth: '130px'}}
            onClick={() => {
              this.props.medicalUserStore.setShowAddDevicePopup(true)
            }}
          >
            <DepartmentsSubHeaderItemText pl0 color={"#38699E"}>
              Add device
            </DepartmentsSubHeaderItemText>  
          </DepartmentsSubHeaderItem>
        </div>
        </div>
          {
            !isCaregiver(currentEmployee.account_type) && (
              <Fragment>
                {this.line()}
                <Row style={{marginTop: "35px", marginBottom: "35px"}}>
                  <Col md={3}>
                    {this.renderText("Connection plan", "bold")}
                    {this.renderText(plan)}
                  </Col>
                  <Col md={6}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: 'center'
                    }}>
                      <div style={{width: "200px"}}>
                      {this.renderText("Duration", "bold")}
                      {this.renderText("1 year")}
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    {this.renderText("Connection period", "bold")}
                    {this.renderText(diapasonDate)}
                  </Col>
                </Row>
                {this.line()}
                <Row style={{marginTop: "35px", marginBottom: "35px"}}>
                  <Col md={3}>
                    {this.renderText("Primary indication", "bold")}
                    {this.renderText(icd_code)}
                    <span style={{color: "#5a7a9c", cursor: "pointer"}} onClick={() => this.setState({showICD10Popup: true})}>Edit</span>
                  </Col>
                  <Col md={6}>
                    <div style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: 'center'
                    }}>
                      <div style={{width: "200px"}}>
                        {this.renderText("Recording", "bold")}
                        {filtersInfo ? this.renderText(filtersInfo.name) : this.renderText('')}
                        <span style={{color: "#5a7a9c", cursor: "pointer"}} onClick={() => this.setState({showFiltersPopup: true})}>Edit</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={3}>
                    {this.renderText("Billing code", "bold")}
                    {cpt_code ? this.renderText(`CPT ${cpt_code}`) : this.renderText('')}
                    <span style={{color: "#5a7a9c", cursor: "pointer"}} onClick={() => this.setState({showBillingCodePopup: true})}>Edit</span>
                  </Col>
                </Row>
              </Fragment>
            )
          }
          {this.line()}
          {this.renderInfo()}
          {this.line()}
          {this.renderDeviceList()}
      </>
    )
  }

  renderECGHeader = () => {
    const dateTitle = `${moment(this.state.date).format("YYYY")},`
    return(
      this.state.isOpenPDF ? <div
      style={{paddingTop: "36px", paddingBottom: "36px"}}
      ><span
      style={{cursor: "pointer", color: "#38699E"}}
      onClick={() => this.setState(
        {isOpenPDF: false},
        () => this.setCustomDateStyles()
      )}><Icon icon="back"/>   {`Back to ECG records`}</span></div>
      : <Row style={{display: "flex", alignItems: "center", padding: "20px", flexWrap: "nowrap"}}>
        <Col>
          <Row style={{flexWrap: "nowrap"}}>
            <ChartBtn style={{marginRight: "20px",
            borderColor: this.state.currentChartPage === "statistics"
                          ? "#38699E" : "#E0E0E0"
            }}
            onClick={() => this.setState({currentChartPage: "statistics", currentChart: "Temperature"}, () => this.chooseBtn({btnTitle: "Day"}))}>
              Statistics
            </ChartBtn>
            <ChartBtn  style={{
            borderColor: this.state.currentChartPage === "ecg"
                          ? "#38699E" : "#E0E0E0"
            }} onClick={() => this.setState(
              {currentChartPage: "ecg", currentChart: "ECG"},
              () => this.chooseBtn({btnTitle: "Day"})
            )}>
              ECG Records
            </ChartBtn>
          </Row>
        </Col>
        <Col md={4} style={{alignItems: "center", justifyContent: "center", display: "flex"}}>
        <span
          style={{color: "#38699E", cursor: "pointer", paddingRight: "30px", paddingBottom: "4px", fontWeight: 500}}
          onClick={() => this.chooseDate(+moment(this.state.date).subtract(1, 'M'))}>
            {"<"}
        </span>
          <span style={{color: "#38699E", cursor: "pointer"}}>{`${dateTitle} ${moment(this.state.date).format("MMM")}`}</span>
        <span
          style={{color: "#38699E", cursor: "pointer", paddingLeft: "30px", paddingBottom: "4px", fontWeight: 500}}
          onClick={() => this.chooseDate(+moment(this.state.date).add(1, 'M'))}>
            {">"}
        </span>
        </Col>
        <Col>
          <Row style={{justifyContent: "flex-end", flexWrap: "nowrap"}}>
            <ChartBtn style={{marginRight: "20px",
            borderColor: this.state.record === "all"
                          ? "#38699E" : "#E0E0E0"
            }}
            onClick={() => this.setState({record: "all"})}>
              All records
            </ChartBtn>
            <ChartBtn  style={{
            marginRight: "20px",
            color: "red",
            borderColor: this.state.currentChartPage === "red"
                          ? "#38699E" : "#E0E0E0"
            }} onClick={() => this.setState({record: "red"})}>
              Red zone
            </ChartBtn>
            <ChartBtn  style={{
            color: "grey",
            borderColor: this.state.currentChartPage === "grey"
                          ? "grey" : "#E0E0E0"
            }} onClick={() => this.setState({record: "grey"})}>
              Unclassified zone
            </ChartBtn>
          </Row>
        </Col>
      </Row>
    )
  }

  renderUserInfo = () => {
    const {t}=this.props
    const { patientPatchRecords } = this.props.patientStore;

    const { currentEmployee } = this.props.globalStore

    const defaultActiveKey = !isCaregiver(currentEmployee.account_type) ? "Healthboard" : "PersonalInformation";

    return(
      <Tabs defaultActiveKey={defaultActiveKey} id="uncontrolled-tab-example"
        style={{
          marginRight: "-15px",
          marginLeft: "-15px"
        }}
        onSelect={(k) => {
          this.setState(
            {tab: k},
            () => {if(k === "Healthboard") this.setCustomDateStyles()}
          )
        }}
      >
        {
          !isCaregiver(currentEmployee.account_type) && (
            <Tab eventKey="Healthboard" title={t("dashboard.user.tabs.first")} style={{padding: "20px"}}>
            {this.renderHealthboarder()}
            <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-35px", marginRight: "-35px"}}/>
            {
              currentEmployee.connection_plan && isPatchPlan(currentEmployee.connection_plan.biosignals_included) ? (
                <Fragment>
                  {
                    patientPatchRecords.length ? (
                      <MeasurmentsTable 
                        t={t}
                        data={patientPatchRecords}
                      />  
                    ) : (
                      <div style={{ paddingTop: '20px' }}>No items to show</div>
                    )
                  }
                </Fragment> 
              ) : (
                <Fragment>
                  {this.state.currentChartPage === "statistics"
                    ? this.renderChartHeader()
                    : this.renderECGHeader()
                  }
                  {this.renderChart()}
               </Fragment>
              )
            }
          </Tab>
          )
        }
        <Tab eventKey="PersonalInformation" title={"Profile"}>
          {this.renderPersonalInfo(t)}
        </Tab>
      </Tabs>
    )
  }

  constructNavData = () => {
    const { t } = this.props
    const { currentCompany, user, currentDepartment, currentEmployee } = this.props.globalStore
    const fName = currentEmployee ? currentEmployee.first_name : ""
    const lName = currentEmployee ? currentEmployee.last_name : ""
    const ownerData = [
      user.role === "owner" && {
        goTo: "companies",
        name: t("dashboard.user.home")
      },{
        goTo: "branches",
        name: currentCompany ? currentCompany.name : ""
      },{
        goTo: "branch",
        name: currentDepartment ? currentDepartment.name : ""
      },{
        name: `${fName} ${lName}`
      }
    ]
    const doctorData = [{
      goTo: "branches",
      name: t("dashboard.user.home")
    },{
      goTo: "branch",
      name: currentDepartment ? currentDepartment.name : ""
    },{
      name: `${fName} ${lName}`
    }]
    const defaultData = [
      {
        name: `${fName} ${lName}`
      }
    ]
    switch (user.role) {
      case "owner": return ownerData
      case "companyowner": return ownerData
      case "doctor": return doctorData
      case "govdoctor": return doctorData
      case "support": return doctorData
      case "supervisor": return doctorData
      default: return defaultData
    }
  }

  renderNavigation = () => {
    return(
      <Breadcrumbs
        data={this.constructNavData()}
        globalStore={this.props.globalStore}
      />
    )
  }

  renderDashboard = () => {
    const { currentEmployee, currentDepartment } = this.props.globalStore
    const { t } = this.props
    const name = currentDepartment ? currentDepartment.name : ""
    const fName = currentEmployee ? currentEmployee.first_name : ""
    const lName = currentEmployee ? currentEmployee.last_name : ""
    const date = currentDepartment
    ? moment(currentDepartment.created).format("DD/MM/YYYY")
    : moment().format("DD/MM/YYYY")
    return(
      <DashboardWrapper>
        <DashboardTitle>
          {this.renderNavigation()}
        </DashboardTitle>
        <DashboardContainer>
          <DashboardHeaderRow>
            <DashboardName>
              {name} {t("dashboard.user.department")}
            </DashboardName>
            <Col>
              <CompanyTitle>{fName} {lName}</CompanyTitle>
            </Col>
            <DashboardDate>
              {t("dashboard.user.dateText")}: {date}
            </DashboardDate>
          </DashboardHeaderRow>
          {this.renderUserInfo()}
        </DashboardContainer>
      </DashboardWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        <DashboardHeader
          globalStore={this.props.globalStore}
          t={this.props.t}
        />
        {this.renderDashboard()}
        {this.renderSchedulePopup()}
        {this.billingCodeEdit()}
        {this.primaryIndicationEdit()}
        {this.filtersPopup()}
        {this.renderAddDevicePopup()}
      </Wrapper>
    )
  }
}