import { types, applySnapshot,
  getRoot, getEnv
} from "mobx-state-tree"
import {
  SupervisorsDataModel,
  SortedSupervisorsDataModel,
  EmployeeDataModel,
  NewEmployeeDataModel,
  SortedEmployeeDataModel,
  ScheduleDataModel,
  constructEmployee,
  EmployeeStatusDataModel,
  SortedEmployeeStatusDataModel,
  constructStatusEmployee,
  constructSupervisors
} from "./types/dashboard.model"
import _ from "lodash"
import { toJS } from "mobx"
import { RootStore } from "../../app/root-store"
import { Environment } from "../../app/environment"
import moment from "moment-timezone"
import { convertUTC } from "../../utils/stringHelpers"
import { toast } from "react-toastify"

export const DepartmentStoreModel = types.model("DepartmentStore")
  .props({
    supervisors: types.optional(types.array(SupervisorsDataModel), []),
    sortedSupervisors: types.optional(types.array(SortedSupervisorsDataModel), []),
    employees: types.optional(types.array(EmployeeDataModel), []),
    allEmployees: types.optional(types.array(EmployeeStatusDataModel), []),
    allSortedEmployees: types.optional(types.array(SortedEmployeeStatusDataModel), []),
    sortedEmployees: types.optional(types.array(SortedEmployeeDataModel), []),
    newEmployee: types.optional(NewEmployeeDataModel, {}),
    schedule: types.optional(ScheduleDataModel, {}),
    time_zone: types.optional(types.string, "0")
  })
  .volatile(() => ({
    reportStatus: "undefined",
    reportUrl: "",
    sortedType: "desc",
    sortedValue: "",
    hideLoadMore: false,
    hideLoadMoreAll: false,
    moveList: [],
    defaultLenght: 100
  }))
  .actions((self) => ({
    resetNewEmployee() {
      applySnapshot(self.newEmployee, {})
    },
    resetEmployees() {
      applySnapshot(self.employees, [])
      applySnapshot(self.allEmployees, [])
      applySnapshot(self.sortedEmployees, [])
      applySnapshot(self.allSortedEmployees, [])
    },
    setSchedule(value: string, type: string) {
      self.schedule[type] = value
    },
    setReportStatus(status: string) {
      self.reportStatus = status
    },
    setLoadMore(trigger, type?) {
      if(type === "all"){
        self.hideLoadMoreAll = trigger
      } else {
        self.hideLoadMore = trigger
      }
    },
    setReportUrl(url) {
      self.reportUrl = url
    },
    setTimeZone(tz) {
      self.time_zone = tz
    },
    setMoveList(data) {
      self.moveList = data
    },
    setNewEmployee(newEmployee) {
      self.employees = [newEmployee, ...self.employees] as any
    },
    setNewEmployeeData(value: string, type: string) {
      self.newEmployee[type] = value
    },
    sortBy(item: any, type: "sortedEmployees" | "allSortedEmployees") {
      self.sortedValue = item
      if(self.sortedValue === item) {
        self.sortedType === "desc" ? self.sortedType = "asc" : self.sortedType = "desc"
      } else {
        self.sortedType = item === "name" ? "asc" : "desc"
      }
      self[type] = _.orderBy(toJS(self[type]), item, (self.sortedType as any) ) as any
    },
    setSortedSupervisors(supervisors) {
      const newSupervisors: any = toJS(supervisors).slice(0, self.defaultLenght)
      self.sortedSupervisors = newSupervisors 
    },
    setSortedEmployees(employees) {
      const newEmployee: any = toJS(employees).slice(0, self.defaultLenght)
      self.sortedEmployees = newEmployee 
    },
    setAllSortedEmployees(employees) {
      const newEmployee: any = toJS(employees).slice(0, self.defaultLenght)
      self.allSortedEmployees = newEmployee 
    }
  }))
  .actions((self) => ({
    setSupervisors(supervisors) {
      self.supervisors = supervisors as any
    },
    setEmployee(employees: any) {
      const newEmpl = [...self.employees].concat(employees)
      self.employees = _.uniqBy(newEmpl, 'id') as any
    },
    setAllEmployee(employees: any) {
      const newEmpl = [...self.allEmployees].concat(employees)
      self.allEmployees = _.uniqBy(newEmpl, 'id') as any
    },
    filterBy(status: "risk" | "pending" | "normal" | "all") {
      if(status === "all") {
        self.setSortedEmployees(self.employees)
      } else {
        self.setSortedEmployees(toJS(self.employees).filter(item => item.status === status))
      }
    },
    filterByAll(status: "created" | "onboard" | "registered" | "all") {
      if(status === "all") {
        self.setAllSortedEmployees(self.allEmployees)
      } else {
        self.setAllSortedEmployees(toJS(self.allEmployees).filter(item => item.status === status))
      }
    },
    loadMore(status) {
      self.defaultLenght += 5
      if(status === "all") {
        self.setSortedEmployees(self.employees)
      } else {
        self.setSortedEmployees(toJS(self.employees).filter(item => item.status === status))
      }
    },
    loadMoreAll(status) {
      self.defaultLenght += 5
      if(status === "all") {
        self.setAllSortedEmployees(self.allEmployees)
      } else {
        self.setAllSortedEmployees(toJS(self.allEmployees).filter(item => item.status === status))
      }
    },
    reset() {
      applySnapshot(self, {})
    },
    search(value, type: "allSortedEmployees", sortedType?) {
      if(value) {
        const item = self.allEmployees
        let findItems = []
        if(sortedType === "all"){
          findItems = toJS(item as any).filter(item => item.id.includes(value))
        } else {
          findItems = toJS(item as any).filter(item => item.id.includes(value) && item.status === sortedType)
        }
        self[type] = findItems as any
      } else {
        if(sortedType === "all") {
          self.setAllSortedEmployees(self.allEmployees)
        } else {
          self.setAllSortedEmployees(toJS(self.allEmployees).filter(item => item.status === sortedType))
        }
      }
    },
    searchSupervisor(value) {
      if(value) {
        const findItems = toJS(self.supervisors as any).filter(item => item.name.toLowerCase().includes(value))
        self.sortedSupervisors = findItems as any
      } else {
        self.setSortedSupervisors(self.supervisors)
      }
    },
    sortBy(item: any, type: "sortedSupervisors") {
      self.sortedValue = item
      if(self.sortedValue === item) {
        self.sortedType === "desc" ? self.sortedType = "asc" : self.sortedType = "desc"
      } else {
        self.sortedType = item === "name" ? "asc" : "desc"
      }
      self[type] = _.orderBy(toJS(self[type]), item, (self.sortedType as any) ) as any
    },
  }))
  .actions((self) => ({
    async getSupervisors() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_department: root.globalStore.currentDepartment.id
      }
      const supervisorsResp = await env.api.department.getStaffList(data)
      if(supervisorsResp){
        const data = supervisorsResp.data
        if(!data) return
        self.setSupervisors(data.data.map(item => constructSupervisors(item)))
        self.setSortedSupervisors(self.supervisors)
      }
    },
    async getEmployees(date, page, withReset?) {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentDepartment} = root.globalStore
      const measurement = {
        id_department: currentDepartment ? currentDepartment.id : "",
        date: moment(date).format("YYYY-MM-DD"),
        page
      }
      withReset && self.resetEmployees()
      const measurementResp: any = await env.api.measurement.getMeasurements(measurement)
      if(measurementResp) {
        const measurement: any = measurementResp.data
        if(!measurement.data) return
        const userIds = []
        if(measurement.data.length === 0) {
          self.setLoadMore(true)
          return
        }
        measurement.data && measurement.data.forEach(item => {
          //@ts-ignore
          if(item) userIds.push(item.id_unique)
        })
        const employessData = {
          id_department: root.globalStore.currentDepartment.id,
          ids: userIds
        }
        const employeesResp = await env.api.dashboard.getEmployees(employessData)
        if(employeesResp){
          const employeesData = employeesResp.data
          if(!employeesData) return
          const employees = employeesData.data.map(item => {
            const measurements = measurement.data.find(measurement => measurement.id_unique === item.id_unique)
            if(measurements){
              item.screening_date = measurements.screening_date
              ? moment(+moment.tz(measurements.screening_date, self.time_zone)).format("YYYY-MM-DD HH:mm")
              : null
              item.status = measurements.status
            }
            return item
          })
          self.setEmployee(employees.map(item => constructEmployee(item)))
          self.setSortedEmployees(self.employees)
        }
      }
    }
  }))
  .actions((self) => ({
    async inviteSupervisors({name,email,role}) {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        name,
        role,
        invitation_email: email,
        id_department: root.globalStore.currentDepartment.id
      }
      const supervisorsResp = await env.api.department.inviteStaff(data)
      if(supervisorsResp){
        const data = supervisorsResp.data
        if(!data) return
        self.getSupervisors()
      }
    },
    async getAllEmployees(page, withReset?) {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const { currentDepartment } = root.globalStore
      const employeesData = {
        id_department: currentDepartment ? currentDepartment.id : "",
        page
      }
      withReset && self.resetEmployees()
      const employeesResp: any = await env.api.dashboard.getAllEmployees(employeesData)
      if(employeesResp) {
        const employeesData: any = employeesResp.data
        if(!employeesData.data) return
        const userIds = []
        if(employeesData.data.length === 0) {
          self.setLoadMore(true, "all")
          return
        }
        employeesData.data && employeesData.data.forEach(item => {
          //@ts-ignore
          if(item) userIds.push(item.id_unique)
        })
        const measurementData = {
          id_department: root.globalStore.currentDepartment.id,
          ids: userIds
        }
        const measurementsResp = await env.api.measurement.getAllMeasurements(measurementData)
        if(measurementsResp){
          const measurementsData = measurementsResp.data
          if(!measurementsData.data) return
          const employees = employeesData.data.map(item => {
            const measurements = measurementsData.data.find(measurement => measurement.id_unique === item.id_unique)
            if(measurements){
              item.last_measurement_date = measurements.last_measurement_date
              ? moment(+moment.tz(measurements.last_measurement_date, self.time_zone)).format("YYYY-MM-DD HH:mm")
              : null
            }
            return item
          })
          self.setAllEmployee(employees.map(item => constructStatusEmployee(item)))
          self.setAllSortedEmployees(self.allEmployees)
        }
      }
    }
  }))
  .actions((self) => ({
    async addEmployee() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const newEmployee = {
        id_department: root.globalStore.currentDepartment.id,
        id_company: root.globalStore.currentCompany.id,
        weight: +self.newEmployee.weight,
        phone: self.newEmployee.phone,
        height: +self.newEmployee.height,
        gender: self.newEmployee.gender,
        first_name : self.newEmployee.first_name,
        last_name : self.newEmployee.last_name,
        birthday: moment(self.newEmployee.birthday).format("YYYY-MM-DD")
      }
      const employeeResp = await env.api.dashboard.inviteEmployee(newEmployee)
      if(employeeResp) {
        await self.getEmployees(moment.now(), 0)
        await self.getAllEmployees(0, true)
        return employeeResp
      }
        return null
    },
    async getSchedule() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentDepartment, user} = root.globalStore
      if(user.role === "supervisor" || user.role === "govdoctor") {
        return
      }
      const scheduleResp = await env.api.department.getSchedule(currentDepartment ? currentDepartment.id : "")
      if(scheduleResp){
        const data = scheduleResp.data
        if(!data) return
        const {time_zone, time_from, time_to} = data
        const zone = time_zone ? time_zone : moment.tz.guess()
        const zoneOffset =  moment.utc().tz(zone).format("Z")
        self.setTimeZone(zone)
        self.setSchedule(convertUTC(time_from, "fromUTC", zoneOffset), "from")
        self.setSchedule(convertUTC(time_to, "fromUTC", zoneOffset), "to")
      }
    },
    async sendSchedule(from ,to, tz, offset) {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_department: root.globalStore.currentDepartment.id,
        time_from: convertUTC(from, "toUTC", offset),
        time_to: convertUTC(to, "toUTC", offset),
        time_zone: tz
      }
      const scheduleResp = await env.api.department.setSchedule(data)
      if(scheduleResp){
        const data = scheduleResp.data
        if(!data) return
        const {time_zone, time_from, time_to} = data.data
        const zone = time_zone ? time_zone : moment.tz.guess()
        const zoneOffset = moment.utc().tz(zone).format("Z")
        self.setTimeZone(zone)
        self.setSchedule(convertUTC(time_from, "fromUTC", zoneOffset), "from")
        self.setSchedule(convertUTC(time_to, "fromUTC", zoneOffset), "to")
      }
    },
    async goToUserInfo(userData) {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_department: root.globalStore.currentDepartment.id,
        id_unique: userData.id
      }
      const employeeResp = await env.api.dashboard.getEmployee(data)
      if(employeeResp){
        const data = employeeResp.data
        if(!data) return
        const employee = data.data
        employee.screening = userData.screening
        employee.status = userData.status
        root.globalStore.setCurrent(employee, "currentEmployee")
        root.globalStore.goTo("user")
      }
    },
    async getDepartmentMoveList() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentCompany} = root.globalStore
      if(!currentCompany) return
      const data = {
        id_company: currentCompany.id
      }
      const moveListResp = await env.api.department.getDepartmentsMoveList(data)
      if(moveListResp){
        const data = moveListResp.data
        if(!data.data) return
        self.setMoveList(data.data.departments)
      }
    },
    async moveUserToDepartment(newDepartment, users) {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_department: root.globalStore.currentDepartment.id,
        id_department_new: newDepartment,
        ids: users
      }
      const moveUserResp = await env.api.dashboard.moveUser(data)
      if(moveUserResp){
        const data = moveUserResp.data
        if(!data) return
        await self.getEmployees(moment.now(), 0, true)
        await self.getAllEmployees(0, true)
      }
    },
    async removeUserFromDepartment(users) {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_department: root.globalStore.currentDepartment.id,
        ids: users
      }
      const removeUserResp = await env.api.dashboard.removeUser(data)
      if(removeUserResp){
        const data = removeUserResp.data
        if(!data) return
        await self.getEmployees(moment.now(), 0, true)
        await self.getAllEmployees(0, true)
      }
    },
    async getReport() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      if(root.globalStore.user.role === "govdoctor" || root.globalStore.user.role === "supervisor"){
        return
      }
      const data = {
        id_department: root.globalStore.currentDepartment.id,
        date: moment(root.globalStore.currentDate).format("YYYY-MM-DD")
      }
      const reportResp = await env.api.reports.getReport(data)
      if(reportResp){
        const data = reportResp.data
        if(!data) return
        if(data.data && data.data.url){
          self.setReportStatus("completed")
          self.setReportUrl(data.data.url)
        } else {
          self.setReportStatus("undefined")
        }
        return true
      }
      self.setReportStatus("undefined")
      return null
    },
    async generateReport() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const data = {
        id_department: root.globalStore.currentDepartment.id,
        company_name: root.globalStore.currentCompany.name,
        department_name: root.globalStore.currentDepartment.name,
        date: moment(root.globalStore.currentDate).format("YYYY-MM-DD")
      }
      const reportResp = await env.api.reports.generateReport(data)
      if(reportResp){
        const data = reportResp.data
        if(!data){
          return null
        }
        self.setReportStatus("pending")
        if(data.message === "TOO_EARLY") toast.warn("Sorry, please generate report after all measurements!")
        return true
      } else {
        self.setReportStatus("undefined")
        return null
      }
    }
  }))

export type DepartmentStore = typeof DepartmentStoreModel.Type