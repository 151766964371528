import React from "react"
import { observer } from "mobx-react"
import { SpecificHeader } from "../../../components/specific-header"
import { TableHeaderRow, TableBodyRow, TableBodyCol, TableBtns, TableFooterRow, TableEditBtn } from "../department.presets"
import { TableHeaderCol } from "../../companies/companies.presets"
import { TableDepartmentsContainer, ActiveBadge, PendingBadge, DepartmentsPopupWrapper, DepartmentsPopup, DepartmentsIconWrapper, DepartmentsPopupTitle, FormWrapper, Control, DepartmentsCancelBtn, DepartmentsAddBtn, RoleBadge } from "../../departments/departments.presets"
import moment from "moment"
import { Form, DropdownButton, Dropdown } from "react-bootstrap"
import { Icon } from "../../../icons/icon"
import { validateField, emailRule } from "../../../services/validation"

@observer
export class SupervisorsPage extends React.Component<{
  departmentsStore: any
  departmentStore: any
  globalStore: any
  t: any
}>{

  async componentDidMount(){
    await this.props.departmentStore.getSupervisors()
  }

  state = {
    showAddSupervisorPopup: false,
    name: "",
    email: "",
    role: "",
    roleName: "",
    emailError: ""
  }

  changeValue = (value, type, roleName?) => {
    this.setState({
      [type]: value,
      roleName
    })
  }

  resendLink = async (item) => {

  }

  renderRole = (role: string) => {
    switch(role) {
      case "doctor": return "Doctor";
      case "govdoctor": return "Gov. Doctor";
      case "support": return "Support";
      default: return "Support";
    }
  }

  searchSupervisor = (e) => {
    this.props.departmentStore.searchSupervisor(e.target.value)
  }

  renderErrorMessage = (type: string) => {
    return(
      <div style={{width: "100%", position: "relative" }}>
        <span style={{color: "red", fontSize: "12px", position: "absolute", width: "100%", textAlign: "right"}}>
          {this.state[type]}
        </span>
      </div>
    )
  }

  addSupervisor = async () => {
    const formattedEmail = (this.state.email || "").trim().toLowerCase()
    const validationEmail: any = validateField(emailRule, formattedEmail)
    if (validationEmail.isValid) {
      await this.props.departmentStore.inviteSupervisors({
        name: this.state.name,
        email: this.state.email,
        role: this.state.role
      })
      this.setState({showAddSupervisorPopup: false})
    } else {
      this.setState({
        emailError: validationEmail.error ? validationEmail.error : ""
      })
    }
  }

  renderManagersPopup = () => {
    const { name, email, role } = this.state
    const { t } = this.props
    const isEmailError = this.state.emailError.length > 0
    const doc = t("dashboard.department.supervisors.popup.roles.doctor")
    const govdoc = t("dashboard.department.supervisors.popup.roles.govdoctor")
    const support = t("dashboard.department.supervisors.popup.roles.support")
    return this.state.showAddSupervisorPopup
    ? <DepartmentsPopupWrapper>
        <DepartmentsPopup>
          <DepartmentsIconWrapper onClick={() => this.setState({showAddSupervisorPopup: false})}>
            <Icon icon="close"/>
          </DepartmentsIconWrapper>
          <FormWrapper>
            <DepartmentsPopupTitle>
              {t("dashboard.department.supervisors.popup.title")}
            </DepartmentsPopupTitle>
            <Form.Group controlId="formGroupManagerName">
              <Form.Label style={{color: "#212121"}}>
              {t("dashboard.department.supervisors.popup.nameInputTitle")}
              </Form.Label>
              <Control
                showerror={"false"}
                type="text"
                placeholder={t("dashboard.department.supervisors.popup.namePlaceholder")}
                onChange={(e) => this.changeValue(e.target.value, "name")}
              />
            </Form.Group>
            <Form.Group controlId="formGroupEmail">
              <Form.Label style={{color: "#212121"}}>
                {t("dashboard.department.supervisors.popup.emailInputTitle")}
              </Form.Label>
              <Control
                showerror={isEmailError ? "true" : "false"}
                onFocus={() => this.setState({emailError: ""})}
                type="email"
                placeholder={t("dashboard.department.supervisors.popup.emailPlaceholder")}
                onChange={(e) => this.changeValue(e.target.value, "email")}
              />
              {this.renderErrorMessage("emailError")}
            </Form.Group>
            <Form.Group controlId="formGroupRole">
              <Form.Label style={{color: "#212121"}}>
                {t("dashboard.department.supervisors.popup.roleTitle")}
              </Form.Label>
              <DropdownButton id="dropdown-basic-button" title={this.state.roleName || t("dashboard.department.supervisors.popup.roleTitle")}>
                <Dropdown.Item onClick={(e) => this.changeValue("doctor", "role", doc)}>{doc}</Dropdown.Item>
                <Dropdown.Item onClick={(e) => this.changeValue("govdoctor", "role", govdoc)}>{govdoc}</Dropdown.Item>
                <Dropdown.Item onClick={(e) => this.changeValue("support", "role", support)}>{support}</Dropdown.Item>
              </DropdownButton>
            </Form.Group>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
              <DepartmentsCancelBtn
                variant="outline-secondary"
                onClick={() => this.setState({showAddSupervisorPopup: false})}
              >{t("dashboard.cancel")}</DepartmentsCancelBtn>
              <DepartmentsAddBtn
                variant="primary"
                onClick={this.addSupervisor}
                disabled={!(name.length && email.length && role.length)}
              >{t("dashboard.add")}</DepartmentsAddBtn>
            </div>
          </FormWrapper>
        </DepartmentsPopup>
      </DepartmentsPopupWrapper>
    : null
  }

  renderSupervisorsHeader = () => {
    const { supervisors } = this.props.departmentStore
    const { isAOwner } = this.props.globalStore
    const { t } = this.props
    return(
      <SpecificHeader
        t={t}
        showBtn={isAOwner}
        title={supervisors.length
          ? `${supervisors.length} ${t("dashboard.department.supervisors.supervisorText")}`
          : t("dashboard.department.supervisors.noSupervisors")
        }
        showSearch={!!supervisors.length}
        onChangeSearch={(e) => this.searchSupervisor(e)}
        onClick={() => this.setState({showAddSupervisorPopup: true})}
        searchPlaceholder={t("dashboard.department.supervisors.search")}
        btnText={t("dashboard.department.supervisors.addSupervisorBtnText")}
      />
    )
  }

  renderSupervisorsTable = () => {
    const { t } = this.props
    const managersHeaders = [
      {type: "name", desc: t("dashboard.department.supervisors.table.firstCol")},
      {type: "status", desc: t("dashboard.department.supervisors.table.secondCol")},
      {type: "created", desc: t("dashboard.department.supervisors.table.thirdCol")},
      {type: "role", desc: t("dashboard.department.supervisors.table.fourthCol")},
      {type: "btns", desc: ""}
    ]
    const {sortedSupervisors, supervisors} = this.props.departmentStore
    const showMore = sortedSupervisors.length !== supervisors.length
    return(
      <>
      {supervisors.length ? <TableHeaderRow>
        {managersHeaders.map((item,index) => 
        <TableHeaderCol
          key={index}
          onClick={() => this.props.departmentStore.sortBy(item.type, "sortedSupervisors")}
        >
          {item.desc}
        </TableHeaderCol>)}
      </TableHeaderRow> : null}
      <TableDepartmentsContainer>
        {sortedSupervisors && sortedSupervisors.map((item, index) => {
          return(
            <TableBodyRow key={index} showhover={"false"}>
              <TableBodyCol>{item.name}</TableBodyCol>
              <TableBodyCol>{
              item.status
              ? <ActiveBadge>{t("dashboard.departments.managersTab.statuses.active")}</ActiveBadge>
              : <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <PendingBadge>{t("dashboard.departments.managersTab.statuses.pending")}</PendingBadge>
                  <div
                    onClick={() => this.resendLink(item)}
                    style={{cursor: "pointer", color: "#38699E", fontSize: "14px", paddingLeft: "10px"}}
                  >
                    {t("dashboard.departments.managersTab.statuses.resend")}
                  </div>
                </div>
              }</TableBodyCol>
              <TableBodyCol>{moment(item.created).format("DD/M/YYYY")}</TableBodyCol>
              <TableBodyCol>
                <RoleBadge>{this.renderRole(item.role)}</RoleBadge>
              </TableBodyCol>
              <TableBodyCol>
                <TableBtns>
                {/* {item.status
                ? <Button variant="outline-danger">{t("dashboard.delete")}</Button>
                : <Button variant="outline-secondary">{t("dashboard.cancel")}</Button>
                } */}
                </TableBtns>
              </TableBodyCol>
            </TableBodyRow>
          )}
        )}
      </TableDepartmentsContainer>
      {showMore ? <TableFooterRow>
        <TableEditBtn
          variant="outline-secondary"
          onClick={() => this.props.departmentsStore.loadMore("sortedManagers")}
        >{t("dashboard.loadMore")}</TableEditBtn>
      </TableFooterRow> : null}
      </>
    )
  }

  render(){
    return(
      <>
        {this.renderSupervisorsHeader()}
        {this.renderSupervisorsTable()}
        {this.renderManagersPopup()}
      </>
    )
  }
}