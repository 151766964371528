import React from "react"
import presets, { 
  Wrapper,
  Logo,
  FormWrapper,
  Title,
  Control,
  Description
} from "./forgot-password.presets"
import { Icon } from "../../../../icons/icon"
import { Form, Button } from "react-bootstrap"
import { validateField, emailRule } from "../../../../services/validation"
import { smartInject } from "../../../../utils/smartInject"
import { GlobalProps } from "../../../global/global.props"
import { withTranslation, WithTranslation } from 'react-i18next';

@(withTranslation() as any)
@smartInject(GlobalProps)
export class ForgotPassword extends React.Component<GlobalProps & WithTranslation> {

  state = {
    isDisabledBtn: true,
    emailError: "",
    done: false
  }

  changeValue = (e: any, type: string) => {
    this.props.authStore.setUserData(e.target.value, type)
  }

  sendData = () => {
    const formattedEmail = (this.props.authStore.data.email || "").trim().toLowerCase()
    const validationEmail: any = validateField(emailRule, formattedEmail)
    if (validationEmail.isValid) {
      this.setState({ done: true })
    } else {
      this.setState({
        emailError: validationEmail.error
      })
    }
  }

  goBack = () => {
    this.props.authStore.goTo("signin")
  }

  renderLogo = () => {
    return(
      <Logo>
        <Icon icon="logo"/>
      </Logo>
    )
  }

  renderErrorMessage = (type: string) => {
    return(
      <div style={{width: "100%", position: "relative" }}>
        <span style={{color: "red", fontSize: "12px", position: "absolute", width: "100%", textAlign: "right"}}>
          {this.state[type]}
        </span>
      </div>
    )
  }

  renderGoBackBtn = () => {
    const { t } = this.props
    return(
    <div
      onClick={this.goBack}
      style={{display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}}>
      <Icon icon="arrowLeft" />
      <span style={{paddingLeft: "10px", color: "#38699E"}}>{t("auth.forgotPassword.backBtn")}</span>
    </div>)
  }

  renderForm = () => {
    const { emailEnterd } = this.props.authStore
    const isEmailError = this.state.emailError.length > 0
    const { t } = this.props
    return(
      <FormWrapper>
        {this.renderGoBackBtn()}
        <Title>{t("auth.forgotPassword.title")}</Title>
        <div style={{display: "flex", justifyContent: "center", paddingBottom: "30px"}}>
          <Description>{t("auth.forgotPassword.text")}</Description>
        </div>
        <Form>
          <Form.Group controlId="formGroupEmail">
            <Form.Label style={{color: "#212121"}}>{t("auth.forgotPassword.emailInputTitle")}</Form.Label>
            <Control
              showerror={isEmailError ? "true" : "false"}
              onFocus={() => this.setState({emailError: ""})}
              type="email"
              placeholder={t("auth.forgotPassword.emailPlaceholder")}
              onChange={(e) => this.changeValue(e, "email")}
            />
            {this.renderErrorMessage("emailError")}
          </Form.Group>
        </Form>
        <Button
          block
          onClick={this.sendData}
          variant="secondary"
          style={emailEnterd ? presets.btnOn : presets.btnOff}
          disabled={!emailEnterd}
        >
          {t("auth.forgotPassword.btnText")}
        </Button>
      </FormWrapper>
    )
  }

  renderDoneForm = () => {
    const { t } = this.props
    const { data } = this.props.authStore
    return(
      <FormWrapper>
        <Title>{t("auth.forgotPassword.done.title")}</Title>
        <div style={{display: "flex", justifyContent: "center", paddingBottom: "30px"}}>
          <Description>{t("auth.forgotPassword.done.text")} {data.email}</Description>
        </div>
        <Button
          block
          onClick={this.goBack}
          variant="secondary"
          style={presets.btnOn}
        >
          {t("auth.forgotPassword.backBtn")}
        </Button>
      </FormWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        {this.renderLogo()}
        {this.state.done ? this.renderDoneForm() : this.renderForm()}
        <div/>
      </Wrapper>
    )
  }
}