import React from "react"
import { Provider } from "mobx-react"
import { RootStore } from "./root-store"
import { setupRootStore } from "./setup-root-store"
import { NavigationRouter } from "../navigation"
import { theme, GlobalStyle, ThemeProvider } from "../shared/theme"
import { getUrlParams } from "../utils/stringHelpers"
import { clear } from "../lib/storage"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./style.css"


interface RootComponentState {
  rootStore?: RootStore,
  isConnected: boolean
}

interface UrlParams {
  role: string
  id: string
}

export default class App extends React.Component<{}, RootComponentState> {

  async componentDidMount() {
    this.setState({
      rootStore: await setupRootStore(),
      isConnected: true
    })
    this.parseUrlString()
  }

  componentDidCatch(error, info) {
    // if(error) this.state.rootStore.globalStore.logout()
  }

  parseUrlString = () => {
    const params: UrlParams | any = getUrlParams(window.location.search)
    const rootStore = this.state && this.state.rootStore
    if(params.role && rootStore){
      clear()
      rootStore.authStore.setUserRole(params.role)
      params.name && rootStore.authStore.setOwnerData(params.name, "fullName")
      params.id && rootStore.authStore.setOwnerData(params.id, "id")
      rootStore!.navigation.push("signup")
    }else if(window.location.pathname === "/new-password"){
      return
    }else if(!localStorage.token){
      clear()
      rootStore!.navigation.push("signin")
    }
  }

  render() {
    const rootStore = this.state && this.state.rootStore
    if (!rootStore) {
      return null
    }
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ToastContainer
          style={{color: "white"}}
          toastStyle={{color: "white"}}
          hideProgressBar
        />
        <Provider rootStore={rootStore} {...rootStore}>
          <NavigationRouter globalStore={rootStore.globalStore} navigation={rootStore.navigation} />
        </Provider>
      </ThemeProvider>
    )
  }
}
