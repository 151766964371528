import { Colors } from "./colors"

const fontSizeBase = "16px"

const defaultText =  {
  color: Colors.black,
  fontSize: fontSizeBase,
  fontWeight: 400,
  fontStyle: "normal"
}

const heading1 = {
  ...defaultText,
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "38px",
  letterSpacing: "-0.55px"
}

const heading2 = {
  ...defaultText,
  fontSize: "28px",
  fontWeight: 700,
  lineHeight: "38px",
  letterSpacing: "-0.55px"
}

const heading3 = {
  ...defaultText,
  fontSize: "19px",
  fontWeight: 700,
  lineHeight: "23px"
}

const body = {
  ...defaultText,
  lineHeight: "24px"
}

const small = {
  ...defaultText,
  fontSize: "14px",
  lineHeight: "21px"
}

const micro = {
  ...defaultText,
  fontSize: "10px",
  lineHeight: "19px",
  color: Colors.black
}

const label = {
  ...defaultText,
  fontSize: "17px",
  lineHeight: "23px",
  fontWeight: 500
}

const formLabel = {
  ...defaultText,
  ...small,
  color: Colors.black
}

export const typography = {
  /**
   * @fontSize 15
   */
  fontSizeBase,

  /**
   * @fontSize 32
   * @fontWeight 700
   * @lineHeight 48
   */
  heading1,

  /**
   * @fontSize 28
   * @fontWeight 700
   * @lineHeight 38
   */
  heading2,

  /**
   * @fontSize 19
   * @fontWeight 700
   * @lineHeight 23
   */
  heading3,

  /**
   * @fontSize 15
   * @lineHeight 23
   */
  body,

  /**
   * @fontSize 13
   * @lineHeight 19
   */
  small,

  /**
   * @fontSize 10
   * @lineHeight 19
   */
  micro,

  /**
   * @fontSize 17
   * @fontWeight 700
   * @lineHeight 23
   */
  label,

  /**
   * @fontSize 13
   * @lineHeight 19
   * @color Blue Gray #8e97aa
   */
  formLabel
}

export type Typography = typeof typography
