import React from "react"
import {
  FormWrapper,
  StepTitle,
  DepartmentsCancelBtn,
  DepartmentsAddBtn,
  Control} from "../add_patient.presets"
import { Form, DropdownButton, Dropdown
  // DropdownButton, Dropdown
} from "react-bootstrap"
import { ErrorMessageWrapper, ErrorMessageText } from "../../branch/branch.presets"
import { Checkbox } from "@material-ui/core"

class FourthStep extends React.Component<{
  goNext: (obj) => void,
  goPrev: () => void,
  fBtnTitle: string,
  sBtnTitle: string,
  data: any,
  changedRecordings: (value: string) => void,
  changeValue: (field: string, value: string | boolean) => void,
  changeValues: (value: any) => void,
}>{

  state = {}

  checkValue = (data) => {
    const state: any = {
      heartRateUnderValueError: "",
      heartRateOverValueError: "",
      tempUnderValueError: "",
      tempOverValueError: "",
      pressureUnderSysValueError: "",
      pressureOverSysValueError: "",
      pressureOverDisValueError: "",
      pressureUnderDisValueError: "",
      SpO2UnderValueError: ""
    }
    const defaultError = "This field is required."
    if (this.props.data) {
      if (this.props.data.heartRateUnder && this.props.data.heartRateUnderValue.length === 0) {
        state.heartRateUnderValueError = defaultError
      }
      if (this.props.data.heartRateOver && this.props.data.heartRateOverValue.length === 0) {
        state.heartRateOverValueError = defaultError
      }
      if (this.props.data.tempUnder && this.props.data.tempUnderValue.length === 0) {
        state.tempUnderValueError = defaultError
      }
      if (this.props.data.tempOver && this.props.data.tempOverValue.length === 0) {
        state.tempOverValueError = defaultError
      }
      if (this.props.data.pressureUnderSys && this.props.data.pressureUnderSysValue.length === 0) {
        state.pressureUnderSysValueError = defaultError
      }
      if (this.props.data.pressureOverSys && this.props.data.pressureOverSysValue.length === 0) {
        state.pressureOverSysValueError = defaultError
      }
      if (this.props.data.pressureOverDis && this.props.data.pressureOverDisValue.length === 0) {
        state.pressureOverDisValueError = defaultError
      }
      if (this.props.data.pressureUnderDis && this.props.data.pressureUnderDisValue.length === 0) {
        state.pressureUnderDisValueError = defaultError
      }
      if (this.props.data.SpO2Under && this.props.data.SpO2UnderValue.length === 0) {
        state.SpO2UnderValueError = defaultError
      }
    }

    if ([ ... new Set(Object.values(state))].length > 1) {
      this.setState(state)
      return
    } else {
      this.props.goNext({
        filters: {
          id_patient: "",
          name: this.props.data.recordings,
          filters: data
        }
      })
    }
  }

  goNext = () => {
    const data: any = []
    if(this.props.data.AFIb) { data.push({key: "ecg", value: "afib", filter: "e"}) }
    if(this.props.data.unclassResult) { data.push({key: "ecg", value: "unclassified", filter: "e"}) }
    if(this.props.data.heartRateUnder) { data.push({key: "heart_rate", value: this.props.data.heartRateUnderValue, filter: "lt"}) }
    if(this.props.data.heartRateOver) { data.push({key: "heart_rate", value: this.props.data.heartRateOverValue, filter: "gt"}) }

    if(this.props.data.tempUnder) { data.push({key: "temperature", value: this.props.data.tempUnderValue, filter: "lt"}) }
    if(this.props.data.tempOver) { data.push({key: "temperature", value: this.props.data.tempOverValue, filter: "lt"}) }

    if(this.props.data.SpO2Under) { data.push({key: "saturation", value: this.props.data.SpO2UnderValue, filter: "lt"}) }

    if(this.props.data.pressureUnderDis) { data.push({key: "bp_diastolic", value: this.props.data.pressureUnderDisValue, filter: "lt"}) }
    if(this.props.data.pressureOverDis) { data.push({key: "bp_diastolic", value: this.props.data.pressureOverDisValue, filter: "gt"}) }
    if(this.props.data.pressureUnderSys) { data.push({key: "bp_systolic", value: this.props.data.pressureUnderSysValue, filter: "lt"}) }
    if(this.props.data.pressureOverSys) { data.push({key: "bp_systolic", value: this.props.data.pressureOverSysValue, filter: "gt"}) }
    this.checkValue(data)
  }

  renderErrorMessage = (type: string) => {
    return(
      <ErrorMessageWrapper>
        <ErrorMessageText>
          {this.state && this.state[type]}
        </ErrorMessageText>
      </ErrorMessageWrapper>
    )
  }


  renderRecordingsToFlag = () => {
    return(
      <Form.Group controlId="formRecordings1">
        <Form.Label style={{color: "#212121"}}>
          Recordings to flag
        </Form.Label>
        <DropdownButton id="dropdown-basic-button" title={this.props.data.recordings}>
          <Dropdown.Item onClick={(e) => this.props.changedRecordings("All")}>All</Dropdown.Item>
          <Dropdown.Item onClick={(e) => this.props.changedRecordings("None")}>None</Dropdown.Item>
          <Dropdown.Item onClick={(e) => this.props.changedRecordings("Custom")}>Custom</Dropdown.Item>
        </DropdownButton>
      </Form.Group>
    )
  }

  renderAFIbresult = () => {
    return(
      <Form.Group controlId="formRecordings2">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <Checkbox
          style={{padding: 0, margin: 0}}
          checked={this.props.data.AFIb}
          onChange={(e) => this.props.changeValue("AFIb", e.target.checked)}
          size="small"
          color="default"
        />
        <p style={{margin: 10, cursor: "pointer"}} onClick={() => this.props.changeValue("AFIb", !this.props.data.AFIb)}>Possible AFib result</p>
        </div>
      </Form.Group>
    )
  }

  renderUnclassResult = () => {
    return(
      <Form.Group controlId="formRecordings3">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <Checkbox
          style={{padding: 0, margin: 0}}
          checked={this.props.data.unclassResult}
          onChange={(e) => this.props.changeValue("unclassResult", e.target.checked)}
          size="small"
          color="default"
        />
        <p style={{margin: 10, cursor: "pointer"}} onClick={() => this.props.changeValue("unclassResult", !this.props.data.unclassResult)}>
          Unclassified result
        </p>
        </div>
      </Form.Group>
    )
  }

  renderAFIbHeartresult = () => {
    return(
      <Form.Group controlId="formRecordings4">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.props.data.AFIbheartrate}
              onChange={(e) => {
                this.props.changeValues({
                  AFIbheartrate: e.target.checked,
                  AFIbheartrateValue: ''
                })
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.props.changeValues({
                AFIbheartrate: !this.props.data.AFIbheartrate,
                AFIbheartrateValue: ''
              })
            }}>Possible AFib result with heart rate over</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.props.data.AFIbheartrateValue}
              disabled={!this.props.data.AFIbheartrate}
              placeholder={"-"}
              onChange={(e) => this.props.changeValue("AFIbheartrateValue", e.target.value)}
            />
          </div>
        </div>
      </Form.Group>
    )
  }

  renderHeartRateUnder = () => {
    return(
      <Form.Group controlId="formRecordings5">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.props.data.heartRateUnder}
              onChange={(e) => {
                this.props.changeValues({
                  heartRateUnder: e.target.checked,
                  heartRateUnderValue: ''
                })
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.props.changeValues({
                heartRateUnder: !this.props.data.heartRateUnder,
                heartRateUnderValue: ''
              })
            }}>Heart rate under</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.props.data.heartRateUnderValue}
              disabled={!this.props.data.heartRateUnder}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {heartRateUnderValueError: ""},
                  () => this.props.changeValue("heartRateUnderValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("heartRateUnderValueError")}
      </Form.Group>
    )
  }

  renderHeartRateOver = () => {
    return(
      <Form.Group controlId="formRecordings6">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.props.data.heartRateOver}
              onChange={(e) => {
                this.props.changeValues({
                  heartRateOver: e.target.checked,
                  heartRateOverValue: ''
                })
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.props.changeValues({
                heartRateOver: !this.props.data.heartRateOver,
                heartRateOverValue: ''
              })
            }}>Heart rate over</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.props.data.heartRateOverValue}
              disabled={!this.props.data.heartRateOver}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {heartRateOverValueError: ""},
                  () => this.props.changeValue("heartRateOverValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("heartRateOverValueError")}
      </Form.Group>
    )
  }

  renderTempUnder = () => {
    return(
      <Form.Group controlId="formRecordings7">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.props.data.tempUnder}
              onChange={(e) => {
                this.props.changeValues({
                  tempUnder: e.target.checked,
                  tempUnderValue: ''
                })
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.props.changeValues({
                tempUnder: !this.props.data.tempUnder,
                tempUnderValue: ''
              })
            }}>Temperature under</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.props.data.tempUnderValue}
              disabled={!this.props.data.tempUnder}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {tempUnderValueError: ""},
                  () => this.props.changeValue("tempUnderValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("tempUnderValueError")}
      </Form.Group>
    )
  }

  inputConstructor = (type, title, error) => {
    return(
      <Form.Group controlId={`formRecordings8${type}`}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.props.data[type]}
              onChange={(e) => {
                this.props.changeValues({
                  [type]: e.target.checked,
                  [`${type}Value`]: ''
                })
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.props.changeValues({
                [type]: !this.props.data[type],
                [`${type}Value`]: ''
              })
            }}>{title}</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.props.data[`${type}Value`]}
              disabled={!this.props.data[type]}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {[`${type}ValueError`]: ""},
                  () => this.props.changeValue(`${type}Value`, value)
                )
              }}
            />
          </div>
        </div>
        <div>
        {error}
        </div>
      </Form.Group>
    )
  }

  renderTempOver = () => {
    return(
      <Form.Group controlId="formRecordings9">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.props.data.tempOver}
              onChange={(e) => {
                this.props.changeValues({
                  tempOver: e.target.checked,
                  tempOverValue: ''
                })
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.props.changeValues({
                tempOver: !this.props.data.tempOver,
                tempOverValue: ''
              })
            }}>Temperature over</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.props.data.tempOverValue}
              disabled={!this.props.data.tempOver}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {tempOverValueError: ""},
                  () => this.props.changeValue("tempOverValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("tempOverValueError")}
      </Form.Group>
    )
  }

  renderSpO2Under = () => {
    return(
      <Form.Group controlId="formRecordings10">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.props.data.SpO2Under}
              onChange={(e) => {
                this.props.changeValues({
                  SpO2Under: e.target.checked,
                  SpO2UnderValue: ''
                })
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.props.changeValues({
                SpO2Under: !this.props.data.SpO2Under,
                SpO2UnderValue: ''
              })
            }}>SpO2 under</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.props.data.SpO2UnderValue}
              disabled={!this.props.data.SpO2Under}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {SpO2UnderValueError: ""},
                  () => this.props.changeValue("SpO2UnderValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("SpO2UnderValueError")}
      </Form.Group>
    )
  }

  renderECG = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          {this.renderRecordingsToFlag()}
          <StepTitle>
            ECG recordings
          </StepTitle>
          {this.renderAFIbresult()}
          {/* {this.renderAFIbHeartresult()} */}
          {this.renderUnclassResult()}
          {this.renderHeartRateUnder()}
          {this.renderHeartRateOver()}
        </FormWrapper>
      </div>
    )
  }

  renderTemp = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Temperature recordings
          </StepTitle>
          {/* {this.renderRecordingsToFlag()} */}
          {this.renderTempUnder()}
          {this.renderTempOver()}
        </FormWrapper>
      </div>
    )
  }

  renderSpO2 = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            SpO2 recordings
          </StepTitle>
          {/* {this.renderRecordingsToFlag()} */}
          {this.renderSpO2Under()}
        </FormWrapper>
      </div>
    )
  }

  renderPressure = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Blood pressure recordings
          </StepTitle>
          {/* {this.renderRecordingsToFlag()} */}
          <p style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#212121"
          }}>Systolic</p>
          {this.inputConstructor("pressureUnderSys", "Blood pressure under", this.renderErrorMessage("pressureUnderSysValueError"))}
          {this.inputConstructor("pressureOverSys", "Blood pressure over", this.renderErrorMessage("pressureOverSysValueError"))}
          <p style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#212121"
          }}>Diastolic</p>
          {this.inputConstructor("pressureUnderDis", "Blood pressure under", this.renderErrorMessage("pressureOverSysValueError"))}
          {this.inputConstructor("pressureOverDis", "Blood pressure over", this.renderErrorMessage("pressureOverDisValueError"))}
        </FormWrapper>
      </div>
    )
  }

  renderBtns = () => {
    return(
      <div style={{width: "400px", margin: "20px auto", display: "flex", justifyContent: "space-between"}}>
        <DepartmentsCancelBtn
          variant="outline-secondary"
          onClick={() => this.props.goPrev()}
        >
          {this.props.fBtnTitle}
        </DepartmentsCancelBtn>
        <DepartmentsAddBtn
          variant="primary"
          onClick={() => this.goNext()}
          disabled={false}
        >
          {this.props.sBtnTitle}
        </DepartmentsAddBtn>
      </div>
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  render(){
    return <>
      {this.renderECG()}
      {this.line()}
      {this.renderTemp()}
      {this.line()}
      {this.renderSpO2()}
      {this.line()}
      {this.renderPressure()}
      {this.line()}
      {this.renderBtns()}
    </>
  }
}

export default FourthStep