import React from 'react'
import { Chart } from 'react-charts'

function CustomTooltip({ datum }) {
  if(!datum) return null
  return <div style={{padding: 0, margin: 0}}>
    <p style={{color: "white", padding: "5px"}}>{datum.originalDatum.y.toFixed(2)} {datum.originalSeries.label}</p>
  </div>
}

export default function ChartComponent({saturations,bp,hr,measurements, typeDate, typeChart, t, date}) {
  const isBP = typeChart === "Blood Pressure"
  let bpMin = 40
  let bpMax = 200
  const bpSystolic = bp.map(item => {
    if(item.systolic < bpMin) bpMin = item.systolic - 3
    if(item.systolic > bpMax) bpMax = item.systolic + 3
    return {
      type: "systolic",
      y: item.systolic,
      x: typeDate === "Day" ? new Date(item.date).getHours() : new Date(item.date),
      zone: item.zone
    }
  })

  const bpDiastolic = bp.map(item => {
    if(item.diastolic < bpMin) bpMin = item.diastolic - 3
    if(item.diastolic > bpMax) bpMax = item.diastolic + 3
    return {
      type: "diastolic",
      y: item.diastolic,
      x: typeDate === "Day" ? new Date(item.date).getHours() : new Date(item.date),
      zone: item.zone
    }
  })

  let spO2Min = 84
  let spO2Max = 100
  const spO2 = saturations.map(item => {
    if(item.saturation < spO2Min) spO2Min = item.saturation - 3
    if(item.saturation > spO2Max) spO2Max = item.saturation + 3
    return {
      y: item.saturation,
      x: typeDate === "Day" ? new Date(item.date).getHours() : new Date(item.date),
      zone: item.zone
    }
  })

  let tempMin = 35
  let tempMax = 40
  const measData = measurements.map(item => {
    if(item.temperature < tempMin) tempMin = item.temperature - 3
    if(item.temperature > tempMax) tempMax = item.temperature + 3
    return {
      y: item.temperature,
      x: typeDate === "Day" ? new Date(item.date).getHours() : new Date(item.date),
      zone: item.zone
    }
  })

  let hrMin = 20
  let hrMax = 180
  const hrData = hr.map(item => {
    if(item.heart_rate < hrMin) hrMin = item.heart_rate - 3
    if(item.heart_rate > hrMax) hrMax = item.heart_rate + 3
    return {
      y: item.heart_rate,
      x: typeDate === "Day" ? new Date(item.date).getHours() : new Date(item.date),
      zone: item.zone
    }
  })

  const getDataByType = () => {
    switch(typeChart) {
      case "Temperature": return measData;
      case "Heart Rate": return hrData;
      case "SpO2": return spO2;
      default: return measData
    }
  }

  const getHardDataByType = () => {
    switch(typeDate){
      case "Day": return hardXTime;
      case "Month": return hardXDays;
      case "Year": return hardXMonth;
      default: return hardXTime;
    }
  }
  const hardXTime = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
  const hardXDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  const hardXMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  const hardX = getHardDataByType()
  const tempData = getDataByType()
  const newData: any = []
  hardX.forEach(x => {
    const data = tempData.find(item => item.x === x)
    if(!data) {
      newData.push({x})
    }else{
      newData.push(data)
    }
  })
  const getTempDataByType = () => {
    switch(typeDate){
      case "Day": return newData;
      case "Month": return getDataByType();
      case "Year": return getDataByType();
      default: return newData;
    }
  }
  const getValuesByChartName = () => {
    switch(typeChart){
      case "Temperature": return { diapason: {hardMin: tempMin, hardMax: tempMax}, icon: "ºC"};
      case "Heart Rate": return { diapason: {hardMin: hrMin, hardMax: hrMax}, icon: "bpm"};
      case "Blood Pressure": return { diapason: {hardMin: bpMin, hardMax: bpMax}, icon: "mmHg"};
      case "SpO2": return { diapason: {hardMin: spO2Min, hardMax: spO2Max}, icon: "%"};
      default: return { diapason: {hardMin: tempMin, hardMax: tempMax}, icon: "ºC"};
    }
  }
  const data = isBP ? [
    {
      label: getValuesByChartName().icon,
      data: bpSystolic
    },{
      label: getValuesByChartName().icon,
      data: bpDiastolic
    }
  ] : [{
    label: getValuesByChartName().icon,
    data: getTempDataByType()
  }]

  const series = React.useMemo(
    () => ({
      type: 'bar'
    }),
    []
  )

  const defs = (
    <defs>
      <linearGradient id="gradient" x1="0" x2="0" y1="1" y2="0">
        <stop offset="0%" stopColor="gray" />
        <stop offset="100%" stopColor="gray" />
      </linearGradient>
    </defs>
  )

  const calcDate = () => {
    switch(typeDate) {
      case "Day": return {hardMin: 0 , hardMax: 24};
      case "Month": return {hardMin: new Date(date).setDate(-1) , hardMax: new Date(date).setDate(31)};
      case "Year": return {hardMin: new Date(date).setMonth(-1) , hardMax: new Date(date).setMonth(12)};
      default: return {hardMin: new Date().setHours(0) , hardMax: new Date().setHours(24)};
    }
  }

  const axes = typeDate === "Day"
  ? [
    { primary: true, type: 'ordinal', position: 'bottom' },
    { position: 'left', type: 'linear', stacked: false, ...(getValuesByChartName().diapason) }
  ]
  : [
    { primary: true, type: 'time', position: 'bottom', ...calcDate() },
    { type: 'linear', position: 'left', ...(getValuesByChartName().diapason) }
  ]

  const tooltip = React.useMemo(
    () => ({
      render: ({ datum, primaryAxis, getStyle }) => {
        return <CustomTooltip {...{ getStyle, primaryAxis, datum }} />
      }
    }),
    []
  )

  const lineChart = (
    <div
      style={{
        position: "relative",
        paddingTop: "20px",
        width: '100%',
        height: '350px'
      }}
    >
      <div style={{position: "absolute", top: "-5px", fontSize: "12px"}}>
        {getValuesByChartName().icon}
      </div>
      <div style={{position: "absolute", bottom: "-1px", right: "-17px", fontSize: "12px"}}>Time</div>
      {isBP && <div style={{position: "absolute", top: "-5px", right: "-20px", display: "flex"}}>
        <div style={{display: "flex", alignItems: "center", padding: "0 10px"}}>
          <div style={{width: "10px", height: "10px", borderRadius: "10px", background: "#38699E", marginRight: "10px"}}/>
          <span
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#757575"
          }}>Systolic</span>
        </div>
        <div style={{display: "flex", alignItems: "center", padding: "0 10px"}}>
          <div style={{width: "10px", height: "10px", borderRadius: "10px", background: "#424242", marginRight: "10px"}}/>
          <span
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#757575"
          }}>Diastolic</span>
        </div>
      </div>}

      <Chart
        series={ typeDate === "Day" && !isBP ? series : null}
        tooltip={tooltip}
        data={data}
        axes={axes}
        getSeriesStyle={series => ({
          color: `url(#gradient)`
        })}
        getDatumStyle={datum => {
          const getColor = () => {
            if(datum.originalDatum.type){
              return datum.originalDatum.type === "systolic" ? "#38699E" : "#424242"
            } else {
              return datum.originalDatum.zone
            }
          }
        return {
          strokeWidth: 4,
          color: getColor()
        }}}
        renderSVG={() => defs}
      />
    </div>
  )

  return lineChart
}