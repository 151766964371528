import { ApiBase } from "../api-base"

export class CompanyApi extends ApiBase {

  get apiName() {
    return "company"
  }

  async initCompany(data) {
    return await this.post("/init/", { body: data})
  }

  async getCompanies() {
    const resp = await this.get("/list/")
    return resp.data
  }

  async addManager(data) {
    const resp = await this.post("/invite_companyowner/", { body: data})
    return resp.data
  }

  async getManagers(id_company) {
    const resp = await this.post("/list_managers/", { body: {id_company}})
    return resp.data
  }

}
