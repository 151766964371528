import React from "react"
import {
  FormWrapper,
  StepTitle,
  DepartmentsCancelBtn,
  DepartmentsAddBtn,
  Control
} from "../../add_patient/add_patient.presets"
import { Form } from "react-bootstrap"
import { ErrorMessageWrapper, ErrorMessageText } from "../../branch/branch.presets"

interface IFirstStep {
  firstName: string;
  lastName: string;
}

class FirstStep extends React.Component<{
  goNext: (obj) => void,
  goPrev: () => void,
  fBtnTitle: string,
  sBtnTitle: string,
  data: IFirstStep,
  changeValue: (field: string, value: string) => void,
}>{

  state = {
    errorLastName: "",
    errorFirstName: "",
  }

  goNext = async () => {
    const state = {
      errorFirstName: "",
      errorLastName: "",
    }
    const defaultError = "This field is required."
    if (this.props.data.firstName.length === 0) {
      state.errorFirstName = defaultError
    }
    if (this.props.data.lastName.length === 0) {
      state.errorLastName = defaultError
    }
    if (Object.values(state).find(item => item.length > 0)) {
      this.setState(state)
      return
    } else {
      this.setState(state)
      this.props.goNext({
        first_name: this.props.data.firstName,
        last_name: this.props.data.lastName,
      })
    }
  }

  renderErrorMessage = (type: string) => {
    return(
      <ErrorMessageWrapper>
        <ErrorMessageText>
          {this.state[type]}
        </ErrorMessageText>
      </ErrorMessageWrapper>
    )
  }

  renderFNAme = () => {
    return(
      <Form.Group controlId="formFirstName">
        <Form.Label style={{color: "#212121"}}>
          <span style={{color: "red"}}>*</span> First name
        </Form.Label>
        <Control
          showerror={this.state.errorFirstName.length ? "true" : "false"}
          onFocus={() => this.setState({errorFirstName: ""})}
          type="text"
          placeholder={"Your First name"}
          value={this.props.data.firstName}
          onChange={(e) => this.props.changeValue("firstName", e.target.value)}
        />
        {this.renderErrorMessage("errorFirstName")}
      </Form.Group>
    )
  }

  renderLName = () => {
    return(
      <Form.Group controlId="formLastName">
        <Form.Label style={{color: "#212121"}}>
          <span style={{color: "red"}}>*</span> Last name
        </Form.Label>
        <Control
          showerror={this.state.errorLastName.length ? "true" : "false"}
          onFocus={() => this.setState({errorLastName: ""})}
          type="text"
          placeholder={"Your Last name"}
          value={this.props.data.lastName}
          onChange={(e) => this.props.changeValue("lastName", e.target.value)}
        />
        {this.renderErrorMessage("errorLastName")}
      </Form.Group>
    )
  }

  renderFirstStep = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Patient information
          </StepTitle>
          {this.renderFNAme()}
          {this.renderLName()}
        </FormWrapper>
      </div>
    )
  }

  renderBtns = () => {
    return(
      <div style={{width: "400px", margin: "20px auto", display: "flex", justifyContent: "space-between"}}>
        <DepartmentsCancelBtn
          variant="outline-secondary"
          onClick={() => this.props.goPrev()}
        >
          {this.props.fBtnTitle}
        </DepartmentsCancelBtn>
        <DepartmentsAddBtn
          variant="primary"
          onClick={() => this.goNext()}
          disabled={false}
        >
          {this.props.sBtnTitle}
        </DepartmentsAddBtn>
      </div>
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  render(){
    return <>
      {this.renderFirstStep()}
      {this.line()}
      {this.renderBtns()}
    </>
  }
}

export default FirstStep