import { types } from "mobx-state-tree"

export const UserDataModel = types.model("UserData").props({
  language: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  role: types.optional(types.string, "owner"),
  account_type: types.optional(types.string, "rpm")
})

export const constructUser = (dto) => {
  let user = UserDataModel.create({
    ...dto,
    role: dto.role,
    account_type: dto.account_type || 'rpm'
  })
  return user
}

export type UserData = typeof UserDataModel.Type