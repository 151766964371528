import React from "react"
import presets, { 
  Wrapper,
  Logo,
  FormWrapper,
  Title,
  Control,
  Description,
  PasswordTitle,
  ControlWrapper,
  ValidationDesc
} from "./new-password.presets"
import { Icon } from "../../../../icons/icon"
import { Form, Button } from "react-bootstrap"
import { smartInject } from "../../../../utils/smartInject"
import { GlobalProps } from "../../../global/global.props"
import { withTranslation, WithTranslation } from 'react-i18next';

@(withTranslation() as any)
@smartInject(GlobalProps)
export class NewPassword extends React.Component<GlobalProps & WithTranslation> {

  state = {
    first: "",
    second: "",
    isDisabledBtn: true,
    passwordError: "",
    errorMin8: false,
    errorLeast1num: false,
    errorLeast1symb: false
  }

  changeValue = (e: any, type: string) => {
    this.setState({[type]: e.target.value}, () => {
      this.validation()
    })
  }

  reset = () => {
    const { errorMin8, errorLeast1num, errorLeast1symb } = this.state
    if (errorMin8 || errorLeast1num || errorLeast1symb) {
      this.setState({
        passwordError: `Password must be at least 8 characters 1 number and 1 symbol.`
      })
    } else {
      alert("DONE!")
      this.props.authStore.goTo("signin")
    }
  }

  renderLogo = () => {
    return(
      <Logo>
        <Icon icon="logo"/>
      </Logo>
    )
  }

  renderErrorMessage = (type: string) => {
    return(
      <div style={{width: "100%", position: "relative" }}>
        <span style={{color: "red", fontSize: "12px", position: "absolute", width: "100%", textAlign: "right"}}>
          {this.state[type]}
        </span>
      </div>
    )
  }

  validation = () => {
    const valid = (reg, type) => {
      this.setState({[type]: this.state.first.length ? !this.state.first.match(reg) : false})
    }
    const min8 = /^.{8,}$/
    const least1num = /.*[0-9].*/
    const least1symb = /.*[#$"№;.?><|}{£€±§^+=!*()@%&].*/
    valid(min8, "errorMin8")
    valid(least1num, "errorLeast1num")
    valid(least1symb, "errorLeast1symb")
  }

  getColor = (type) => {
    if(this.state[type] && this.state.first.length > 0){
      return "red"
    } else if(!this.state[type] && this.state.first.length === 0) {
      return "#616161"
    } else {
      return "#2E7D32"
    }
  }

  renderValidationDescritpion = () => {
    return(
      <div style={{position: "absolute", left: "420px", top: "142px", width: "35%"}}>
        <ValidationDesc color={() => this.getColor("errorMin8")}>• Minimum 8 characters</ValidationDesc>
        <ValidationDesc color={() => this.getColor("errorLeast1num")}>• At least 1 number</ValidationDesc>
        <ValidationDesc color={() => this.getColor("errorLeast1symb")}>• At least 1 symbol</ValidationDesc>
      </div>
    )
  }

  renderForm = () => {
    const isPasswordError = this.state.passwordError.length > 0
    const isPass = this.state.first.length > 0 && this.state.second.length > 0
    const isMatch = isPass && this.state.first === this.state.second
    return(
      <FormWrapper>
        <Title>New Password</Title>
        <div style={{display: "flex", justifyContent: "center", paddingBottom: "30px"}}>
          <Description>Please enter your new password</Description>
        </div>
        <Form.Group controlId="formGroupPassword1">
          <PasswordTitle>
            <Form.Label style={{color: "#212121"}}>New Password</Form.Label>
          </PasswordTitle>
          <ControlWrapper>
            <Control
              showerror={isPasswordError ? "true" : "false"}
              type={"password"}
              onFocus={() => this.setState({passwordError: ""})}
              placeholder="Enter new password"
              onChange={(e) => this.changeValue(e, "first")}
            />
          </ControlWrapper>
          {this.renderErrorMessage("passwordError")}
        </Form.Group>
        <Form.Group controlId="formGroupPassword">
          <PasswordTitle>
            <Form.Label style={{color: "#212121"}}>Confirm Password</Form.Label>
          </PasswordTitle>
          <ControlWrapper>
            <Control
              showerror={"false"}
              type={"password"}
              onFocus={() => this.setState({passwordError: ""})}
              placeholder="Confirm new password"
              onChange={(e) => this.changeValue(e, "second")}
            />
          </ControlWrapper>
        </Form.Group>
        <Button
          block
          onClick={this.reset}
          variant="secondary"
          style={isMatch ? presets.btnOn : presets.btnOff}
          disabled={!isMatch}
        >
          Reset Password
        </Button>
        {this.renderValidationDescritpion()}
      </FormWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        {this.renderLogo()}
        {this.renderForm()}
        <div/>
      </Wrapper>
    )
  }
}