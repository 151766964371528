import React from "react"
import presets, { 
  Wrapper,
  Logo,
  Title,
  FormWrapper,
  ControlWrapper,
  PasswordTitle,
  Footer,
  PreTitle,
  FooterTitle,
  FooterBtn,
  Control, 
  ValidationDesc,
  TextArea} from "./signup.presets"
import { Icon } from "../../../../icons/icon"
import { Form, Button, Col, Row } from "react-bootstrap"
import { validateField, emailRule, passwordRule } from "../../../../services/validation"
import { smartInject } from "../../../../utils/smartInject"
import { GlobalProps } from "../../../global/global.props"
import { withTranslation, WithTranslation } from 'react-i18next';

@(withTranslation() as any)
@smartInject(GlobalProps)
export class Signup extends React.Component<GlobalProps & WithTranslation> {

  state = {
    type: "password",
    isDisabledBtn: true,
    emailError: "",
    passwordError: "",
    errorMin8: false,
    errorLeast1num: false,
    errorLeast1symb: false
  }

  componentDidMount() {
    this.props.authStore.reset()
  }

  changeType = () => {
    if(this.state.type === "password") this.setState({type: "text"})
    if(this.state.type === "text") this.setState({type: "password"})
  }

  changeValue = (e: any, type: string) => {
    this.props.authStore.setOwnerData(e.target.value, type)
    if(type === "password") this.validation()
  }

  sendData = async () => {
    const formattedEmail = (this.props.authStore.ownerData.email || "").trim().toLowerCase()
    const validationEmail: any = validateField(emailRule, formattedEmail)
    const validationPassword: any = validateField(passwordRule, this.props.authStore.ownerData.password)
    if (validationEmail.isValid && validationPassword.isValid) {
      const resp = await this.props.authStore.signup()
      if(resp) this.goToSignIn()
    } else {
      this.setState({
        emailError: validationEmail.error ? validationEmail.error : "",
        passwordError: validationPassword.error ? validationPassword.error : ""
      })
    }
  }

  goToForgotPassword = () => {
    this.props.authStore.goTo("forgot-password")
  }

  goToSignIn = () => {
    this.props.authStore.goTo("signin")
  }

  renderLogo = () => {
    return(
      <Logo>
        <Icon icon="logo"/>
      </Logo>
    )
  }

  renderEmailMessage = () => {
    const {t} = this.props
    return(
      <div style={presets.emailMessageWrapper as any}>
        {this.state.emailError ? <span style={presets.emailError as any}>
          {this.state.emailError}
        </span> : <span style={presets.emailMessage as any}>
          {t("auth.signup.personal.emailSubtext")}
        </span>}
      </div>
    )
  }

  renderErrorMessage = (type: string) => {
    return(
      <div style={presets.errorMessageWrapper as any}>
        <span style={presets.errorMessageText as any}>
          {this.state[type]}
        </span>
      </div>
    )
  }

  validation = () => {
    const valid = (reg, type) => {
      const {ownerData} = this.props.authStore
      this.setState({[type]: ownerData.password.length ? !ownerData.password.match(reg) : false})
    }
    const min8 = /^.{8,}$/
    const least1num = /.*[0-9].*/
    const least1symb = /.*[#$"№;.?><|}{£€±§^+=!*()@%&].*/
    valid(min8, "errorMin8")
    valid(least1num, "errorLeast1num")
    valid(least1symb, "errorLeast1symb")
  }

  getColor = (type) => {
    if(this.state[type] && this.props.authStore.ownerData.password.length > 0){
      return "red"
    } else if(!this.state[type] && this.props.authStore.ownerData.password.length === 0) {
      return "#616161"
    } else {
      return "#2E7D32"
    }
  }

  renderValidationDescritpion = () => {
    const {t} = this.props
    return(
      <div style={{paddingTop: "10px"}}>
        <ValidationDesc color={() => this.getColor("errorMin8")}>• {t("validationMessage.characters")}</ValidationDesc>
        <ValidationDesc color={() => this.getColor("errorLeast1num")}>• {t("validationMessage.number")}</ValidationDesc>
        <ValidationDesc color={() => this.getColor("errorLeast1symb")}>• {t("validationMessage.symbol")}</ValidationDesc>
      </div>
    )
  }

  renderRegistartion = () => {
    const isEmailError = this.state.emailError.length > 0
    const isPasswordError = this.state.passwordError.length > 0
    const { role } = this.props.authStore
    const {t} = this.props
    const roleModify = role && (role[0].toUpperCase() + role.slice(1))
    return(
      <Row style={{marginLeft: "-180px"}}>
        <Col>
        <PreTitle marginTop={"69px"}>{t("auth.signup.personal.title")}</PreTitle>
        </Col>
        <Col>
          <FormWrapper>
            <Title>{t("auth.signup.title")}</Title>
            <Form>
              <Form.Group controlId="formGroupRole">
                <Form.Label style={{color: "#212121"}}>{t("auth.signup.personal.roleInputTitle")}</Form.Label>
                <Control
                  showerror={"false"}
                  type="text"
                  value={roleModify}
                  disabled
                />
              </Form.Group>
              <Form.Group controlId="formGroupFullName">
                <Form.Label style={{color: "#212121"}}>{t("auth.signup.personal.fullNameInputTitle")}</Form.Label>
                <Control
                  showerror={"false"}
                  type="text"
                  value={this.props.authStore.ownerData.fullName}
                  placeholder={t("auth.signup.personal.fullNamePlaceholder")}
                  onChange={(e) => this.changeValue(e, "fullName")}
                />
              </Form.Group>
              <Form.Group controlId="formGroupEmail">
                <Form.Label style={{color: "#212121"}}>{t("auth.signup.personal.emailInputTitle")}</Form.Label>
                <Control
                  showerror={isEmailError ? "true" : "false"}
                  onFocus={() => this.setState({emailError: ""})}
                  type="email"
                  placeholder={t("auth.signup.personal.emailPlaceholder")}
                  onChange={(e) => this.changeValue(e, "email")}
                />
                {this.renderEmailMessage()}
              </Form.Group>
              <Form.Group controlId="formGroupPassword">
            <PasswordTitle>
              <Form.Label style={{color: "#212121"}}>{t("auth.signup.personal.passwordInputTitle")}</Form.Label>
              <span style={{color: "#38699E", cursor: "pointer"}} onClick={this.goToForgotPassword}>{t("auth.signup.personal.forgotPassword")}</span>
            </PasswordTitle>
            <ControlWrapper>
              <Control
                showerror={isPasswordError ? "true" : "false"}
                type={this.state.type}
                onFocus={() => this.setState({passwordError: ""})}
                placeholder={t("auth.signup.personal.passwordPlaceholder")}
                onChange={(e) => this.changeValue(e, "password")}
              />
              <Icon icon="eye" onClick={this.changeType} style={presets.icon}/>
            </ControlWrapper>
            {this.renderErrorMessage("passwordError")}
            {this.renderValidationDescritpion()}
          </Form.Group>
          </Form>
          {this.props.authStore.role !== "owner" && this.renderBtn()}
          </FormWrapper>
        </Col> 
      </Row>
    )
  }

  renderCompanyInfo = () => {
    const {t} = this.props
    return(
      <Row style={{marginLeft: "-180px"}}>
        <Col>
          <PreTitle marginTop={"0px"}>{t("auth.signup.company.title")}</PreTitle>
        </Col>
        <Col>
          <FormWrapper>
            <Form.Group controlId="formGroupCompanyName">
              <Form.Label style={{color: "#212121"}}>{t("auth.signup.company.nameInputTitle")}</Form.Label>
              <Control
                showerror={"false"}
                onFocus={() => this.setState({emailError: ""})}
                type="email"
                placeholder={t("auth.signup.company.namePlaceholder")}
                onChange={(e) => this.changeValue(e, "companyName")}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label style={{color: "#212121"}}>{t("auth.signup.company.descriptionInputTitle")}</Form.Label>
              <TextArea
                className="form-control"
                as="textarea"
                rows={5}
                placeholder={t("auth.signup.company.descriptionPlaceholder")}
                onChange={(e) => this.changeValue(e, "companyDesc")}
              />
            </Form.Group>
            {this.renderBtn()}
          </FormWrapper>
        </Col>
      </Row>
    )
  }

  renderBtn = () => {
    const { allDataEntered } = this.props.authStore
    const {t} = this.props
    return(
      <>
        <Button
          block
          onClick={this.sendData}
          variant="secondary"
          style={allDataEntered ? presets.btnOn : presets.btnOff}
          disabled={!allDataEntered}
        >
          {t("auth.signup.btnText")}
        </Button>
        <Footer>
          <FooterTitle>{t("auth.signup.footerText")}</FooterTitle>
          <FooterBtn
            style={{cursor: "pointer"}}
            onClick={this.goToSignIn}
          >{t("auth.signup.footerBtnText")}</FooterBtn>
        </Footer>
      </>
    )
  }

  renderForm = () => {
    return(
      <>
      {this.renderRegistartion()}
      {this.props.authStore.role === "owner" && 
        <>
          <Row style={{marginLeft: "-180px"}}>
            <Col><div style={presets.line}/></Col>
          </Row>
          {this.renderCompanyInfo()}
        </>}
      </>
    )
  }

  render(){
    return(
      <Wrapper>
        {this.renderLogo()}
        {this.renderForm()}
        <div/>
      </Wrapper>
    )
  }
}