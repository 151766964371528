import React, { Fragment } from "react"
import { styled } from "../theme"

export const DashboardTitle = styled.span`
  width: 100%;
  padding-bottom: 15px;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #38699E;
`

export class Breadcrumbs extends React.Component<{
  globalStore: any,
  data: Array<any>
}> {
  
  render(){
    if(!this.props.data) return
    return(
      this.props.data.filter(Boolean).map((item, index) => (
        index === this.props.data.filter(Boolean).length - 1
        ? <Fragment key={index}>{item.name}</Fragment>
        : <Fragment key={index}>
            <DashboardTitle
              key={index}
              style={{cursor: "pointer"}}
              onClick={() => this.props.globalStore.goTo(item.goTo)}
              >
                {item.name}
            </DashboardTitle>
            {item.name.length ? ` / ` : ""}
          </Fragment>
      ))
    )
  }
}