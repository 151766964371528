import React from "react"
import { Switch, Route, Redirect } from "react-router"
import { NavigationRoutes } from "../navigation"
import { NavigationRoute } from "../navigation/navigation.types"

function renderSingleRoute(route: NavigationRoute, path: string) {
  let component = route.component
  if (route.redirect) {
    let redirectPath = route.is_redirect_absolute ? route.redirect : path + route.redirect
    component = () => (<Redirect to={redirectPath} />)
  }
  return <Route
    key={route.path}
    path={path + route.path}
    component={component}
  />
}
export function renderModuleRouting(path: string, routeMap: NavigationRoutes) {
  return (
    <Switch>
      {routeMap.map(route => renderSingleRoute(route, path))}
    </Switch>
  )
}