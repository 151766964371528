import { types, getEnv, getRoot } from "mobx-state-tree"
import { Environment } from "../../app/environment"
import { RootStore } from "../../app/root-store"
import moment from "moment"
import { patchName } from '../../utils/plans';

export const AddPatientStoreModel = types.model("AddPatientStore")
  .volatile(() => ({
    newPatient: {
      id: "",
      mrn: "",
      supervisor_name: "",
      supervisor_id: "",
      connection_plan_id: "",
      phone: "",
      gender: "",
      first_name: "",
      last_name: "",
      birthday: "",
      icd_code: "",
      cpt_code: "",
      filters: {} as any,
      reminders: [] as any,
      connection_plan: "",
      shipping: {} as any
    },
    supervisors: [],
    planList: [],
    errorsMessages: {} as any,
    billingCodes: [],
    mrnExists: false,
    patientPatchRecords: [],
  }))
  .actions((self) => ({
    changeValue(type: string, value: string) {
      self.newPatient[type] = value
    },
    changeError(type: string, value: string) {
      self.errorsMessages[type] = value
    },
    setPlanList(list) {
      self.planList = list
    },
    setIsValidMRN({mrn_exists}) {
      self.mrnExists = mrn_exists
    },
    setBillingCodes(codes) {
      self.billingCodes = codes
    },
    setSupervisors(supervisors) {
      self.supervisors = supervisors
    },
    setPatientPatchRecords(patientPatchRecords) {
      self.patientPatchRecords = patientPatchRecords
    },
  }))
  .actions(self => ({
    async getConnectionPlanList() {
      const env: Environment = getEnv(self)
      const resp = await env.api.dashboard.getPlanList()
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setPlanList(data.data)
      }
    },
    async isMRNRegistered(mrn) {
      const env: Environment = getEnv(self)
      const resp = await env.api.dashboard.isMRNReg({mrn})
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setIsValidMRN(data.data)
      }
    },
    async getBilling() {
      const env: Environment = getEnv(self)
      const resp = await env.api.dashboard.getBillingCodes()
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setBillingCodes(data.data)
      }
    },
    async getSupervisors() {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const resp = await env.api.department.listSupervisors({
        id_department: root.globalStore.currentDepartment.id,
      })
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setSupervisors(data.data)
      }
    },
    async getPatientPatchRecords() {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const resp = await env.api.dashboard.listPatientPatchRecords({
        id_unique: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
      })
      if(resp) {
        const data = resp.data
        if(!data) return
        self.setPatientPatchRecords(data.data)
      }
    },
    async setFilters() {
      const env: Environment = getEnv(self)
      const { filters } = self.newPatient
      filters.id_user = self.newPatient.id
      const resp = await env.api.dashboard.setFilters(filters)
      if(resp) {
        const data = resp.data
        if(!data) return
      }
    },
    async setReminders() {
      const env: Environment = getEnv(self)
      const { reminders } = self.newPatient
      const data = {
        reminders,
        id_user: self.newPatient.id
      }
      const resp = await env.api.dashboard.setReminders(data)
      if (resp) {
        const data = resp.data
        if(!data) return
      }
    },
    async addPatient(){
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const profile = {
        profile: {
          mrn: self.newPatient.mrn,
          supervisor_name: self.newPatient.supervisor_name,
          supervisor_id: self.newPatient.supervisor_id,
          id_department: root.globalStore.currentDepartment.id,
          id_company: root.globalStore.currentCompany.id,
          phone: self.newPatient.phone,
          gender: self.newPatient.gender,
          first_name: self.newPatient.first_name,
          last_name: self.newPatient.last_name,
          birthday: moment(self.newPatient.birthday).format("YYYY-MM-DD"),
          icd_code: self.newPatient.icd_code,
          cpt_code: self.newPatient.cpt_code
        },
        shipping: {
          street: self.newPatient.shipping.street,
          city: self.newPatient.shipping.city,
          state: self.newPatient.shipping.state,
          zip_code: self.newPatient.shipping.zip_code
        },
        connection_plan_id: `${self.newPatient.connection_plan_id}`
      }
      const resp = await env.api.dashboard.inviteMedicalPatient(profile)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.changeValue("id", data.data.unique_id)
      }
    },
    async addCaregiver(){
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const profile = {
        id_department: root.globalStore.currentDepartment.id,
        id_company: root.globalStore.currentCompany.id,
        first_name: self.newPatient.first_name,
        last_name: self.newPatient.last_name,
      }
      const resp = await env.api.dashboard.inviteCaregiverUser(profile)
      if(resp) {
        const data = resp.data
        if(!data) return
        self.changeValue("id", data.data.unique_id)
      }
    },
    
  }))

  .actions(self => ({
    async createPatient() {
      await self.addPatient()

      if (self.newPatient.connection_plan !== patchName) {
        await self.setFilters()
        await self.setReminders()
      }
    },
    async createCaregiver() {
      await self.addCaregiver()
    }
  }))

export type AddPatientStore = typeof AddPatientStoreModel.Type