import { ApiBase } from "../api-base"

export class ManagerApi extends ApiBase {

  get apiName() {
    return "manager"
  }

  async initManager(data) {
    return await this.post("/create/", { body: data})
  }

  async getManagers() {
    const resp = await this.get("/list/")
    return resp.data
  }

}
