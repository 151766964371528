import React from "react"
import {
  FormWrapper,
  StepTitle,
  DepartmentsAddBtn
} from "../../add_patient/add_patient.presets"
var QRCode = require('qrcode.react')

class SecondStep extends React.Component<{
  id: string,
  goNext: () => void,
  sBtnTitle: string
}>{

  renderStep = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
          ID number: {this.props.id}
          </StepTitle>
          <FormWrapper>
            <div style={{margin: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <QRCode value={this.props.id} size={256} />
            </div>
          </FormWrapper>
        </FormWrapper>
      </div>
    )
  }

  renderBtns = () => {
    return(
      <div style={{width: "400px", margin: "20px auto", display: "flex", justifyContent: "center"}}>
        <DepartmentsAddBtn
          variant="primary"
          onClick={() => this.props.goNext()}
          disabled={false}
        >
          {this.props.sBtnTitle}
        </DepartmentsAddBtn>
      </div>
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  render(){
    return <>
      {this.renderStep()}
      {this.line()}
      {this.renderBtns()}
    </>
  }
}

export default SecondStep