// Colors variables
export enum Colors {

  /**
   * @White #ffffff
   */
  white = "#fff",

  /**
   * @Black #000000
   */
  black = "#000000",

  /**
   * @MainBlue #38699E
   */
  mainBlue = "#38699E",

  /**
   * @DeepBlue #265B95
   */
  deepBlue = "#265B95",

  /**
   * @Gray #757575
   */
  gray = "#757575",

  /**
   * @DarkGray #6C7A8B
   */
  darkGray = "#6C7A8B",

  /**
   * @LightGray #D2DDE9
   */
  lightGray = "#D2DDE9",

  /**
   * @BgColor #F8F8F8
   */
  bgColor = "#EFEFEF",

  /**
   * @BrightBlue #4E93DE
   */
  brightBlue = "#4E93DE",

  /**
   * @Blue #79F1E9
   */
  blue = "#79F1E9",

  /**
   * @Green #52DC76
   */
  green = "#52DC76",

  /**
   * @Red #DC5252
   */
  red = "#DC5252",

  /**
   * @Yellow #F5D350
   */
  yellow = "#F5D350",

  /**
   * @Transparent transparent
   */
  transparent = "transparent",

  /**
   * @Shadow rgba(0,0,0,0.2)
   */
  shadow = "rgba(0,0,0,0.2)",

  /**
   * @DarkShadow rgba(0,0,0,0.4)
   */
  darkShadow = "rgba(0,0,0,0.4)"
}



