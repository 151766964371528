import { ReactComponent as logo } from "./logo.svg"
import { ReactComponent as eye } from "./eye.svg"
import { ReactComponent as arrowLeft } from "./arrow-left.svg"
import { ReactComponent as dashboardLogo } from "./dashboard-logo.svg"
import { ReactComponent as plus } from "./plus.svg"
import { ReactComponent as star } from "./star.svg"
import { ReactComponent as search } from "./search.svg"
import { ReactComponent as close } from "./close.svg"
import { ReactComponent as calendar } from "./calendar.svg"
import { ReactComponent as list } from "./list.svg"
import { ReactComponent as normal } from "./normal.svg"
import { ReactComponent as pending } from "./pending.svg"
import { ReactComponent as risk } from "./risk.svg"
import { ReactComponent as ring } from "./ring.svg"
import { ReactComponent as copy } from "./copy.svg"
import { ReactComponent as reportGreen } from "./reportGreen.svg"
import { ReactComponent as reportBlue } from "./reportBlue.svg"
import { ReactComponent as reportGray } from "./reportGray.svg"
import { ReactComponent as heart } from "./heart.svg"
import { ReactComponent as low } from "./low.svg"
import { ReactComponent as complete } from "./complete.svg"
import { ReactComponent as reminder } from "./reminders.svg"
import { ReactComponent as back } from "./back.svg"
import { ReactComponent as pen } from "./pen.svg"
import { ReactComponent as sign } from "./sign.svg"
import { ReactComponent as people } from "./people.svg"
const icons = {
  logo,
  eye,
  plus,
  star,
  close,
  search,
  ring,
  risk,
  pending,
  normal,
  list,
  copy,
  calendar,
  dashboardLogo,
  arrowLeft,
  reportGray,
  reportBlue,
  reportGreen,
  heart,
  low,
  complete,
  reminder,
  back,
  pen,
  sign,
  people
}

export default icons
export type Icon = keyof typeof icons
