//
// Load environment variables in.
//
// IMPORTANT:
//
//   1.  These might be null, so fallback to sane defaults accordingly where you
//       make use of these.
//
//   2.  You must use this syntax: process.env.NAME_OF_ENV_VAR.  No funny stuff
//       or the babel plugin won't work.
//
//   3.  You must whitelist each one in your `.babelrc` file.
//
// GOTCHA:
//
//   Babel will cache things extensively. In dev, to bust this cache to pick up
//   new environment variable values, just change this file and resave it.
//
//   Or run `yarn start --reset-cache` to nuke babel's cache entirely
//   (overkill).
//
// ----------------------------------------------------------------------------

// tell typescript that there will be a the `node.js` process global variable used
declare var process: any

declare global {
  interface Window { WEB: Record<string, string> }
}

const params = window.WEB || process.env

/**
 * An example importing an environment variable.
 */

export const API_BASE_URL: string = params.REACT_APP_API_BASE_URL
export const API_USER_URL: string = params.REACT_APP_API_USER_URL
export const API_DATA_URL: string = params.REACT_APP_API_DATA_URL
export const API_DEVICE_URL: string = params.REACT_APP_API_DEVICE_URL