import React from "react"
import { 
  Wrapper,
  DashboardWrapper,
  DashboardTitle,
  DashboardContainer,
  CompanyTitle,
  DepartmentIconWrapper,
  DepartmentPopupWrapper,
  DepartmentPopup,
  Date,
  FormWrapper,
  DepartmentPopupTitle
} from "./department.presets"
import { Icon } from "../../icons/icon"
import { smartInject } from "../../utils/smartInject"
import { Tabs, Tab, Row, Col } from "react-bootstrap"
import moment from "moment"
import { GlobalProps } from "../global/global.props"
import { DashboardHeader } from "../../components/dashboard-header"
import { withTranslation, WithTranslation } from 'react-i18next'
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import "./style.css"
import { EmployeesPage } from "./pages/employees"
import { DailyDashboardPage } from "./pages/daily-dashboard"
import { Breadcrumbs } from "../../shared/Breadcrumbs"
import { SupervisorsPage } from "./pages/supervisors"
var QRCode = require('qrcode.react')

@(withTranslation() as any)
@smartInject(GlobalProps)
export class Department extends React.Component<GlobalProps & WithTranslation> {

  userData: any
  state: any
  initialState: any
  constructor(props){
    super(props)
    this.userData = []
    this.state = {
      isLoading: false,
      showAddEmployeePopup: false,
      showAddSchedulePopup: false,
      showQrPopup: false,
      tab: "Daily Dashboard",
      idForQrCode: "",
      page: 0,
      pageAll: 0
    }
    this.initialState = this.state
  }

  async componentWillMount() {
    this.setState({isLoading: true})
    const { isAOwner } = this.props.globalStore
    this.props.departmentStore.resetEmployees()
    isAOwner && await this.props.departmentStore.getDepartmentMoveList()
    await this.props.departmentStore.getAllEmployees(this.state.pageAll)
    await this.props.departmentStore.getSchedule()
    await this.props.departmentStore.getEmployees(this.props.globalStore.currentDate, this.state.page)
    this.setState({isLoading: false})
  }

  componentDidMount(){
    this.setCustomDateStyles()
  }

  setCustomDateStyles = () => {
    const el: any = document.querySelectorAll(".MuiInputBase-input")
    const el2: any = document.querySelectorAll(".MuiFormControl-root")
    el[0].setAttribute("id", "CustomMuiWrapper");
    el2[0].setAttribute("id", "CustomMuiContainer");
  }

  componentWillUnmount() {
    this.props.departmentStore.reset()
    this.props.globalStore.setCurrent(this.props.globalStore.todaysDate, "currentDate")
  }

  loadMore = () => {
    this.setState(
      {page: this.state.page + 1},
      async () => {
        await this.props.departmentStore.getEmployees(this.state.date, this.state.page)
      }
    )
  }

  loadMoreAll = () => {
    this.setState(
      {pageAll: this.state.pageAll + 1},
      async () => {
        await this.props.departmentStore.getAllEmployees(this.state.pageAll)
      }
    )
  }

  chooseDate = async (date) => {
    this.props.departmentStore.resetEmployees()
    this.setState({isLoading: true})
    this.props.globalStore.setCurrent(date, "currentDate")
    this.props.globalStore.setCurrent(date, "userInfoCurrentDate")
    await this.props.departmentStore.getAllEmployees(this.state.pageAll)
    await this.props.departmentStore.getEmployees(date, this.state.page)
    await this.props.departmentStore.getReport()
    this.setState({isLoading: false})
  }

  changeValue = (e, type) => {
    this.props.departmentStore.setNewEmployeeData(type === "birthday" ? +moment(e) : e.target.value, type)
  }

  changePhone = (phone) => {
    this.props.departmentStore.setNewEmployeeData(phone, "phone")
  }

  addEmployee = async () => {
    const user = await this.props.departmentStore.addEmployee()
    if(user.data) {
      this.props.departmentStore.resetNewEmployee()
      this.setState({idForQrCode: user.data.data.unique_id, showQrPopup: true})
    } else {
      this.props.departmentStore.resetNewEmployee()
    }
  }

  search = (e, type, subType) => {
    this.props.departmentStore.search(e.target.value.toUpperCase(), type, subType)
  }

  goToUserInfo = async (data) => {
    await this.props.departmentStore.goToUserInfo(data)
  }

  renderQrPopup = () => {
    const {t}= this.props
    return this.state.showQrPopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showQrPopup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
              {t("dashboard.departament.qr.title")}
            </DepartmentPopupTitle>
            <div style={{
              fontSize: "14px",
              color: "#212121",
              textAlign: "center"
            }}>{t("dashboard.departament.qr.desc")}</div>
            <div style={{margin: "30px", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <QRCode value={this.state.idForQrCode} size={256} />
            </div>
            <div style={{
              fontSize: "20px",
              color: "#212121",
              textAlign: "center"
            }}>{this.state.idForQrCode}</div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  doctorDepartment = () => {
    const {t} = this.props
    return(
      <Tabs
        defaultActiveKey={"Daily Dashboard"}
        id="uncontrolled-tab-example"
        style={{
          marginRight: "-15px",
          marginLeft: "-15px"
        }}
      >
        <Tab eventKey="Daily Dashboard" title={t("dashboard.department.tabs.first")}>
          <DailyDashboardPage
            t={this.props.t}
            loadMore={this.loadMore}
            hideLodaMore={this.props.departmentStore.hideLoadMore}
            addEmployee={this.addEmployee}
            changeValue={this.changeValue}
            changePhone={this.changePhone}
            globalStore={this.props.globalStore}
            departmentStore={this.props.departmentStore}
            tab={this.state.tab}
            isLoading={this.state.isLoading}
            goToUserInfo={this.goToUserInfo}
            search={this.search}
          />
        </Tab>
        <Tab eventKey="Employees" title={t("dashboard.department.tabs.second")}>
          <EmployeesPage
            t={this.props.t}
            loadMore={this.loadMoreAll}
            hideLodaMoreAll={this.props.departmentStore.hideLoadMoreAll}
            addEmployee={this.addEmployee}
            changeValue={this.changeValue}
            changePhone={this.changePhone}
            globalStore={this.props.globalStore}
            departmentStore={this.props.departmentStore}
            tab={this.state.tab}
            isLoading={this.state.isLoading}
            goToUserInfo={this.goToUserInfo}
            search={this.search}
          />
        </Tab>
      </Tabs>
    )
  }

  renderDepartment = () => {
    const {t} = this.props
    return(
      <Tabs
        defaultActiveKey={"Daily Dashboard"}
        id="uncontrolled-tab-example"
        style={{
          marginRight: "-15px",
          marginLeft: "-15px"
        }}
        onSelect={(k) => {
          this.userData = []
          this.setState(
            {globalCheck: false,
              tempFlag: false, tab: k},
              () => {if(k === "Daily Dashboard") this.setCustomDateStyles()}
          )
        }}
      >
        <Tab eventKey="Daily Dashboard" title={t("dashboard.department.tabs.first")}>
          <DailyDashboardPage
            t={this.props.t}
            loadMore={this.loadMore}
            hideLodaMore={this.props.departmentStore.hideLoadMore}
            addEmployee={this.addEmployee}
            changeValue={this.changeValue}
            changePhone={this.changePhone}
            globalStore={this.props.globalStore}
            departmentStore={this.props.departmentStore}
            tab={this.state.tab}
            isLoading={this.state.isLoading}
            goToUserInfo={this.goToUserInfo}
            search={this.search}
          />
        </Tab>
        <Tab eventKey="Employees" title={t("dashboard.department.tabs.second")}>
          <EmployeesPage
            t={this.props.t}
            loadMore={this.loadMoreAll}
            hideLodaMoreAll={this.props.departmentStore.hideLoadMoreAll}
            addEmployee={this.addEmployee}
            changeValue={this.changeValue}
            changePhone={this.changePhone}
            globalStore={this.props.globalStore}
            departmentStore={this.props.departmentStore}
            tab={this.state.tab}
            isLoading={this.state.isLoading}
            goToUserInfo={this.goToUserInfo}
            search={this.search}
          />
        </Tab>
        {<Tab eventKey="Supervisors" title={t("dashboard.department.tabs.third")}>
          <SupervisorsPage
            t={this.props.t}
            globalStore={this.props.globalStore}
            departmentsStore={this.props.departmentsStore}
            departmentStore={this.props.departmentStore}
          />
        </Tab>}
      </Tabs>
    )
  }

  constructNavData = () => {
    const { t } = this.props
    const { currentCompany, user, currentDepartment } = this.props.globalStore
    const name = currentDepartment ? currentDepartment.name : ""
    const ownerData = [
      {
        goTo: "companies",
        name: t("dashboard.department.home")
      },{
        goTo: "departments",
        name: currentCompany ? currentCompany.name : ""
      },{
        name: name
      }
    ]
    const doctorData = [
      {
        goTo: "departments",
        name: t("dashboard.department.home")
      },{
        name: name
      }
    ]
    const defaultData = [
      {
        goTo: "departments",
        name: currentCompany ? currentCompany.name : ""
      },{
        name: name
      }
    ]
    switch (user.role) {
      case "owner": return ownerData
      case "companyowner": return defaultData
      case "doctor": return doctorData
      case "govdoctor": return doctorData
      case "support": return doctorData
      case "supervisor": return doctorData
      default: return defaultData
    }
  }

  renderNavigation = () => {
    return(
      <Breadcrumbs
        data={this.constructNavData()}
        globalStore={this.props.globalStore}
      />
    )
  }

  renderDashboard = () => {
    const { currentDepartment, currentCompany, isAOwner, isADoctor, isASupervisor, isASupport } = this.props.globalStore
    const {t} = this.props
    const dateTitle = moment(this.props.globalStore.currentDate).format("MMM DD") === moment().format("MMM DD")
    ? `Today,`
    : `${moment(this.props.globalStore.currentDate).format("YYYY")},`
    return(
      <DashboardWrapper>
        <DashboardTitle>
          {this.renderNavigation()}
        </DashboardTitle>
        <DashboardContainer>
          <Row style={{display: "flex", alignItems: "baseline"}}>
            <Col style={{fontSize: "14px"}}>{currentCompany ? currentCompany.name : ""}</Col>
            <Col><CompanyTitle>{currentDepartment ? currentDepartment.name : ""}</CompanyTitle></Col>
            <Col style={{textAlign: "end", fontSize: "14px"}}>
              {t("dashboard.department.dateAdd")}: {currentDepartment ?
                moment(currentDepartment.created).format("DD/MM/YYYY") :
                moment().format("DD/MM/YYYY")}
            </Col>
          </Row>
          <div style={{position: "relative"}}>
            {this.state.tab === "Daily Dashboard" && <Date>
              <span
              style={{color: "#38699E", cursor: "pointer", paddingRight: "10px", paddingBottom: "4px", fontWeight: 500}}
              onClick={() => this.chooseDate(+moment(this.props.globalStore.currentDate).subtract(1, 'd'))}>{"<"}</span>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={this.props.globalStore.currentDate}
                  onChange={(e) => this.chooseDate(e)}
                  labelFunc={() => `${dateTitle} ${moment(this.props.globalStore.currentDate).format("MMM DD")}`}
                  InputProps={{ style: {color: "#38699E", cursor: "pointer"}, disableUnderline: true }}
                />
              </MuiPickersUtilsProvider>
              <span
              style={{color: "#38699E", cursor: "pointer", paddingLeft: "10px", paddingBottom: "4px", fontWeight: 500}}
              onClick={() => this.chooseDate(+moment(this.props.globalStore.currentDate).add(1, 'd'))}>{">"}</span>
            </Date>}
            {(isAOwner || isASupport) && this.renderDepartment()}
            {(isADoctor || isASupervisor) && this.doctorDepartment()}
          </div>
        </DashboardContainer>
        {this.renderQrPopup()}
      </DashboardWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        <DashboardHeader globalStore={this.props.globalStore} t={this.props.t}/>
        {this.renderDashboard()}
      </Wrapper>
    )
  }
}