import React from "react"
import {
  DashboardWrapper,
  DashboardTitle,
  DashboardContainer,
  CompanyTitle,
  Wrapper
} from "../add_patient/add_patient.presets"
import { smartInject } from "../../utils/smartInject"
import { GlobalProps } from "../global/global.props"
import { DashboardHeader } from "../../components/dashboard-header"
import { withTranslation, WithTranslation } from 'react-i18next'
import { Breadcrumbs } from "../../shared/Breadcrumbs"
import Stepper from "../add_patient/Stepper"
import FirstStep from "./FirstStep"
import SecondStep from "./SecondStep"

@(withTranslation() as any)
@smartInject(GlobalProps)
export class AddCaregiver extends React.Component<GlobalProps & WithTranslation> {

  state = {
    current: 1,
    nextStep: 2,
    firstStep: {
      firstName: "",
      lastName: "",
    },
  }

  changeFirstStepValue = (type: string, value: string) => {
    this.setState({
      firstStep: {
        ...this.state.firstStep,
        [type]: value
      }
    })
  }

  goNext = async (obj?: any) => {
    if(obj) {
      Object.entries(obj).forEach(item => this.props.patientStore.changeValue(item[0], (item[1] as any)))
      await this.props.patientStore.createCaregiver();
    }

    if(this.state.nextStep === 3) {
      this.props.globalStore.goTo("branch")
      return
    }

    this.setState({
      current: this.state.nextStep,
      nextStep: this.state.nextStep + 1
    })
  }

  goPrev = () => {
    if(this.state.current === 1) this.props.globalStore.goTo("branch")
  }

  constructNavData = () => {
    const { currentCompany, user, currentDepartment } = this.props.globalStore
    const name = currentDepartment ? currentDepartment.name : ""
    const defaultData = [
      {
        goTo: "branches",
        name: currentCompany ? currentCompany.name : ""
      },{
        goTo: "branch",
        name: name
      },{
        name: "Add New Caregiver"
      }
    ]
    switch (user.role) {
      default: return defaultData
    }
  }

  renderNavigation = () => {
    return(
      <Breadcrumbs
        data={this.constructNavData()}
        globalStore={this.props.globalStore}
      />
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  renderDashboard = () => {
    return(
      <DashboardWrapper>
        <DashboardTitle>
          {this.renderNavigation()}
        </DashboardTitle>
        <DashboardContainer>
          <CompanyTitle>Add New Caregiver</CompanyTitle>
          <Stepper data={[{
            title: "Step One",
            subtitle: "Patient information",
            isComplete: this.state.current >= 2
          },{
            title: "Step Two",
            subtitle: "ID number",
            isComplete: this.state.nextStep === 3
          }]}/>
          {this.line()}
          {this.state.current === 1 && <FirstStep
            goPrev={this.goPrev}
            goNext={this.goNext}
            fBtnTitle={"Back to Branch Page"}
            sBtnTitle={"Next"}
            data={this.state.firstStep}
            changeValue={this.changeFirstStepValue}
          />}
          {this.state.current === 2 && <SecondStep
            id={this.props.patientStore.newPatient.id}
            goNext={this.goNext}
            sBtnTitle={"Done"}
          />}
        </DashboardContainer>
      </DashboardWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        <DashboardHeader globalStore={this.props.globalStore} t={this.props.t}/>
        {this.renderDashboard()}
      </Wrapper>
    )
  }
}