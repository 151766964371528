import React from "react"
import { TableHeaderRow, TableHeaderCol, TableDepartmentContainer, TableBodyRow, TableBodyCol, TableBodyDiv, TableSubBodyDiv, DepartmentsSubHeaderItem, DepartmentsSubHeaderItemText, TableEditBtn, TableFooterRow } from "../branch.presets"
import moment from "moment"
import { observer } from "mobx-react"
import { Form } from "react-bootstrap"
// import { CheckBox } from "./checkbox";
import { Checkbox } from "@material-ui/core"

@observer
export class DailyDashboardTable extends React.Component<{
  t: any
  departmentStore: any
  type: string
  reset: boolean
  goToUserInfo: (e) => void,
  globalCheck: boolean
  changeGlobalCheck: (e) => void
  changeUserData: (e,x) => void
  onChangeTempFlag: (e) => void
  users: number
}>{

  departmentHeaders: any
  backgroundByStatus: any
  colorTextByStatus: any
  constructor(props){
    super(props)
    this.departmentHeaders = [
      // {type: "checkbox", desc: ""},
      {type: "name", desc: this.props.t("dashboard.branch.dailyDashboard.table.firstCol")},
      {type: "status", desc: this.props.t("dashboard.branch.dailyDashboard.table.secondCol")},
      {type: "screening", desc: this.props.t("dashboard.branch.dailyDashboard.table.thirdCol")},
      {type: "supervisor", desc: this.props.t("dashboard.branch.dailyDashboard.table.fourthCol")},
      {type: "dob", desc: this.props.t("dashboard.branch.dailyDashboard.table.fifthCol")},
      {type: "mrn", desc: this.props.t("dashboard.branch.dailyDashboard.table.sixCol")},
    ]
    this.backgroundByStatus = (status) => {
      switch(status){
        case "pending": return "white";
        case "normal": return "rgba(46, 125, 50, 0.1)";
        case "low": return "rgba(245, 124, 0, 0.1)";
        case "risk": return "rgba(191, 54, 12, 0.1)";
        default: return "white"
      }
    }
    this.colorTextByStatus = (status) => {
      switch(status){
        case "pending": return "#38699E";
        case "normal": return "#2E7D32";
        case "risk": return "#BF360C";
        case "low": return "#AD5700";
        default: return "#38699E"
      }
    }
  }

  renderLoadMoreBtn = () => {
    const {t} = this.props
    return(
      false ? <TableFooterRow>
        <TableEditBtn
          variant="outline-secondary"
          onClick={() => this.props.departmentStore.loadMore(this.props.type)}
        >{t("dashboard.branch.dailyDashboard.table.loadMore")}</TableEditBtn>
      </TableFooterRow> : null
    )
  }

  renderHeader = () => {
    const { employees } = this.props.departmentStore
    return(
      employees.length ? <TableHeaderRow>
        {this.departmentHeaders.map(item => {
          if(item.type === "checkbox") {
            return (
              <Form.Group style={{marginLeft: "15px", marginBottom: "0px"}} controlId="formBasicCheckbox">
                <Checkbox
                  style={{padding: 0, margin: 0}}
                  checked={this.props.globalCheck}
                  onChange={(e) => this.globalAddOrRemoveUser(e.target.checked)}
                  size="small"
                  color="default"
                />
              </Form.Group>
            )
          } else {
            return (
              <TableHeaderCol
                key={item.type}
                onClick={() => this.props.departmentStore.sortBy(item.type, "allSortedEmployees")}
              >
                {item.desc}
              </TableHeaderCol>
            )
          }
        })}
      </TableHeaderRow> : null
    )
  }

  globalAddOrRemoveUser = (checked) => {
    const { sortedEmployees } = this.props.departmentStore
    this.props.changeGlobalCheck(checked)
    sortedEmployees.map(user => this.props.changeUserData(user.id, checked))
  }

  addOrRemoveUser = (id, checked) => {
    this.props.onChangeTempFlag(checked)
    this.props.changeUserData(id, checked)
  }

  renderBody = () => {
    const {sortedEmployees} = this.props.departmentStore
    return(
      <TableDepartmentContainer>
          {sortedEmployees && sortedEmployees.map((item, index) => {
          return(
            <TableBodyRow key={index}>
              {/* <Form.Group style={{marginLeft: "15px"}} controlId="formBasicCheckbox">
                <CheckBox reset={this.props.reset} globalCheck={this.props.globalCheck} onChange={e => this.addOrRemoveUser(item.id, e)}/>
              </Form.Group> */}
              <TableBodyCol onClick={() => this.props.goToUserInfo({
                id: item.id,
                screening: item.screening,
                status: item.status
              })}>
                <TableBodyDiv>{item.name}</TableBodyDiv>
                <TableSubBodyDiv>ID {item.id}</TableSubBodyDiv>
              </TableBodyCol>
              <TableBodyCol>
                <DepartmentsSubHeaderItem
                  style={{width: "65%"}}
                  background={this.backgroundByStatus(item.status)}
                  border={"0.5px solid rgba(0,0,0,0.1)"}
                >
                  <DepartmentsSubHeaderItemText color={this.colorTextByStatus(item.status)}
                    style={{paddingRight: "10px"}}
                  >
                    {
                      !item.screening && item.status === "risk" 
                      ? "Not measured"
                      : item.status.charAt(0).toUpperCase() + item.status.slice(1)
                    }
                  </DepartmentsSubHeaderItemText>
                </DepartmentsSubHeaderItem>
              </TableBodyCol>
              <TableBodyCol>{item.screening ? moment.utc(item.screening).local().format("YYYY-MM-DD HH:mm") : "-"}</TableBodyCol>
              <TableBodyCol>{item.supervisor ? item.supervisor : "-"}</TableBodyCol>
              <TableBodyCol>{item.dob ? item.dob : "-"}</TableBodyCol>
              <TableBodyCol>{item.mrn ? item.mrn : "-"}</TableBodyCol>
            </TableBodyRow>
          )}
        )}
        </TableDepartmentContainer>
    )
  }

  render(){
    return(
      <>
      {this.renderHeader()}
      {this.renderBody()}
      {this.renderLoadMoreBtn()}
      </>
    )
  }
}