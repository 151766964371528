import { types, getEnv, applySnapshot } from "mobx-state-tree"

import { NavigationStoreModel } from "../navigation/navigation.store"
import { Environment } from "./environment"
import { AuthStoreModel } from "../modules/auth/auth.store"
import { CompaniesStoreModel } from "../modules/companies/companies.store"
import { load, save } from "../lib/storage"
import { DepartmentsStoreModel } from "../modules/departments/departments.store"
import { DepartmentStoreModel } from "../modules/department/department.store"
import { GlobalStoreModel } from "../modules/global/global.store"
import { UserStoreModel } from "../modules/user/user.store"
import { MedicalUserStoreModel } from "../modules/patient/user.store"
import { BranchesStoreModel } from "../modules/branches/branches.store"
import { AddPatientStoreModel } from "../modules/add_patient/add_patient.store"
import { BranchStoreModel } from "../modules/branch/branch.store"

export const RootStoreModel = types.model("RootStore").props({
  patientStore: types.optional(AddPatientStoreModel, {}),
  globalStore: types.optional(GlobalStoreModel, {}),
  authStore: types.optional(AuthStoreModel, {}),
  companiesStore: types.optional(CompaniesStoreModel, {}),
  navigationStore: types.optional(NavigationStoreModel, {}),
  branchesStore: types.optional(BranchesStoreModel, {}),
  branchStore: types.optional(BranchStoreModel, {}),
  departmentsStore: types.optional(DepartmentsStoreModel, {}),
  departmentStore: types.optional(DepartmentStoreModel, {}),
  medicalUserStore: types.optional(MedicalUserStoreModel, {}),
  userStore: types.optional(UserStoreModel, {})
})
.views(self => ({
  get navigation() {
    return (getEnv(self) as Environment).navigation
  }
}))
.actions((self) => ({
  async refreshToken() {
    const env: Environment = getEnv(self)
    let token: any = await load("token")
    if(!token) return
    if(token.access) {
      const newToken: any = await env.api.auth.refreshToken(token.refresh)
      if(newToken && newToken.data) {
        await save("token", {
          access: newToken.data.access,
          refresh: newToken.data.refresh
        })
      } else {
        self.globalStore.logout()
      }
    }
  }
}))
.actions((self) => ({
  afterCreate() {
    self.refreshToken()
    setInterval(() => self.refreshToken(), 30000)
  },
  reset() {
    applySnapshot(self, {})
  }
}))

//@ts-ignore
export type RootStore = typeof RootStoreModel.Type
export type RootStoreSnapshot = typeof RootStoreModel.SnapshotType
