import React from "react"
import ChartComponent from "./chart"
import { Row, Col } from "react-bootstrap"

export class StatisticChartWrapper extends React.Component<{
  currentChart: string,
  setNewChart: any,
  date: any,
  bp: any,
  hr: any,
  measurements: any,
  saturations: any,
  typeDate: any
  t: any
}>{

  renderMenu = () => {
    const charts = [
      "Temperature",
      "Heart Rate",
      "Blood Pressure",
      "SpO2",
      // "Blood Glucose"
    ]
    const getBorderRadius = (index) => {
      switch(index){
        case 0: return "5px 0 0 0";
        case 4: return "0 5px 0 0";
        default: return "0"
      }
    }
    return(
      <Row style={{marginLeft: "0px", marginRight: "0px"}}>
        {charts.map((item, index) => 
          <Col style={{
            border: "1px solid #E0E0E0",
            borderRadius: getBorderRadius(index),
            background: this.props.currentChart === item ? "#F5F5F5" : "white",
            color: this.props.currentChart === item ? "#38699E" : "#757575",
            textAlign: "center",
            padding: "10px",
            cursor: "pointer"
          }}
          key={item}
          onClick={() => this.props.setNewChart(item)}
          >{item}</Col>
        )}
      </Row>
    )
  }

  render(){
    return(
      <>
      {this.renderMenu()}
      <div style={{border: "1px solid #E0E0E0", borderTop: 0, padding: "30px", borderRadius: "0 0 5px 5px"}}>
        <ChartComponent
          date={this.props.date}
          typeChart={this.props.currentChart}
          saturations={this.props.saturations}
          bp={this.props.bp}
          hr={this.props.hr}
          measurements={this.props.measurements}
          typeDate={this.props.typeDate}
          t={this.props.t}
        />
      </div>
      </>
    )
  }
}