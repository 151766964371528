export enum Offsets {
  /**
   * @extraExtraLarge 30px
   */
  xxl = "30px",

  /**
   * @extraLarge 25px
   */
  xl = "25px",

  /**
   * @large 20px
   */
  lg = "20px",

  /**
   * @medium 15px
   */
  md = "15px",

  /**
   * @small 10px
   */
  sm = "10px",

  /**
   * @extraSmall 5px
   */
  xs = "5px"
}
