import { RouteComponentProps } from "react-router"

export interface NavigationRoute {
  path: string
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
  redirect?: string
  is_redirect_absolute?: boolean
}

export type NavigationRoutes = Array<NavigationRoute>

export enum NavigationAction {
  Back = "POP",
  Forward = "PUSH",
  Change = "REPLACE"
}

export type NavigationUnsubscribeFn = () => void

export type NavigationSubscriptionHandlerFn = (location: any) => void
export interface NavigationSubscription {
  remove(): void
}

export type NavigationSubscribers = {
  [key: string]: any
}