import { Colors } from "./colors"
import { Offsets } from "./offsets"
import { typography } from "./typography"
import { ScreensWidth } from "./screens"
import { ThemeVariables } from "./variables"

export const theme = {
  offsets: Offsets,
  colors: Colors,
  typography,
  screens: ScreensWidth,
  variables: ThemeVariables
}

export type DGTheme = typeof theme
