import React from "react"
import { DepartmentSubHeaderWrapper, DepartmentSubHeaderSchedule, DepartmentSubHeaderContainer, DepartmentsSubHeaderItem, DepartmentsSubHeaderItemText, DropdownBtn, DepartmentPopupWrapper, DepartmentPopup, DepartmentIconWrapper, FormWrapper, DepartmentPopupTitle, Control, DepartmentCancelBtn, DepartmentAddBtn, TableEditBtn, TableFooterRow } from "../branch.presets"
import { Icon } from "../../../icons/icon"
import { observer } from "mobx-react"
import { SpecificHeader } from "../../../components/specific-header"
import { EmplyeesTable } from "../components/employeesTable"
import { Loading } from "../../../components/spinner"
import { Form, Dropdown, Button } from "react-bootstrap"
import { AddEmployeePopup } from "../components/addEmployeePopup"
import { GenderBtn } from '../branch.presets';

@observer
export class EmployeesPage extends React.Component<{
  t: any
  hideLodaMore: any,
  departmentStore: any
  globalStore: any
  tab: string
  isLoading: boolean
  loadMore: any
  goToUserInfo: (e) => void
  search: (e, v, x) => void
  addEmployee: (e) => void
  changeValue: (e,v) => void
  changePhone: (e) => void
}>{

  userData: any
  constructor(props){
    super(props)
    this.userData = []
  }

  state = {
    chosenItem: 1,
    type: "all",
    globalCheck: false,
    tempFlag: false,
    newDepartmentId: "",
    newDepartmentName: "",
    showMovePopup: false,
    showDeletePopup: false,
    showAddEmployeePopup: false,
    showAddPatientOrCaregiverPopup: false,
    userType: 'Patient',
    reset: false
  }

  onChangeTempFlag = (trigger) => this.setState({tempFlag: trigger})

  onChangeUserDataByType = (userId: string, trigger) => {
    trigger
      ? this.userData.push(userId as never)
      : this.userData = this.userData.filter(item => item !== userId)
  }

  move = async () => {
    await this.props.departmentStore.moveUserToDepartment(this.state.newDepartmentId, this.userData)
    this.userData = []
    this.setState({showMovePopup: false, reset: true}, () => {
      this.setState({reset: false})
    })
  }

  delete = async () => {
    await this.props.departmentStore.removeUserFromDepartment(this.userData)
    this.userData = []
    this.setState({showDeletePopup: false, reset: true}, () => {
      this.setState({reset: false})
    })
  }

  renderAddEmployeePopup = () => {
    return(
    this.state.showAddEmployeePopup ?
    <AddEmployeePopup
      t={this.props.t}
      newEmployee={this.props.departmentStore.newEmployee}
      addEmployee={async (e) => {
        await this.props.addEmployee(e)
        this.setState({showAddEmployeePopup: false})
      }}
      changeValue={this.props.changeValue}
      changePhone={this.props.changePhone}
      closePopup={() => {
        this.props.departmentStore.resetNewEmployee()
        this.setState({showAddEmployeePopup: false})
      }}
      setNewEmployeeData={this.props.departmentStore.setNewEmployeeData}
    /> : null
    )
  }

  renderAddPatientOrCaregiverPopup = () => {
    const {t} = this.props
    return(
      this.state.showAddPatientOrCaregiverPopup ? (
        <DepartmentPopupWrapper>
          <DepartmentPopup>
            <DepartmentIconWrapper onClick={() => {
              this.setState({showAddPatientOrCaregiverPopup: false})
            }}>
              <Icon icon="close"/>
            </DepartmentIconWrapper>
            <FormWrapper>
            <DepartmentPopupTitle>
              {t("dashboard.branch.dailyDashboard.popup.chooseUserType.title")} 
            </DepartmentPopupTitle>
            <div style={{display: "flex", flexDirection: "row", paddingBottom: '25px', justifyContent: 'center'}}>
              <GenderBtn
                showgender={this.state.userType === "Patient" ? "true" : "false"}
                onClick={() => this.setState({ userType: 'Patient'})}
              >
                Patient
              </GenderBtn>
              <GenderBtn
                showgender={this.state.userType === "Caregiver" ? "true" : "false"}
                onClick={() => this.setState({ userType: 'Caregiver'})}
                noMargin
              >
                Caregiver
              </GenderBtn>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="primary"
                onClick={() =>
                  this.state.userType === 'Patient' ? 
                  this.props.globalStore.goTo("add_patient") : this.props.globalStore.goTo("add_caregiver") 
                }
              >
                {t("dashboard.branch.dailyDashboard.popup.chooseUserType.btn")}
              </Button>
            </div>
            </FormWrapper>
          </DepartmentPopup>
        </DepartmentPopupWrapper>
      ) : null
    )
  }

  renderMovePopup = () => {
    const {currentDepartment} = this.props.globalStore
    const {moveList} = this.props.departmentStore
    //@ts-ignore
    const CustomToggle: any = React.forwardRef(({ onClick, children }, ref) => (
      <DropdownBtn
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        id="dropdown-basic"
      >
        {children}
      </DropdownBtn>
    ));
    const {t} =this.props
    return this.state.showMovePopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showMovePopup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
            {t("dashboard.branch.dailyDashboard.popup.move.title")}
            </DepartmentPopupTitle>
            <div style={{
              fontSize: "14px",
              color: "#212121",
              textAlign: "center",
              paddingBottom: "25px"
            }}>{t("dashboard.branch.dailyDashboard.popup.move.subTitle")}</div>
            <Form.Group controlId="formGroupScheduleFrom">
              <Form.Label style={{color: "#212121"}}>{t("dashboard.branch.dailyDashboard.popup.from")}</Form.Label>
              <Control
                showerror={"false"}
                type="text"
                style={{color: "#616161"}}
                placeholder={currentDepartment ? currentDepartment.name : ""}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="formGroupScheduleTo">
              <Form.Label style={{color: "#212121"}}>{t("dashboard.branch.dailyDashboard.popup.to")}</Form.Label>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  {this.state.newDepartmentName || "Choose a department"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {moveList.map((item: any) => <Dropdown.Item
                  onClick={() => this.setState({newDepartmentId: item.id, newDepartmentName: item.name})}
                  >{item.name}</Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentCancelBtn
              variant="outline-secondary"
              onClick={() => {
                this.setState({showMovePopup: false})
              }}
            >{t("dashboard.cancel")}</DepartmentCancelBtn>
            <DepartmentAddBtn
              variant="primary"
              onClick={() => this.move()}
              disabled={!(this.state.newDepartmentId.length)}
            >{t("dashboard.branch.dailyDashboard.popup.move.btn")}</DepartmentAddBtn>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  renderDeletePopup = () => {
    const {t} = this.props
    return this.state.showDeletePopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.setState({showDeletePopup: false})
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
            {t("dashboard.branch.dailyDashboard.popup.delete.title1")} {this.props.globalStore.currentDepartment.name} {t("dashboard.branch.dailyDashboard.popup.delete.title2")}?
            </DepartmentPopupTitle>
            <div style={{
              fontSize: "14px",
              color: "#212121",
              textAlign: "center",
              paddingBottom: "25px"
            }}>{t("dashboard.branch.dailyDashboard.popup.delete.subTitle")}</div>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentCancelBtn
              variant="outline-secondary"
              onClick={() => {
                this.setState({showDeletePopup: false})
              }}
            >{t("dashboard.cancel")}</DepartmentCancelBtn>
            <Button
              variant="outline-danger"
              onClick={() => this.delete()}
            >{t("dashboard.branch.dailyDashboard.popup.delete.btn")}</Button>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }

  renderEmployeesSubHeader = () => {
    const {allEmployees, filterByAll} = this.props.departmentStore
    const {t} = this.props
    const items = [
      {
        id: 1,
        name: `${t("dashboard.branch.patients.statuses.all")} • ${allEmployees.length}`,
        background: "rgba(56, 105, 158, 0.1)",
        color: "#38699E",
        border: "",
        icon: "list",
        onClick: () => {
          this.setState({chosenItem: 1, type: "all"})
          filterByAll("all")
        }
      },{
        id: 2,
        name: `${t("dashboard.branch.patients.statuses.created")} • ${allEmployees.filter(item => item.status === "created").length}`,
        background: "rgba(191, 54, 12, 0.1)",
        color: "#BF360C",
        border: "",
        icon: "risk",
        onClick: () => {
          this.setState({chosenItem: 2, type: "created"})
          filterByAll("created")
        }
      },{
        id: 3,
        name: `${t("dashboard.branch.patients.statuses.onboard")} • ${allEmployees.filter(item => item.status === "onboard").length}`,
        background: "rgba(46, 125, 50, 0.1)",
        color: "#2E7D32",
        border: "",
        icon: "normal",
        onClick: () => {
          this.setState({chosenItem: 3, type: "onboard"})
          filterByAll("onboard")
        }
      },{
        id: 4,
        name: `${t("dashboard.branch.patients.statuses.registered")} • ${allEmployees.filter(item => item.status === "registered").length}`,
        background: "white",
        color: "#38699E",
        border: "0.5px solid rgba(0,0,0,0.1)",
        icon: "pending",
        onClick: () => {
          this.setState({chosenItem: 4, type: "registered"})
          filterByAll("registered")
        }
      }
    ]
    const {schedule} = this.props.departmentStore
    return(
      <DepartmentSubHeaderWrapper>
        <DepartmentSubHeaderSchedule>
        {schedule.from ? <>
        <div style={{justifyContent: "flex-start", display: "flex", padding: "10px"}}>
            <span style={{fontSize: "14px", color: "black", fontWeight: 500}}>
            {t("dashboard.branch.dailyDashboard.current")}: {schedule.from} - {schedule.to}
            </span>
          </div>
          <div>
          <Icon icon={"ring"}/>
          </div>
          </> : null}
        </DepartmentSubHeaderSchedule>
        <DepartmentSubHeaderContainer>
          {items.map(item => (
            <DepartmentsSubHeaderItem
              key={item.id}
              background={item.background}
              border={item.id === this.state.chosenItem ? "1.5px solid #38699E" : item.border}
              onClick={() => item.onClick()}
            >
              <Icon icon={item.icon as any}/>
              <DepartmentsSubHeaderItemText color={item.color}>{item.name}</DepartmentsSubHeaderItemText>
            </DepartmentsSubHeaderItem>
          ))}
        </DepartmentSubHeaderContainer>
      </DepartmentSubHeaderWrapper>
    )
  }

  renderEmployeesHeader = () => {
    const { allEmployees } = this.props.departmentStore
    const {t} =this.props
    return(
      <SpecificHeader
        t={t}
        title={allEmployees.length ? `${allEmployees.length} ${t("dashboard.branch.patients.employeeText")}` : t("dashboard.branch.patients.noEmployeesText")}
        showSearch={!!allEmployees.length}
        onChangeSearch={(e) => this.props.search(e, "allSortedEmployees", this.state.type)}
        // onClick={() => }
        onClick={() => this.setState({ showAddPatientOrCaregiverPopup: true })}
        searchPlaceholder={t("dashboard.branch.patients.search")}
        btnText={t("dashboard.branch.patients.addEmployeeBtnText")}
        showBtn={!this.props.globalStore.isASupervisor || !this.props.globalStore.isASupport}
        firstBtn={{title: `${t("dashboard.branch.dailyDashboard.fBtn")} • ${this.userData.length}`, onClick: () => this.setState({showMovePopup: true})}}
        secondBtn={{title: `${t("dashboard.branch.dailyDashboard.sBtn")} • ${this.userData.length}`, onClick: () => this.setState({showDeletePopup: true})}}
        showBtns={this.userData.length}
      />
    )
  }

  renderEmployeesTable = () => {
    return <EmplyeesTable
      showCheckBox={this.props.globalStore.isAOwner}
      t={this.props.t}
      reset={this.state.reset}
      onChangeTempFlag={this.onChangeTempFlag}
      changeUserData={(user,trigger) => this.onChangeUserDataByType(user,trigger)}
      globalCheck={this.state.globalCheck}
      changeGlobalCheck={(e) => this.setState({globalCheck: e})}
      departmentStore={this.props.departmentStore}
      type={this.state.type}
      users={this.userData.length}
      goToUserInfo={this.props.goToUserInfo}
    />
  }

  renderLoadMore = () => {
    return(
      <TableFooterRow>
        <TableEditBtn
          variant="outline-secondary"
          onClick={() => {
            this.props.loadMore()
          }}
        >{this.props.t("dashboard.loadMoreBtn")}</TableEditBtn>
      </TableFooterRow>
    )
  }

  render(){
    return(
      <>
      {this.renderEmployeesSubHeader()}
      {this.renderEmployeesHeader()}
      {this.props.isLoading ? <Loading/> : this.renderEmployeesTable()}
      {!this.props.hideLodaMore && this.renderLoadMore()}
      {this.renderMovePopup()}
      {this.renderDeletePopup()}
      {this.renderAddEmployeePopup()}
      {this.renderAddPatientOrCaregiverPopup()}
      </>
    )
  }
}