import React from "react"
import {
  FormWrapper,
  StepTitle,
  DepartmentsCancelBtn,
  DepartmentsAddBtn,
  Control} from "../add_patient.presets"
import { Form, DropdownButton, Dropdown } from "react-bootstrap"
import { ErrorMessageWrapper, ErrorMessageText } from "../../branch/branch.presets"

interface ISecondStep {
  street: string;
  city: string;
  state: string;
  zipcode: string;
  plan: string;
  plan_id: string;
}

class SecondStep extends React.Component<{
  connectionPlanList: any,
  goNext: (obj) => void,
  goPrev: () => void,
  fBtnTitle: string,
  sBtnTitle: string,
  data: ISecondStep,
  changeValue: (field: string, value: string) => void,
}>{

  state = {
    errorPlan: "",
    errorStreet: "",
    errorCity: "",
    errorState: "",
    errorZipCode: ""
  }

  goNext = async () => {
    const state = {
      errorPlan: "",
      // errorStreet: "",
      // errorCity: "",
      // errorState: "",
      // errorZipCode: ""
    }
    const defaultError = "This field is required."
    // if (this.state.street.length === 0) {
    //   state.errorStreet = defaultError
    // }
    // if (this.state.state.length === 0) {
    //   state.errorState = defaultError
    // }
    if (this.props.data.plan === "Choose your connection plan") {
      state.errorPlan = defaultError
    }
    // if (this.state.city.length === 0) {
    //   state.errorCity = defaultError
    // }
    // if (this.state.zipcode.length === 0) {
    //   state.errorZipCode = defaultError
    // }
    if (Object.values(state).find(item => item.length > 0)) {
      this.setState(state)
      return
    } else {
      this.props.goNext({
        shipping: {
          street: this.props.data.street,
          city: this.props.data.city,
          state:  this.props.data.state,
          zip_code:  this.props.data.zipcode
        },
        connection_plan_id: this.props.data.plan_id,
        connection_plan : this.props.data.plan
      })
    }
  }

  renderErrorMessage = (type: string) => {
    return(
      <ErrorMessageWrapper>
        <ErrorMessageText>
          {this.state[type]}
        </ErrorMessageText>
      </ErrorMessageWrapper>
    )
  }

  renderStreet = () => {
    return(
      <Form.Group controlId="formStreet">
        <Form.Label style={{color: "#212121"}}>
          Street
        </Form.Label>
        <Control
          showerror={this.state.errorStreet.length ? "true" : "false"}
          onFocus={() => this.setState({errorStreet: ""})}
          type="text"
          placeholder={"Your Street"}
          value={this.props.data.street}
          onChange={(e) => this.props.changeValue("street", e.target.value)}
        />
        {this.renderErrorMessage("errorStreet")}
      </Form.Group>
    )
  }

  renderCity = () => {
    return(
      <Form.Group controlId="formCity">
        <Form.Label style={{color: "#212121"}}>
        City
        </Form.Label>
        <Control
          showerror={this.state.errorCity.length ? "true" : "false"}
          onFocus={() => this.setState({errorCity: ""})}
          type="text"
          placeholder={"Your City"}
          value={this.props.data.city}
          onChange={(e) => this.props.changeValue("city", e.target.value)}
        />
        {this.renderErrorMessage("errorCity")}
      </Form.Group>
    )
  }

  renderState = () => {
    return(
      <Form.Group controlId="formState">
        <Form.Label style={{color: "#212121"}}>
        State
        </Form.Label>
        <Control
          showerror={this.state.errorState.length ? "true" : "false"}
          onFocus={() => this.setState({errorState: ""})}
          type="text"
          placeholder={"Your State"}
          value={this.props.data.state}
          onChange={(e) => this.props.changeValue("state", e.target.value)}
        />
        {this.renderErrorMessage("errorState")}
      </Form.Group>
    )
  }

  renderZipCode = () => {
    return(
      <Form.Group controlId="formZipCode">
        <Form.Label style={{color: "#212121"}}>
        Zip code
        </Form.Label>
        <Control
          showerror={this.state.errorZipCode.length ? "true" : "false"}
          onFocus={() => this.setState({errorZipCode: ""})}
          type="number"
          placeholder={"Your Zip code"}
          value={this.props.data.zipcode}
          onChange={(e) => this.props.changeValue("zipcode", e.target.value)}
        />
        {this.renderErrorMessage("errorZipCode")}
      </Form.Group>
    )
  }

  renderPlan = () => {
    return(
      <Form.Group controlId="formGroupRole">
        <Form.Label style={{color: "#212121"}}>
            <span style={{color: "red"}}>*</span> Connection plan
        </Form.Label>
        <DropdownButton id="dropdown-basic-button" title={this.props.data.plan}>
          {this.props.connectionPlanList && this.props.connectionPlanList.map((item, index) => <Dropdown.Item
            key={index}
            onClick={() => this.setState({errorPlan: ""}, () => {
              this.props.changeValue("plan_id", item.id)
            })
          }>{item.description}</Dropdown.Item>)}
        </DropdownButton>
        {this.renderErrorMessage("errorPlan")}
      </Form.Group>
    )
  }

  renderSecondStep = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Patient information
          </StepTitle>
          {this.renderPlan()}
          <div style={{marginTop: "50px", marginBottom: "30px"}}>
            <p style={{fontWeight: 500, fontSize: "20px", lineHeight: "24px", textAlign: "center", color: "#757575"}}>
              Shipping address (optional)
            </p>
            <p style={{fontSize: "14px", lineHeight: "20px", textAlign: "center", color: "#212121"}}>
              We will ship a device directly to your home
            </p>
          </div>
          {this.renderStreet()}
          {this.renderCity()}
          {this.renderState()}
          {this.renderZipCode()}
        </FormWrapper>
      </div>
    )
  }

  renderBtns = () => {
    return(
      <div style={{width: "400px", margin: "20px auto", display: "flex", justifyContent: "space-between"}}>
        <DepartmentsCancelBtn
          variant="outline-secondary"
          onClick={() => this.props.goPrev()}
        >
          {this.props.fBtnTitle}
        </DepartmentsCancelBtn>
        <DepartmentsAddBtn
          variant="primary"
          onClick={() => this.goNext()}
          disabled={false}
        >
          {this.props.sBtnTitle}
        </DepartmentsAddBtn>
      </div>
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  render(){
    return <>
      {this.renderSecondStep()}
      {this.line()}
      {this.renderBtns()}
    </>
  }
}

export default SecondStep