import React from "react"
import { 
  Wrapper,
  DashboardWrapper,
  DashboardTitle,
  DashboardContainer,
  CompaniesIconWrapper,
  CompaniesPopupWrapper,
  CompaniesPopup,
  Control,
  FormWrapper,
  TextArea, 
  CompaniesPopupTitle,
  CompaniesCancelBtn,
  CompaniesAddBtn,
  TableHeaderRow,
  TableHeaderCol,
  TableBodyCol,
  TableEditBtn,
  TableBodyRow,
  TableCompaniesContainer,
  TableBtns,
  TableFooterRow,
  CompanyTypeBtn} from "./companies.presets"
import { Icon } from "../../icons/icon"
import { smartInject } from "../../utils/smartInject"
import { Form } from "react-bootstrap"
import moment from "moment"
import { GlobalProps } from "../global/global.props"
import { DashboardHeader } from "../../components/dashboard-header"
import { SpecificHeader } from "../../components/specific-header"
import { withTranslation, WithTranslation } from 'react-i18next';
import { Loading } from "../../components/spinner"
import { ErrorMessageWrapper, ErrorMessageText } from "../department/department.presets"
import { validateField, requiredRule } from "../../services/validation"

@(withTranslation() as any)
@smartInject(GlobalProps)
export class Companies extends React.Component<GlobalProps & WithTranslation> {

  companiesEnd: any
  state = {
    showAddCompanyPopup: false,
    searchText: "",
    companyTypeError: "",
    isLoading: false
  }

  async componentDidMount() {
    this.setState({isLoading: true})
    await this.props.companiesStore.getCompanies()
    this.setState({isLoading: false})
  }

  changeValue = (e, type) => {
    this.props.companiesStore.setNewCompanyData(e.target.value, type)
  }

  sendData = async () => {
    const {addCompany, newCompany} = this.props.companiesStore
    const validationCompanyType: any = validateField(requiredRule, newCompany.company_type)
    if (validationCompanyType.isValid) {
      await addCompany()
      this.setState({showAddCompanyPopup: false})
    } else {
      this.setState({
        comapnyTypeError: validationCompanyType.error ? validationCompanyType.error : ""
      })
    }
  }

  search = (e) => {
    this.props.companiesStore.search(e.target.value)
    this.setState({searchText: e.target.value})
  }

  renderCompanyHeader = () => {
    const { companies } = this.props.companiesStore
    const { t } = this.props
    return(
      <SpecificHeader
        t={t}
        showBtn
        title={companies.length
          ? `${companies.length} ${t("dashboard.companies.companyText")}`
          : t("dashboard.companies.noCompanyText")
        }
        onChangeSearch={this.search}
        showSearch={!!companies.length}
        onClick={() => this.setState({showAddCompanyPopup: true})}
        searchPlaceholder={t("dashboard.companies.search")}
        btnText={t("dashboard.companies.addCompanyBtnText")}
      />
    )
  }

  renderErrorMessage = (type: string) => {
    return(
      <ErrorMessageWrapper>
        <ErrorMessageText>
          {this.state[type]}
        </ErrorMessageText>
      </ErrorMessageWrapper>
    )
  }

  renderCompaniesPopup = () => {
    const { name, description, company_type } = this.props.companiesStore.newCompany
    const { setNewCompanyData } = this.props.companiesStore
    const { t } = this.props
    return this.state.showAddCompanyPopup
    ? <CompaniesPopupWrapper>
        <CompaniesPopup>
          <CompaniesIconWrapper onClick={() => this.setState({showAddCompanyPopup: false})}>
            <Icon icon="close"/>
          </CompaniesIconWrapper>
          <FormWrapper>
            <CompaniesPopupTitle>
            {t("dashboard.companies.popup.title")}
            </CompaniesPopupTitle>
            <Form.Group controlId="formGroupEmployeeBiological">
              <Form.Label style={{color: "#212121"}}>{t("dashboard.companies.popup.swiperTitle")}</Form.Label>
              <div style={{display: "flex", flexDirection: "row"}}>
                <CompanyTypeBtn
                  showgender={company_type === "medical" ? "true" : "false"}
                  onClick={() => {
                    this.setState({companyTypeError: ""})
                    setNewCompanyData("medical", "company_type")
                  }}
                >{t("dashboard.companies.popup.swiperFirst")}</CompanyTypeBtn>
                <CompanyTypeBtn
                  showgender={company_type === "corporate" ? "true" : "false"}
                  onClick={() => {
                    this.setState({companyTypeError: ""})
                    setNewCompanyData("corporate", "company_type")
                  }}
                >{t("dashboard.companies.popup.swiperSecond")}</CompanyTypeBtn>
              </div>
              {this.renderErrorMessage("companyTypeError")}
            </Form.Group>
            <Form.Group controlId="formGroupCompanyName">
              <Form.Label style={{color: "#212121"}}>
                {t("dashboard.companies.popup.nameInputTitle")}
              </Form.Label>
              <Control
                showerror={"false"}
                onFocus={() => this.setState({emailError: ""})}
                type="email"
                placeholder={t("dashboard.companies.popup.namePlaceholder")}
                onChange={(e) => this.changeValue(e, "name")}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label style={{color: "#212121"}}>
                {t("dashboard.companies.popup.descriptionInputTitle")}
              </Form.Label>
              <TextArea
                className="form-control"
                as="textarea"
                rows={5}
                placeholder={t("dashboard.companies.popup.descriptionPlaceholder")}
                onChange={(e) => this.changeValue(e, "description")}
              />
            </Form.Group>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <CompaniesCancelBtn
              variant="outline-secondary"
              onClick={() => this.setState({showAddCompanyPopup: false})}
            >{t("dashboard.cancel")}</CompaniesCancelBtn>
            <CompaniesAddBtn
              variant="primary"
              onClick={this.sendData}
              disabled={!(name.length && description.length && company_type.length)}
            >{t("dashboard.add")}</CompaniesAddBtn>
            </div>
          </FormWrapper>
        </CompaniesPopup>
      </CompaniesPopupWrapper>
    : null
  }

  renderTable = () => {
    const { t } = this.props
    const companyHeaders = [
      {type: "name", desc: t("dashboard.companies.table.firstCol")},
      {type: "company_type", desc: t("dashboard.companies.table.secondCol")},
      {type: "departments", desc: t("dashboard.companies.table.thirdCol")},
      {type: "managers", desc: t("dashboard.companies.table.fourthCol")},
      {type: "created", desc: t("dashboard.companies.table.fifthCol")},
      {type: "btns", desc: ""}
    ]
    const {sortedCompanies, companies} = this.props.companiesStore
    const showMore = sortedCompanies.length !== companies.length && !this.state.searchText
    return(
      <>
      {companies.length ? <TableHeaderRow>
        {companyHeaders.map((item, index) => <TableHeaderCol key={index} onClick={() => this.props.companiesStore.sortBy(item.type)}>{item.desc}</TableHeaderCol>)}
      </TableHeaderRow> : null}
      <TableCompaniesContainer>
        {sortedCompanies.map((item, index) => {
          return(
            <TableBodyRow
              onClick={() => this.props.companiesStore.goToDepartment(item)}
              key={item.id}
              ref={(el) => this.companiesEnd = el}
            >
              <TableBodyCol>{item.name}</TableBodyCol>
              <TableBodyCol>{item.company_type.charAt(0).toUpperCase() + item.company_type.slice(1)}</TableBodyCol>
              <TableBodyCol>{item.departments}</TableBodyCol>
              <TableBodyCol>{item.managers}</TableBodyCol>
              <TableBodyCol>{moment(item.created).format("DD/M/YYYY")}</TableBodyCol>
              <TableBodyCol>
                <TableBtns>
                  <TableEditBtn
                    variant="outline-secondary"
                  >{t("dashboard.edit")}</TableEditBtn>
                  {/* <Button variant="outline-danger">{t("dashboard.delete")}</Button> */}
                </TableBtns>
              </TableBodyCol>
            </TableBodyRow>
          )}
        )}
      </TableCompaniesContainer>
      {showMore ? <TableFooterRow>
        <TableEditBtn
          variant="outline-secondary"
          onClick={() => {
            this.props.companiesStore.loadMore()
            setTimeout(() => this.companiesEnd.scrollIntoView({ behavior: "smooth" }), 500)
          }}
        >{t("dashboard.loadMoreBtn")}</TableEditBtn>
      </TableFooterRow> : null}
      </>
    )
  }

  renderCompanies = () => {
    return(
      <>
        {this.renderCompanyHeader()}
        {this.state.isLoading ? <Loading/> : this.renderTable()}
      </>
    )
  }

  renderPopup = () => {
    return this.renderCompaniesPopup()
  }

  renderDashboard = () => {
    const { t } = this.props
    return(
      <DashboardWrapper>
        <DashboardTitle>{t("dashboard.companies.navTitle")}</DashboardTitle>
        <DashboardContainer>
          {this.renderCompanies()}
        </DashboardContainer>
        {this.renderPopup()}
      </DashboardWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        <DashboardHeader globalStore={this.props.globalStore} t={this.props.t} isRootComponent />
        {this.renderDashboard()}
      </Wrapper>
    )
  }
}