import { styled, theme } from "../../shared/theme"
import { Container, Form, Button, Row, Col } from "react-bootstrap"

export const Wrapper = styled.div`
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.bgColor};
`

export const Header = styled.div`
  height: 65px;
  width: 100%;
  padding: 13px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #38699E;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const HeaderTitleText = styled.div`
  color: white;
  padding-left: 10px;
  padding-right: 10px;
`

export const HeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify};
  align-items: center;
  width: 30%;
`

export const HeaderBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 8px;
  border: ${(props) => props.isBorder ? "1px solid #FFFFFF" : "none"};
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  cursor: pointer;
`

export const DashboardWrapper = styled.div`
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 5%;
  padding-right: 5%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DashboardTitle = styled.span`
  width: 100%;
  padding-bottom: 15px;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #38699E;
`

export const DashboardContainer = styled(Container)`
  min-height: 90%;
  min-width: 100%;
  position: relative;
  border: 0.7px solid rgb(0,0,0,0.2);
  background-color: white;
  border-radius: 4px;
`

export const DepartmentsHeader = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

export const DepartmentsTitle = styled(Col)`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding: 0;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #757575;
`

export const DepartmentsSearch = styled(Col)`
  margin-top: -6px;
  padding: 0;
  position: relative;
`

export const Search = styled(Form.Control)`
  width: 100%;
  padding-left: 30px;
  :focus {
    border-width: 2px;
    border-color: #38699E;
    box-shadow: none;
  }
`

export const IconWrapper = styled.div`
  width: 10%;
  margin-top: -32px;
  margin-left: 10px;
`

export const DepartmentsButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #38699E;
  border: none;
  :focus {
    border-width: 2px;
    background-color: #38699E;
    border-color: #38699E;
    box-shadow: none;
  }
`

export const DepartmentsButtonText = styled.span`
  padding: 3px;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
`

export const DepartmentsPopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DepartmentsPopup = styled.div`
  padding: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  border: 0.5px solid gray;
  border-radius: 4px;
`

export const Control = styled(Form.Control)`
  outline: none;
  border-color: ${props => props.showerror === "true" ? "red" : ""};
  :focus {
    border-width: 2px;
    border-color: #38699E;
    box-shadow: none;
  }
  :disabled {
    background-color: #E0E0E0;
    border: none
  }
`

export const TextArea = styled(Form.Control)`
  :focus {
    border-width: 2px;
    border-color: #38699E;
    box-shadow: none;
  }
`

export const FormWrapper = styled.div`
  width: 400px;
`

export const DepartmentsIconWrapper = styled.div`
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer;
`

export const DepartmentsPopupTitle = styled.p`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #757575;
`

export const DepartmentsCancelBtn = styled(Button)`
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  color: #38699E;
  :hover {
    border: 1px solid lightgray;
    background-color: lightgray;
  }
  :focus {
    box-shadow: none;
  }
`

export const DepartmentsAddBtn = styled(Button)`
  :disabled {
    color: #616161;
    background: #E0E0E0;
    border: none;
  }
`

export const TableHeaderRow = styled(Row)`
  margin-top: 30px;
  border-bottom: 0.7px solid rgb(0,0,0,0.2);
  padding-bottom: 8px;
`

export const TableFooterRow = styled(Row)`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
`

export const TableBodyRow = styled(Row)`
  margin-right: 0;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.7px solid rgb(0,0,0,0.2);
  padding-bottom: 10px;
  padding-top: 10px;
  :hover {
    cursor: ${props => props.showhover === "true" ? "pointer" : ""};
    background-color: ${props => props.showhover === "true" ? "rgb(0,0,0,0.05)" : "white"}
  }
`

export const TableHeaderCol = styled(Col)`
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #757575;
  cursor: pointer;
`

export const TableBodyCol = styled(Col)`
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #212121;
`

export const TableEditBtn = styled(DepartmentsCancelBtn)`
  margin-right: 10px;
`

export const PendingBadge = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 6.5px 10px;
  letter-spacing: 0.25px;
  color: #616161;
  background: #E0E0E0;
  border-radius: 4px;
`

export const ActiveBadge = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 6.5px 10px;
  width: 60px;
  letter-spacing: 0.25px;
  color: #2E7D32;
  background: rgba(46, 125, 50, 0.1);
  border-radius: 4px;
`

export const RoleBadge = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 6.5px 10px;
  letter-spacing: 0.25px;
  color: #2E7D32;
  background: rgba(46, 125, 50, 0.1);
  border-radius: 4px;
`

export const TableDepartmentsContainer = styled.div`
  margin-right: -15px;
  margin-left: -15px;
`

export const TableBtns = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const CompanyTitle = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 36px;
  text-align: center;
  color: #212121;

`

export const StepTitle = styled.p`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #757575;
`