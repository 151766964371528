import _ from "lodash"
import { ErrorParsers } from "./error-parsers"

const errorMessages = require("./error-messages.json")

function _buildErrorMessageFromObject(errData, messageRules) {
  let placeholders = {}
  _.forEach(messageRules.valuesFor, (v, k) => placeholders[k] = v[errData[k]])
  return _.template(messageRules.template)(placeholders)
}

function errorMessagableToErrorMessage(parsedError, errorsMessagable) {
  if (typeof errorsMessagable === "object") {
    return _buildErrorMessageFromObject(parsedError.data, errorsMessagable)
  } else {
    return errorsMessagable
  }
}

export class ErrorPresenter {
  context: string
  service: string

  constructor(context) {
    this.context = context
    this.service = context.split(".")[0]
  }

  getMessageTemplate(err: any) {
    let message = this.getMessage(err)
    let template = _.template(message)
    return template
  }

  getMessage(err: any) {
    let parsedError = ErrorParsers[this.service](err)
    let fallbackError = parsedError.status >= 500 ? _.get(errorMessages[this.service], "base.5xx") : null
    return errorMessagableToErrorMessage(parsedError, _.get(errorMessages, [this.context, parsedError.code].join(".")))
      || parsedError.message
      || fallbackError
  }

}
