import { styled } from "../../shared/theme"

export const Header = styled.div`
  height: 65px;
  width: 100%;
  padding: 13px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #38699E;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const HeaderTitleText = styled.div`
  color: white;
  padding-left: 10px;
  padding-right: 10px;
`

export const HeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify};
  align-items: center;
  width: 30%;
`

export const HeaderBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 8px;
  border: ${(props) => props.isBorder ? "1px solid #FFFFFF" : "none"};
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  cursor: pointer;
  user-select: none;
`