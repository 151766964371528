import React from "react"
import {
  FormWrapper,
  StepTitle,
  DepartmentsCancelBtn,
  DepartmentsAddBtn} from "../add_patient.presets"
import { Form } from "react-bootstrap"
import { ErrorMessageWrapper, ErrorMessageText } from "../../branch/branch.presets"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

class ThirdStep extends React.Component<{
  billingCodes: any,
  goNext: (obj) => void,
  goPrev: () => void,
  fBtnTitle: string,
  sBtnTitle: string,
  data: {
    icd10: any,
    cpt_code: any,
    icd_code: any
  },
  fetchICD10Codes: (value: string) => void,
  changeValue: (field: string, value: string) => void,
}>{

  state = {
    cptError: "",
    icdError: ""
  }

  goNext = async () => {
    const state = {
      cptError: "",
      icdError: ""
    }
    if (Object.values(state).find(item => item.length > 0)) {
      this.setState(state)
      return
    } else {
      this.props.goNext({
        cpt_code: this.props.data.cpt_code && this.props.data.cpt_code.code || undefined,
        icd_code: this.props.data.icd_code && this.props.data.icd_code.title || undefined
      })
    }
  }

  renderErrorMessage = (type: string) => {
    return(
      <ErrorMessageWrapper>
        <ErrorMessageText>
          {this.state[type]}
        </ErrorMessageText>
      </ErrorMessageWrapper>
    )
  }

  renderICD10 = () => {
    const icdCodes = this.props.data.icd10 ? this.props.data.icd10 : []

    return(
      <Form.Group controlId="formICD-10">
        <Form.Label style={{color: "#212121"}}>
          ICD-10 code
        </Form.Label>
        <Autocomplete
          id="combo-box-demo"
          options={icdCodes}
          defaultValue={{ ...this.props.data.icd_code }}
          getOptionLabel={(option: any) => option.title}
          style={{ width: 400 }}
          onInputChange={(event, newInputValue) => {
            const item = icdCodes.find(item => item.title === newInputValue);
            this.setState({icdError: ""}, () => this.props.changeValue("icd_code", item))
          }}
          renderInput={(params) => 
            <TextField 
              {...params} 
              placeholder="Start typing ICD-10 code description..." 
              onChange={(e) => this.props.fetchICD10Codes(e.target.value)} 
              variant="outlined"
            />}
        />
        {this.renderErrorMessage("icdError")}
      </Form.Group>
    )
  }

  renderBillingCode = () => {
    const billing = this.props.billingCodes ? this.props.billingCodes : []

    return(
      <Form.Group controlId="formBillingCode">
        <Form.Label style={{color: "#212121"}}>
          Billing code
        </Form.Label>
        <Autocomplete
          id="combo-box-demo2"
          options={billing}
          defaultValue={{ ...this.props.data.cpt_code }}
          getOptionLabel={(option: any) => option.text}
          style={{ width: 400 }}
          onInputChange={(event, newInputValue) => {
            const item = billing.find(item => item.text === newInputValue);
            this.setState({icdError: ""}, () => this.props.changeValue("cpt_code", item))
          }}
          renderInput={(params) => 
            <TextField 
              {...params} 
              placeholder="Choose CPT code" 
              variant="outlined"
            />}
        />
        {this.renderErrorMessage("cptError")}
      </Form.Group>
    )
  }

  renderThirdStep = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Reports and billing
          </StepTitle>
          {this.renderICD10()}
          {this.renderBillingCode()}
        </FormWrapper>
      </div>
    )
  }

  renderBtns = () => {
    return(
      <div style={{width: "400px", margin: "20px auto", display: "flex", justifyContent: "space-between"}}>
        <DepartmentsCancelBtn
          variant="outline-secondary"
          onClick={() => this.props.goPrev()}
        >
          {this.props.fBtnTitle}
        </DepartmentsCancelBtn>
        <DepartmentsAddBtn
          variant="primary"
          onClick={() => this.goNext()}
          disabled={false}
        >
          {this.props.sBtnTitle}
        </DepartmentsAddBtn>
      </div>
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  render(){
    return <>
      {this.renderThirdStep()}
      {this.line()}
      {this.renderBtns()}
    </>
  }
}

export default ThirdStep