import React from "react"
import { Form } from "react-bootstrap"
import { TableHeaderRow, TableHeaderCol, TableDepartmentContainer, TableBodyRow, TableBodyCol, TableBodyDiv, TableSubBodyDiv, DepartmentsSubHeaderItem, DepartmentsSubHeaderItemText, TableEditBtn, TableFooterRow } from "../department.presets";
import { CheckBox } from "./checkbox";
import moment from "moment";
import { Checkbox } from "@material-ui/core";
import { observer } from "mobx-react";

@observer
export class EmplyeesTable extends React.Component<{
  t: any
  showCheckBox: boolean
  departmentStore: any
  type: string
  reset: boolean
  goToUserInfo: (e) => void
  globalCheck: boolean
  changeGlobalCheck: (e) => void
  changeUserData: (e,x) => void
  onChangeTempFlag: (e) => void
  users: number
}>{

  departmentHeaders: any
  backgroundByStatus: any
  colorTextByStatus: any
  constructor(props){
    super(props)
    this.departmentHeaders = [
      props.showCheckBox && {type: "checkbox", desc: ""},
      {type: "name", desc: this.props.t("dashboard.department.dailyDashboard.table.firstCol")},
      {type: "status", desc: this.props.t("dashboard.department.dailyDashboard.table.secondCol")},
      {type: "screening", desc: this.props.t("dashboard.department.dailyDashboard.table.thirdCol")},
      {type: "created", desc: this.props.t("dashboard.department.dailyDashboard.table.fourthCol")}
    ]
    this.backgroundByStatus = (status) => {
      switch(status){
        case "registered": return "white";
        case "onboard": return "rgba(46, 125, 50, 0.1)";
        case "created": return "rgba(191, 54, 12, 0.1)";
        default: return "white"
      }
    }
    this.colorTextByStatus = (status) => {
      switch(status){
        case "registered": return "#38699E";
        case "onboard": return "#2E7D32";
        case "created": return "#BF360C";
        default: return "#38699E"
      }
    }
  }

  renderLoadMoreBtn = () => {
    const{t}=this.props
    return(
      false ? <TableFooterRow>
          <TableEditBtn
            variant="outline-secondary"
            onClick={() => this.props.departmentStore.loadMore(this.props.type)}
          >{t("dashboard.department.dailyDashboard.table.loadMore")}</TableEditBtn>
        </TableFooterRow> : null
    )
  }

  globalAddOrRemoveUser = (checked) => {
    const { allSortedEmployees } = this.props.departmentStore
    this.props.changeGlobalCheck(checked)
    allSortedEmployees.map(user => this.props.changeUserData(user.id, checked))
  }

  addOrRemoveUser = (id, checked) => {
    this.props.onChangeTempFlag(checked)
    this.props.changeUserData(id, checked)
  }

  renderHeader = () => {
    const { allEmployees } = this.props.departmentStore
    return(
      allEmployees.length ? <TableHeaderRow>
        {this.departmentHeaders.filter(Boolean).map(item => {
          if(item.type === "checkbox") {
            return (
              <Form.Group style={{marginLeft: "15px", marginBottom: "0px"}} controlId="formBasicCheckbox">
                <Checkbox
                  style={{padding: 0, margin: 0}}
                  checked={this.props.globalCheck}
                  onChange={(e) => this.globalAddOrRemoveUser(e.target.checked)}
                  size="small"
                  color="default"
                />
              </Form.Group>
            )
          } else {
            return (
              <TableHeaderCol
                key={item.type}
                onClick={() => this.props.departmentStore.sortBy(item.type, "allSortedEmployees")}
              >
                {item.desc}
              </TableHeaderCol>
            )
          }
        })}
      </TableHeaderRow> : null
    )
  }

  render(){
      const {allSortedEmployees} = this.props.departmentStore
      return(
        <>
        {this.renderHeader()}
        <TableDepartmentContainer>
          {allSortedEmployees && allSortedEmployees.map((item, index) => {
            return(
              <TableBodyRow className={item.id} key={index}>
                {this.props.showCheckBox && <Form.Group style={{marginLeft: "15px"}} controlId="formBasicCheckbox">
                  <CheckBox reset={this.props.reset} globalCheck={this.props.globalCheck} onChange={e => this.addOrRemoveUser(item.id, e)}/>
                </Form.Group>}
                <TableBodyCol onClick={() => this.props.goToUserInfo({
                                                id: item.id,
                                                screening: item.last_measurement_date,
                                                status: item.status
                                              })}>
                  <TableBodyDiv>{item.name}</TableBodyDiv>
                  <TableSubBodyDiv>ID {item.id}</TableSubBodyDiv>
                </TableBodyCol>
                <TableBodyCol>
                  <DepartmentsSubHeaderItem
                    style={{width: "65%"}}
                    background={this.backgroundByStatus(item.status)}
                    border={"0.5px solid rgba(0,0,0,0.1)"}
                  >
                    <DepartmentsSubHeaderItemText color={this.colorTextByStatus(item.status)}
                      style={{paddingRight: "10px"}}
                    >
                      {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
                    </DepartmentsSubHeaderItemText>
                  </DepartmentsSubHeaderItem>
                </TableBodyCol>
                <TableBodyCol>{item.last_measurement_date ? moment.utc(item.last_measurement_date).local().format("YYYY-MM-DD HH:mm") : "-"}</TableBodyCol>
                <TableBodyCol>{item.created ? moment(item.created).format("DD/M/YYYY") : "-"}</TableBodyCol>
              </TableBodyRow>
            )}
          )}
        </TableDepartmentContainer>
        {this.renderLoadMoreBtn()}
        </>)
  }
}