import { ApiBase } from "../api-base"

export class MeasurementApi extends ApiBase {

  get apiName() {
    return "measurement"
  }

  async getMeasurements(data) {
    return await this.post("/list/", { body: data }, true, "data")
  }

  async getAllMeasurements(data) {
    return await this.post("/list_statuses/", { body: data }, true, "data")
  }

  async getTemperature(data) {
    return await this.post("/list_temperature/", { body: data }, true, "data")
  }

}