import { NavigationStore } from "../../navigation/navigation.store"
import { AuthStore } from "../auth/auth.store"
import { CompaniesStore } from "../companies/companies.store"
import { DepartmentStore } from "../department/department.store"
import { DepartmentsStore } from "../departments/departments.store"
import { GlobalStore } from "./global.store"
import { UserStore } from "../user/user.store"
import { MedicalUserStore } from "../patient/user.store"
import { BranchesStore } from "../branches/branches.store"
import { AddPatientStore } from "../add_patient/add_patient.store"
import { BranchStore } from "../branch/branch.store"

export class GlobalProps {
  t: any
  globalStore: GlobalStore = null as any
  navigationStore: NavigationStore = null as any
  authStore: AuthStore = null as any
  companiesStore: CompaniesStore = null as any
  branchStore: BranchStore = null as any
  branchesStore: BranchesStore = null as any
  departmentStore: DepartmentStore = null as any
  departmentsStore: DepartmentsStore = null as any
  userStore: UserStore = null as any
  medicalUserStore: MedicalUserStore = null as any
  patientStore: AddPatientStore = null as any
}