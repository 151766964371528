import { types, applySnapshot, getEnv, getRoot } from "mobx-state-tree"
import {
  DepartmentsDataModel,
  NewDepartmentDataModel,
  constructDepartments,
  SortedDepartmentsDataModel,
  ManagersDataModel,
  SortedManagersDataModel,
  NewManagerDataModel,
  constructManagers} from "./types/dashboard.model"
import _ from "lodash"
import { toJS } from "mobx"
import { Environment } from "../../app/environment"
import { RootStore } from "../../app/root-store"

export const BranchesStoreModel = types.model("BranchesStore")
  .props({
    departments: types.optional(types.array(DepartmentsDataModel), []),
    staffCompanies: types.frozen(),
    staffDepartments: types.frozen(),
    sortedDepartments: types.optional(types.array(SortedDepartmentsDataModel), []),
    managers: types.optional(types.array(ManagersDataModel), []),
    sortedManagers: types.optional(types.array(SortedManagersDataModel), []),
    newDepartment: types.optional(NewDepartmentDataModel, {}),
    newManager: types.optional(NewManagerDataModel, {}),
    newInfo: types.optional(NewDepartmentDataModel, {})
  })
  .volatile(() => ({
    sortedType: "desc",
    sortedValue: "",
    defaultLenght: 7
  }))
  .actions((self) => ({
    setStaffCompanies(companies) {
      self.staffCompanies = companies
    },
    setStaffDepartments(departments) {
      self.staffDepartments = departments
    },
    setNewDepartment(newDepartment) {
      self.departments = [newDepartment, ...self.departments] as any
    },
    setNewManager(newManager) {
      self.managers = [newManager, ...self.managers] as any
    },
    setNewDepartmentData(value: string, type: string) {
      self.newDepartment[type] = value
    },
    setNewManagerData(value: string, type: string) {
      self.newManager[type] = value
    },
    setNewInfoData(value: string, type: string) {
      self.newInfo[type] = value
    },
    sortBy(item: any, type: "sortedDepartments" | "sortedManagers") {
      self.sortedValue = item
      if(self.sortedValue === item) {
        self.sortedType === "desc" ? self.sortedType = "asc" : self.sortedType = "desc"
      } else {
        self.sortedType = item === "name" ? "asc" : "desc"
      }
      self[type] = _.orderBy(toJS(self[type]), item, (self.sortedType as any) ) as any
    },
    setSortedDepartments(departments) {
      const newDepartments: any = toJS(departments).slice(0, self.defaultLenght)
      self.sortedDepartments = newDepartments 
    },
    setSortedManagers(managers) {
      const newManagers: any = toJS(managers).slice(0, self.defaultLenght)
      self.sortedManagers = newManagers 
    }
  }))
  .actions((self) => ({
    setDepartments(departments: any) {
      self.departments = departments as any
    },
    setManagers(managers: any) {
      self.managers = managers as any
    },
    loadMore(type: "sortedDepartments" | "sortedManagers") {
      self.defaultLenght += 5
      type === "sortedDepartments"
      ? self.setSortedDepartments(self.departments)
      : self.setSortedManagers(self.managers)
    },
    reset() {
      applySnapshot(self, {})
    },
    search(value, type: "sortedDepartments" | "sortedManagers") {
      const isDepartments = type === "sortedDepartments"
      if(value) {
        const item = isDepartments
        ? self.departments
        : self.managers
        const findItems = toJS(item as any).filter(i => i.name.includes(value))
        self[type] = findItems as any
      } else {
        self.setSortedDepartments(self.departments)
      }
    }
  }))
  .actions((self) => ({
    async getManagers() {
      const root = getRoot(self) as RootStore
      if(root.globalStore.user.role !== "owner") return
      const env: Environment = getEnv(self)
      const {currentCompany} = root.globalStore
      const id_company = currentCompany ? currentCompany.id : ""
      const managersResp = await env.api.company.getManagers(id_company)
      if(managersResp){
        const data = managersResp.data
        if(!data) return
        self.setManagers(data.map(item => constructManagers(item)))
        self.setSortedManagers(self.managers)
      }
    },
    replaceDepartmentsByCompanyId(id) {
      const departmentsForCurrentCompany = self.staffDepartments.filter(ditem => ditem.id_company === id)
      self.setDepartments(departmentsForCurrentCompany.map(item => constructDepartments(item)))
      self.setSortedDepartments(self.departments)
    }
  }))
  .actions((self) => ({
    async addDepartment() {
      const root = getRoot(self) as RootStore
      const env: Environment = getEnv(self)
      const newDepartment = {
        name: self.newDepartment.name,
        description: self.newDepartment.description,
        id_company: root.globalStore.currentCompany.id
      }
      const departmentResp = await env.api.department.initDepartment(newDepartment)
      if(departmentResp) {
        root.companiesStore.getCompanies()
        const {data} = departmentResp.data
        data && self.setNewDepartment(constructDepartments(data))
        self.setSortedDepartments(self.departments)
      }
    },
    async addManager() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const newManager = {
        name: self.newManager.name,
        invitation_email: self.newManager.email,
        id_company: root.globalStore.currentCompany.id
      }
      const managerResp = await env.api.company.addManager(newManager)
      if(managerResp) {
        await self.getManagers()
      }
    },
    async getDepartments() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentCompany} = root.globalStore
      const id_company = root.globalStore.user.role === "owner" || root.globalStore.user.role === "doctor"
      ? currentCompany ? currentCompany.id : ""
      : {}
      const departmentsResp = await env.api.department.getDepartments(id_company)
      if(departmentsResp){
        const data = departmentsResp.data
        if(!data || !data.departments) return
        self.setDepartments(data.departments.map(item => constructDepartments(item)))
        root.globalStore.setCurrent({
          name: data.name,
          id: data.id_company,
          description: data.description
        }, "currentCompany")
        self.setSortedDepartments(self.departments)
      }
    },
    async getStaffDepartments() {
      const env: Environment = getEnv(self)
      const departmentsResp = await env.api.department.getStaffDepartments()
      if(departmentsResp){
        const data = departmentsResp.data
        const root = getRoot(self) as RootStore
        if(!data || !data.data) return
        self.setStaffCompanies(data.data.companies)
        self.setStaffDepartments(data.data.departments)
        root.globalStore.setCurrent({
          id: data.data.companies[0].id_company,
          name: data.data.companies[0].company_name
        }, "currentCompany")
        self.replaceDepartmentsByCompanyId(data.data.companies[0].id_company)
      }
    },
    goToDepartment(currentDepartament) {
      const root = getRoot(self) as RootStore
      root.globalStore.setCurrent(toJS(currentDepartament), "currentDepartment")
      root.globalStore.goTo("branch")
    }
  }))

export type BranchesStore = typeof BranchesStoreModel.Type