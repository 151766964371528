import React from "react"
import { DepartmentPopupWrapper, DepartmentPopup, DepartmentIconWrapper, FormWrapper, DepartmentPopupTitle, Control, GenderBtn, DepartmentCancelBtn, DepartmentAddBtn, ErrorMessageWrapper, ErrorMessageText } from "../department.presets"
import { Icon } from "../../../icons/icon"
import { Form, Row, Col } from "react-bootstrap"
import { alphabeticRule, requiredRule, validateField, dateRule } from "../../../services/validation"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./style.css"
import { observer } from "mobx-react"
import moment from "moment"

interface AddEmployeePopupProps {
  t: any
  newEmployee: any
  addEmployee: any
  changeValue: any
  closePopup: any
  changePhone: any
  setNewEmployeeData: any
}

@observer
export class AddEmployeePopup extends React.Component<AddEmployeePopupProps>{

  state = {
    firstNameError: "",
    lastNameError: "",
    genderError: "",
    birthdayError: ""
  }

  componentWillUnmount() {
    this.props.changeValue(null, "birthday")
  }

  sendData = async () => {
    const { newEmployee, addEmployee } = this.props
    const validationFirstName: any = validateField(alphabeticRule, newEmployee.first_name)
    const validationLastName: any = validateField(alphabeticRule, newEmployee.last_name)
    const validationGender: any = validateField(requiredRule, newEmployee.gender)
    const validationDate: any = validateField(dateRule, moment(newEmployee.birthday).format("DD/MM/YYYY"))
    if (
      validationFirstName.isValid && validationLastName.isValid
      && validationGender.isValid && validationDate.isValid
    ) {
      addEmployee()
    } else {
      this.setState({
        firstNameError: validationFirstName.error ? validationFirstName.error : "",
        lastNameError: validationLastName.error ? validationLastName.error : "",
        genderError: validationGender.error ? validationGender.error : "",
        birthdayError: validationDate.error ? validationDate.error : ""
      })
    }
  }

  renderErrorMessage = (type: string) => {
    return(
      <ErrorMessageWrapper>
        <ErrorMessageText>
          {this.state[type]}
        </ErrorMessageText>
      </ErrorMessageWrapper>
    )
  }

  isAllDataEntered = () => {
    const { newEmployee } = this.props
    return !!(newEmployee.first_name.length && newEmployee.last_name.length && newEmployee.gender.length)
  }

  renderBtns = () => {
    const {t} = this.props
    return(
      <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
        <DepartmentCancelBtn
          variant="outline-secondary"
          onClick={this.props.closePopup}
        >{t("dashboard.cancel")}</DepartmentCancelBtn>
        <DepartmentAddBtn
          variant="primary"
          onClick={this.sendData}
          disabled={!this.isAllDataEntered()}
        >{t("dashboard.add")}</DepartmentAddBtn>
      </div>
    )
  }

  render() {
    const { newEmployee, setNewEmployeeData, t } = this.props
    const isFirstNameError = this.state.firstNameError.length > 0
    const isLastNameError = this.state.lastNameError.length > 0

    return <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={this.props.closePopup}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
            {t("dashboard.department.employees.popup.title")}
            </DepartmentPopupTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Group controlId="formGroupEmployeeFirstName">
                <Form.Label style={{color: "#212121"}}>{t("dashboard.department.employees.popup.firstNameInputTitle")}</Form.Label>
                <Control
                  showerror={isFirstNameError ? "true" : "false"}
                  onFocus={() => this.setState({firstNameError: ""})}
                  type="text"
                  placeholder={t("dashboard.department.employees.popup.firstNamePlaceholder")}
                  onChange={(e) => this.props.changeValue(e, "first_name")}
                />
                {this.renderErrorMessage("firstNameError")}
              </Form.Group>
              <Form.Group controlId="formGroupEmployeeLastName">
                <Form.Label style={{color: "#212121"}}>{t("dashboard.department.employees.popup.lastNameInputTitle")}</Form.Label>
                <Control
                  showerror={isLastNameError ? "true" : "false"}
                  onFocus={() => this.setState({lastNameError: ""})}
                  type="text"
                  placeholder={t("dashboard.department.employees.popup.lastNamePlaceholder")}
                  onChange={(e) => this.props.changeValue(e, "last_name")}
                />
                {this.renderErrorMessage("lastNameError")}
              </Form.Group>
            </div>
            <Form.Group controlId="formGroupEmployeeBirthday">
              <Form.Label style={{color: "#212121"}}>{t("dashboard.department.employees.popup.birthdayInputTitle")}</Form.Label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  onFocus={() => {
                    this.setState({birthdayError: ""})
                    const el: any = document.querySelectorAll(".MuiFormControl-marginNormal")
                    el[0].setAttribute("style", "border: 1.5px solid #38699E!important;");
                  }}
                  onBlur={() => {
                    const el: any = document.querySelectorAll(".MuiFormControl-marginNormal")
                    el[0].setAttribute("style", "border: 1px solid lightgray!important;");
                  }}
                  id="date-picker-inline"
                  placeholder={"dd/mm/yyyy"}
                  value={this.props.newEmployee.birthday || null}
                  onError={() => {
                    if(this.state.birthdayError.length){
                      const el: any = document.querySelectorAll(".MuiFormControl-marginNormal")
                      el[0].setAttribute("style", "border: 1.5px solid red!important;");
                    }
                  }}
                  onChange={(e) => {
                    this.props.changeValue(e, "birthday")
                  }}
                  InputProps={{ style: {width: "100%", textAlign: "center", paddingLeft: "10px", cursor: "pointer"}, disableUnderline: true }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              {this.renderErrorMessage("birthdayError")}
            </Form.Group>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Form.Group controlId="formGroupEmployeeHeight" style={{position: "relative"}}>
                <Form.Label style={{color: "#212121"}}>{t("dashboard.department.employees.popup.heightInputTitle")}</Form.Label>
                <Control
                  showerror={"false"}
                  type="numeric"
                  maxLength="3"
                  placeholder={t("dashboard.department.employees.popup.heightPlaceholder")}
                  onChange={(e) => this.props.changeValue(e, "height")}
                />
                <span style={{position: "absolute", top: 38, right: 18, background: "white"}}>{t("dashboard.department.employees.popup.cm")}</span>
              </Form.Group>
              <Form.Group controlId="formGroupEmployeeWeight" style={{position: "relative"}}>
                <Form.Label style={{color: "#212121"}}>{t("dashboard.department.employees.popup.weightInputTitle")}</Form.Label>
                <Control
                  showerror={"false"}
                  type="numeric"
                  maxLength="3"
                  placeholder={t("dashboard.department.employees.popup.weightPlaceholder")}
                  onChange={(e) => this.props.changeValue(e, "weight")}
                />
                <span style={{position: "absolute", top: 38, right: 18, background: "white"}}>{t("dashboard.department.employees.popup.kg")}</span>
              </Form.Group>
            </div>
            <Row>
              <Col>
                <Form.Group controlId="formGroupEmployeePhone">
                  <Form.Label style={{color: "#212121"}}>{t("dashboard.department.employees.popup.phoneInputTitle")}</Form.Label>
                  <PhoneInput
                    country={'it'}
                    value={newEmployee.phone}
                    inputProps={{style: {width: "100%"}}}
                    onlyCountries={["ua","it","kz"]}
                    onChange={phone => this.props.changePhone(phone)}
                  />
                </Form.Group>
              </Col>
              {this.renderErrorMessage("phone")}
            </Row>
            <Form.Group controlId="formGroupEmployeeBiological">
              <Form.Label style={{color: "#212121"}}>{t("dashboard.department.employees.popup.genderTitle")}</Form.Label>
              <div style={{display: "flex", flexDirection: "row"}}>
                <GenderBtn
                  showgender={newEmployee.gender === "male" ? "true" : "false"}
                  onClick={() => {
                    this.setState({genderError: ""})
                    setNewEmployeeData("male", "gender")
                  }}
                >{t("dashboard.department.employees.popup.genderMale")}</GenderBtn>
                <GenderBtn
                  showgender={newEmployee.gender === "female" ? "true" : "false"}
                  onClick={() => {
                    this.setState({genderError: ""})
                    setNewEmployeeData("female", "gender")
                  }}
                >{t("dashboard.department.employees.popup.genderFemale")}</GenderBtn>
              </div>
              {this.renderErrorMessage("genderError")}
            </Form.Group>
            {this.renderBtns()}
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
  }
}