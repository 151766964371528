import React from "react"
import { Spinner } from "react-bootstrap"

export class Loading extends React.Component{
  render(){
    return(
      <div style={{display: "flex", height: "410px", width: "100%", alignItems: "center", justifyContent: "center"}}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }
}