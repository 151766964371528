import { styled, theme } from "../../../../shared/theme"
import { Form } from "react-bootstrap"

export const Wrapper = styled.div`
  padding: 50px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.bgColor};
`
export const Logo = styled.div`
  
`

export const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding-top: 30px;
  color: ${theme.colors.gray};
`

export const Description = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #212121;
  width: 90%;
`

export const FormWrapper = styled.div`
  min-width: 400px;
  position: relative;
`

export const PasswordTitle = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Footer = styled.div`
  margin-top: 55px;
  display: flex;
  justify-content: center;
`
export const FooterTitle = styled.span`
  padding-right: 5px;
  color: ${theme.colors.black};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

export const FooterBtn = styled.span`
  color: #38699E;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

export const Control = styled(Form.Control)`
  outline: none;
  border-color: ${props => props.showerror === "true" ? "red" : ""};
  :focus {
    border-width: 2px;
    border-color: #38699E;
    box-shadow: none;
  }
`

export const ValidationDesc = styled.p`
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.color ? props.color : "#616161"};
`

export const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`

const eye = {
  display: "flex",
  position: "relative",
  flexDirection: "row"
}

const btnOn = {
  marginTop: "25px",
  color: theme.colors.white,
  background: "#38699E",
  border: "#616161"
}

const btnOff = {
  marginTop: "25px",
  color: "#616161",
  background: "#E0E0E0",
  border: "#616161"
}

const icon = {
  right: "10px",
  top: "10px",
  position: "absolute",
  cursor: "pointer"
}



export default {
  btnOn,
  btnOff,
  icon,
  eye
}
