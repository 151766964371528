import { ApiBase } from "../api-base"
import { save } from "../../../lib/storage"

export class AuthApi extends ApiBase {

  get apiName() {
    return "auth"
  }

  async refreshToken(refresh: string) {
    return await this.post(`/refresh/`,{ body: {refresh}})
  }

  async login(email: string, password: string) {
    const loginResp = await this.post("/obtain/", { body: { email: email.toLowerCase(), password } }, false)
    if(loginResp && loginResp.data) {
      await save("token", {
        access: loginResp.data.access,
        refresh: loginResp.data.refresh,
      })
      return loginResp.data.staff_profile
    }
      return null
  }

}
