import { types, applySnapshot, getEnv, getRoot } from "mobx-state-tree"
import { UserDataModel, constructUser } from "./types/global.model"
import { Environment } from "../../app/environment"
import { clear } from "../../lib/storage"
import { RootStore } from "../../app/root-store"

export const GlobalStoreModel = types.model("UserStore")
  .props({
    user: types.optional(UserDataModel, {}),
    currentCompany: types.frozen(),
    currentDepartment: types.frozen(),
    currentEmployee: types.frozen(),
    todaysDate: types.optional(types.Date, new Date()),
    currentDate: types.optional(types.Date, new Date()),
    userInfoCurrentDate: types.optional(types.Date, new Date()),
    companyType: types.optional(types.string, "corporate")
  })
  .views((self) => ({
    get isADoctor(): boolean {
      const {role} = self.user
      return role === "doctor" || role === "govdoctor"
    },
    get isAOwner(): boolean {
      const {role} = self.user
      return role === "owner" || role === "companyowner"
    },
    get isASupport(): boolean {
      return  self.user.role === "support"
    },
    get isASupervisor(): boolean {
      return  self.user.role === "supervisor"
    }
  }))
  .actions((self) => ({
    reset() {
      applySnapshot(self, {})
    },
    goTo(route: string) {
      const env: Environment = getEnv(self)
      env.navigation.push(route)
    }
  }))
  .actions((self) => ({
      setUser(userResp: any) {
        self.user = constructUser(userResp)
      },
      setCurrent(item: any, type: "currentCompany" | "currentDepartment" | "currentEmployee" | "currentDate" | "userInfoCurrentDate" | "companyType"){
        self[type] = item
      }
  }))
  .actions((self) => ({
    logout() {
      clear()
      const root = getRoot(self) as RootStore
      root.reset()
      self.goTo("signin")
    }
  }))

export type GlobalStore = typeof GlobalStoreModel.Type