import React from "react"
import { Icon } from "../../icons/icon"
import {
  Header,
  HeaderTitle,
  HeaderTitleText,
  HeaderMenu,
  HeaderBtn,
} from "./presets"
import { observer } from "mobx-react";

@observer
export class DashboardHeader extends React.Component<{globalStore: any, t?: any, isRootComponent?: boolean}>{

  logout = () => {
    this.props.globalStore.logout()
  }

  getUserRole = (role) => {
    if(role === "owner") { return "Owner "}
    if(role === "companyowner") { return "Company Owner"}
    if(role === "doctor") { return "Doctor"}
    if(role === "support") { return "Support"}
    if(role === "govdoctor") { return "National Health System Doctor"}
    if(role === "supervisor") {return "Supervisor"}
    return "Owner"
  }

  render(){
    const { user, goTo } = this.props.globalStore
    const { isRootComponent } = this.props;
    const baseRoute: "/companies" | "/branches" | "/departments" = 
      user.role === "owner" ? "/companies" : user.account_type === 'rpm' ? "/branches" : "/departments"
    const { t } = this.props
    return(
      <Header>
        <HeaderMenu justify="flex-start">
          <Icon icon={"dashboardLogo"}/>
        </HeaderMenu>
        <HeaderTitle>
          <Icon icon={user.role === "doctor" || user.role === "govdoctor" || user.role === "supervisor" ? "heart" : "star"}/>
          <HeaderTitleText>{user.name} • {this.getUserRole(user.role)}</HeaderTitleText>
        </HeaderTitle>
        <HeaderMenu justify="flex-end">
          <HeaderBtn
            isBorder={isRootComponent}
            onClick={() => goTo(baseRoute)}
          >
            {t("menu.home")}
          </HeaderBtn>
          {/* <HeaderBtn
            isBorder={currentPage === "settings"}
            onClick={() => setCurrentPage("settings")}
          >{t("menu.settings")}</HeaderBtn> */}
          <HeaderBtn
            onClick={this.logout}
          >
            {t("menu.logout")}
          </HeaderBtn>
        </HeaderMenu>
      </Header>
    )
  }
}