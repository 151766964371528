import { ApiBase } from "../api-base"

export class DashboardApi extends ApiBase {

  get apiName() {
    return "dashboard"
  }

  async inviteEmployee(data) {
    return await this.post("/invite_user/", { body: data}, true, "user")
  }

  async getEmployees(data) {
    return await this.post("/list_users/", { body: data}, true, "user")
  }

  async getAllEmployees(data) {
    return await this.post("/list_user_statuses/", { body: data}, true, "user")
  }

  async getEmployee(data) {
    return await this.post("/user/", { body: data}, true, "user")
  }

  async removeUser(data) {
    return await this.post("/remove_department/", { body: data}, true, "user")
  }

  async moveUser(data) {
    return await this.post("/assign_department/", { body: data}, true, "user")
  }

  async getSaturation(data) {
    return await this.post("/list_saturation/", { body: data }, true, "data")
  }

  async getBP(data) {
    return await this.post("/list_bp/", { body: data }, true, "data")
  }

  async getHeartRate(data) {
    return await this.post("/list_heart_rate/", { body: data }, true, "data")
  }

  async getECG(data) {
    return await this.post("/list_ecg/", { body: data }, true, "data")
  }

  async getPlanList() {
    return await this.get("/connection_plans/", {}, true, "user")
  }

  async isMRNReg(data) {
    return await this.post("/is_mrn_registered/", { body: data }, true, "user")
  }

  async inviteMedicalPatient(data) {
    return await this.post("/invite_rpm_user/", { body: data }, true, "user")
  }

  async inviteCaregiverUser(data) {
    return await this.post("/invite_caregiver_user/", { body: data }, true, "user")
  }

  async getBillingCodes() {
    return await this.get("/billing_codes/", {}, true, "user")
  }

  async setReminders(data) {
    return await this.post("/set_reminders/", {body: data}, true, "data")
  }

  async getReminders(data) {
    return await this.post("/get_reminders/", {body: data}, true, "data")
  }

  async setFilters(data) {
    return await this.post("/set_filters/", { body: data }, true, "data")
  }

  async setBillingCode(data) {
    return await this.post("/set_billing_code/", { body: data }, true, "user")
  }

  async setICDCode(data) {
    return await this.post("/set_icd_code/", { body: data }, true, "user")
  }

  async getFilters(data) {
    return await this.post("/get_filters/", { body: data }, true, "data")
  }

  async listRecordsRPM(data) {
    return await this.post("/list_records_rpm/", { body: data }, true, "data")
  }

  async listPatientPatchRecords(data) {
    return await this.post("/list_patient_records_patch/", {body: data}, true, "data")
  }

  async createPatientRecordPatch(data) {
    return await this.post("/create_patient_record_patch/", {body: data}, true, "data")
  }
}