import { types, applySnapshot, getEnv, getRoot } from "mobx-state-tree"
import { AuthDataModel, AuthOwnerDataModel } from "./types/auth.model"
import { Environment } from "../../app/environment"
import { RootStore } from "../../app/root-store"

export const AuthStoreModel = types.model("AuthStore")
  .props({
    role: types.optional(types.string, ""),
    signIn: types.optional(types.boolean, true),
    data: types.optional(AuthDataModel, {}),
    ownerData: types.optional(AuthOwnerDataModel, {})
  })
  .views(self => ({
    get allDataEntered(): boolean {
      const data = self.role ? self.ownerData : self.data
      return !!(data.email.length && data.password.length)
    },
    get emailEnterd(): boolean {
      return !!self.data.email.length
    },
  }))
  .actions((self) => ({
    goTo(route: string) {
      const env: Environment = getEnv(self)
      env.navigation.push(route)
    }
}))
  .actions((self) => ({
    async login() {
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const userResp = await env.api.auth.login(self.data.email, self.data.password)
      if (userResp) {
        root.globalStore.setUser(userResp)
        self.goTo(
          root.globalStore.user.role === "owner"
          ? "companies"
          : root.globalStore.user.account_type === "rpm"
            ? "branches"
            : "departments"
        )
        return userResp
      }
        return null
    },

    async signup() {
      const env: Environment = getEnv(self)
      return await env.api.organization.registration(self.ownerData)
    }
  }))
  .actions((self) => ({
    setUserRole(role: string) {
      self.role = role
    },
    setUserData(value: string, type: string) {
      self.data[type] = value
    },
    setOwnerData(value: string, type: string) {
      self.ownerData[type] = value
    },
    reset() {
      applySnapshot(self, {})
    }
  }))

export type AuthStore = typeof AuthStoreModel.Type