import React from "react"
import presets, { 
  Wrapper,
  Logo,
  Title,
  FormWrapper,
  ControlWrapper,
  PasswordTitle,
  Control } from "./signin.presets"
import { Icon } from "../../../../icons/icon"
import { Form, Button } from "react-bootstrap"
import { validateField, emailRule, passwordRule } from "../../../../services/validation"
import { smartInject } from "../../../../utils/smartInject"
import { GlobalProps } from "../../../global/global.props"
import { withTranslation, WithTranslation } from 'react-i18next';

@(withTranslation() as any)
@smartInject(GlobalProps)
export class Signin extends React.Component<GlobalProps & WithTranslation> {

  state = {
    type: "password",
    isDisabledBtn: true,
    emailError: "",
    passwordError: ""
  }

  componentDidMount() {
    this.props.authStore.reset()
  }

  changeType = () => {
    if(this.state.type === "password") this.setState({type: "text"})
    if(this.state.type === "text") this.setState({type: "password"})
  }

  changeValue = (e: any, type: string) => {
    this.props.authStore.setUserData(e.target.value, type)
  }

  sendData = async () => {
    const formattedEmail = (this.props.authStore.data.email || "").trim().toLowerCase()
    const validationEmail: any = validateField(emailRule, formattedEmail)
    const validationPassword: any = validateField(passwordRule, this.props.authStore.data.password)
    if (validationEmail.isValid && validationPassword.isValid) {
      await this.props.authStore.login()
    } else {
      this.setState({
        emailError: validationEmail.error ? validationEmail.error : "",
        passwordError: validationPassword.error ? validationPassword.error : ""
      })
    }
  }

  goToForgotPassword = () => {
    this.props.authStore.goTo("forgot-password")
  }

  renderLogo = () => {
    return(
      <Logo>
        <Icon icon="logo"/>
      </Logo>
    )
  }

  renderErrorMessage = (type: string) => {
    return(
      <div style={{width: "100%", position: "relative" }}>
        <span style={{color: "red", fontSize: "12px", position: "absolute", width: "100%", textAlign: "right"}}>
          {this.state[type]}
        </span>
      </div>
    )
  }

  renderForm = () => {
    const { t } = this.props
    const { allDataEntered } = this.props.authStore
    const isEmailError = this.state.emailError.length > 0
    const isPasswordError = this.state.passwordError.length > 0
    return(
      <FormWrapper>
      <Title>{t("auth.login.title")}</Title>
      <Form>
        <Form.Group controlId="formGroupEmail">
          <Form.Label style={{color: "#212121"}}>{t("auth.login.emailInputTitle")}</Form.Label>
          <Control
            showerror={isEmailError ? "true" : "false"}
            onFocus={() => this.setState({emailError: ""})}
            type="email"
            placeholder={t("auth.login.emailPlaceholder")}
            onChange={(e) => this.changeValue(e, "email")}
          />
          {this.renderErrorMessage("emailError")}
        </Form.Group>
        <Form.Group controlId="formGroupPassword">
          <PasswordTitle>
            <Form.Label style={{color: "#212121"}}>{t("auth.login.passwordInputTitle")}</Form.Label>
            <span style={{color: "#38699E", cursor: "pointer"}} onClick={this.goToForgotPassword}>{t("auth.login.forgotBtnText")}</span>
          </PasswordTitle>
          <ControlWrapper>
            <Control
              showerror={isPasswordError ? "true" : "false"}
              type={this.state.type}
              onFocus={() => this.setState({passwordError: ""})}
              placeholder={t("auth.login.passwordPlaceholder")}
              onChange={(e) => this.changeValue(e, "password")}
            />
            <Icon icon="eye" onClick={this.changeType} style={presets.icon}/>
          </ControlWrapper>
          {this.renderErrorMessage("passwordError")}
        </Form.Group>
      </Form>
      <Button
        block
        onClick={this.sendData}
        variant="secondary"
        style={allDataEntered ? presets.btnOn : presets.btnOff}
        disabled={!allDataEntered}
      >
        {t("auth.login.btnText")}
      </Button>
      </FormWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        {this.renderLogo()}
        {this.renderForm()}
        <div/>
      </Wrapper>
    )
  }
}