import Validate from "validate.js"
import { includes, isEmpty } from "lodash"
import { ValidationRules, ValidationResult, SingleFieldValidationResult } from "./validation.types"

/**
 * Validates that 1 attribute doesn't appear in another's attributes content.
 */
Validate.validators.excludes = function custom(value, options, key, attributes) {
  const list = attributes[options.attribute] || []
  if (value && includes(value, list)) {
    return options.message || `${value} is in the list`
  }
}

/**
 * Validates that another attribute isn't true.
 */
Validate.validators.tripped = function custom(value, options, key, attributes) {
  if (value && attributes[options.attribute] === true) {
    return options.message || `${options.attribute} is true`
  }
}

/**
 * Custom formatter which returns only one error for each field
 */
Validate.formatters.custom = function(errors) {
  return errors.reduce((state, error) => {
    state[error.attribute] = error.error
    return state
  },                   {})
}

/**
 * Runs the given rules against the data object.
 *
 * @param rules The rules to apply.
 * @param data The object to validate.
 */
export function validate(rules: ValidationRules, data: {}): ValidationResult {
  const result = typeof data !== "object"
    ? {}
    : Validate(data, rules, { fullMessages: false, format: "custom" }) || {}

  return {
    isValid: isEmpty(result),
    errors: result
  }
}

export function validateField(rule: any, value: any): SingleFieldValidationResult {
  const result = validate({ field: rule }, { field: value })
  return {
    isValid: result.isValid,
    error: result.isValid ? undefined : result.errors.field
  }
}
