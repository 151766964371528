import { GeneralApiProblem } from "../services/api/api.types"

export class ErrorTracking {

  static setUserIdentifier(user) {
    // add specific trackers
  }

  static setLastApiRequest(apiName: string, url: string, params: object) {
    // add specific trackers
  }

  static setLastApiSuccessResponse(apiResponse: object) {
    // add specific trackers
  }

  static setLastApiErrorResponse(apiResponse: GeneralApiProblem) {
    // add specific trackers
  }
}



