import { createGlobalStyle } from "./styled"
import normalize from "styled-normalize"
import { theme } from "./theme"

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    -webkit-tap-highlight-color: transparent;
    font-size: ${theme.typography.body.fontSize};
    color: ${theme.colors.darkGray};
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    outline: none !important;
  }

  html{
    min-height: 100%;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }

  body{
    height: 100%;
    background: ${theme.colors.bgColor};
    min-height: 100%;
  }

  p {
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.body.fontWeight};
    color: ${theme.typography.body.color};
    line-height: ${theme.typography.body.lineHeight};
  }

  #dropdown-basic-button {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
