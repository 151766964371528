import React from "react"
import {
  DashboardWrapper,
  DashboardTitle,
  DashboardContainer,
  CompanyTitle,
  TextArea,
  DepartmentsIconWrapper,
  DepartmentsPopupWrapper,
  DepartmentsPopup,
  Control,
  FormWrapper,
  DepartmentsPopupTitle,
  DepartmentsCancelBtn,
  DepartmentsAddBtn,
  TableHeaderRow,
  TableHeaderCol,
  TableBodyCol,
  TableEditBtn,
  TableBodyRow,
  TableDepartmentsContainer,
  TableBtns,
  TableFooterRow,
  Wrapper,
  PendingBadge,
  ActiveBadge} from "./branches.presets"
import { Icon } from "../../icons/icon"
import { smartInject } from "../../utils/smartInject"
import { Form, Tabs, Tab } from "react-bootstrap"
import moment from "moment"
import { GlobalProps } from "../global/global.props"
import { DashboardHeader } from "../../components/dashboard-header"
import { SpecificHeader } from "../../components/specific-header"
import { withTranslation, WithTranslation } from 'react-i18next';
import { Loading } from "../../components/spinner"
import { Breadcrumbs } from "../../shared/Breadcrumbs"
import { CompaniesTitle, CompaniesHeader } from "../../components/specific-header/presets"

@(withTranslation() as any)
@smartInject(GlobalProps)
export class Branches extends React.Component<GlobalProps & WithTranslation> {

  state = {
    showAddDepartmentPopup: false,
    showAddManagerPopup: false,
    isLoading: false
  }

  async componentWillMount() {
    this.setState({isLoading: true})
    const { isADoctor, isASupport, isASupervisor } = this.props.globalStore
    if(isADoctor || isASupport || isASupervisor) {
      await this.props.branchesStore.getStaffDepartments()
    } else {
      await this.props.branchesStore.getDepartments()
      await this.props.branchesStore.getManagers()
    }
    this.presetCompanyInfo()
    this.setState({isLoading: false})
  }

  presetCompanyInfo = () => {
    const {currentCompany} = this.props.globalStore
    this.props.branchesStore.setNewInfoData(currentCompany ? currentCompany.name : "", "name")
    this.props.branchesStore.setNewInfoData(currentCompany ? currentCompany.description : "", "description")
  }

  changeValue = (e, type, storeType: "setNewDepartmentData" | "setNewManagerData") => {
    this.props.branchesStore[storeType](e.target.value, type)
  }

  changeInfoValue = (e, type) => {
    this.props.branchesStore.setNewInfoData(e.target.value, type)
  }

  addCompanyInfo = () => {

  }

  addDepartment = async () => {
    await this.props.branchesStore.addDepartment()
    this.setState({showAddDepartmentPopup: false})
  }

  addManager = async () => {
    await this.props.branchesStore.addManager()
    this.setState({showAddManagerPopup: false})
  }

  search = (e, type) => {
    this.props.branchesStore.search(e.target.value, type)
  }

  resendLink = async (item) => {

  }

  renderCompanies = () => {
    const { staffCompanies } = this.props.branchesStore
    const { currentCompany } =  this.props.globalStore
    return(
    !staffCompanies ? null
    : <>
    <CompaniesHeader>
      <CompaniesTitle>
        {staffCompanies.length + " companies"}
      </CompaniesTitle>
    </CompaniesHeader>
    <div style={{display: "flex", width: "100%", margin: "25px -10px 10px", flexWrap: "wrap"}}>
      {staffCompanies.map((item: any) => <div onClick={async () => {
        this.setState({isLoading: true})
        this.props.globalStore.setCurrent({
          id: item.id_company,
          name: item.company_name
        }, "currentCompany")
        this.props.branchesStore.replaceDepartmentsByCompanyId(item.id_company)
        this.setState({isLoading: false})
      }}
      style={{
        background: "#F5F5F5",
        border: item.id_company === (currentCompany && currentCompany.id)
        ? "2px solid #38699E"
        : "2px solid #F5F5F5",
        color: "#38699E",
        padding: "5px 10px",
        borderRadius: "4px",
        margin: "5px 10px",
        cursor: "pointer"
      }}
      >
        {item.company_name}
      </div>)}
    </div>
    </>
    )
  }

  renderDepartmentHeader = () => {
    const { departments } = this.props.branchesStore
    const { user } = this.props.globalStore
    const { t } = this.props
    return(
      <SpecificHeader
        t={t}
        showBtn={user.role === "owner" || user.role === "companyowner"}
        title={departments.length
          ? `${departments.length} ${t("dashboard.branches.departmentsTab.departmentText")}`
          : t("dashboard.branches.departmentsTab.noDepartment")
        }
        showSearch={!!departments.length}
        onChangeSearch={(e) => this.search(e, "sortedDepartments")}
        onClick={() => this.setState({showAddDepartmentPopup: true})}
        searchPlaceholder={t("dashboard.branches.departmentsTab.search")}
        btnText={t("dashboard.branches.departmentsTab.addDepartmentBtnText")}
      />
    )
  }

  renderManagersHeader = () => {
    const { managers } = this.props.branchesStore
    const { user } = this.props.globalStore
    const { t } = this.props
    return(
      <SpecificHeader
        t={t}
        showBtn={user.role === "owner"}
        title={managers.length
          ? `${managers.length} ${t("dashboard.branches.managersTab.managerText")}`
          : t("dashboard.branches.managersTab.noManager")
        }
        showSearch={!!managers.length}
        onChangeSearch={(e) => this.search(e, "sortedManagers")}
        onClick={() => this.setState({showAddManagerPopup: true})}
        searchPlaceholder={t("dashboard.branches.managersTab.search")}
        btnText={t("dashboard.branches.managersTab.addManagerBtnText")}
      />
    )
  }

  renderDepartmentsPopup = () => {
    const { name, description } = this.props.branchesStore.newDepartment
    const { t } = this.props
    return this.state.showAddDepartmentPopup
    ? <DepartmentsPopupWrapper>
        <DepartmentsPopup>
          <DepartmentsIconWrapper onClick={() => this.setState({showAddDepartmentPopup: false})}>
            <Icon icon="close"/>
          </DepartmentsIconWrapper>
          <FormWrapper>
            <DepartmentsPopupTitle>
            {t("dashboard.branches.departmentsTab.popup.title")}
            </DepartmentsPopupTitle>
            <Form.Group controlId="formGroupDepartmentName">
              <Form.Label style={{color: "#212121"}}>
              {t("dashboard.branches.departmentsTab.popup.nameInputTitle")}
              </Form.Label>
              <Control
                showerror={"false"}
                onFocus={() => this.setState({emailError: ""})}
                type="email"
                placeholder={t("dashboard.branches.departmentsTab.popup.namePlaceholder")}
                onChange={(e) => this.changeValue(e, "name", "setNewDepartmentData")}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label style={{color: "#212121"}}>
                {t("dashboard.branches.departmentsTab.popup.descriptionTitle")}
              </Form.Label>
              <TextArea
                className="form-control"
                as="textarea"
                rows={5}
                placeholder={t("dashboard.branches.departmentsTab.popup.descriptionPlaceholder")}
                onChange={(e) => this.changeValue(e, "description", "setNewDepartmentData")}
              />
            </Form.Group>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentsCancelBtn
              variant="outline-secondary"
              onClick={() => this.setState({showAddDepartmentPopup: false})}
            >{t("dashboard.cancel")}</DepartmentsCancelBtn>
            <DepartmentsAddBtn
              variant="primary"
              onClick={this.addDepartment}
              disabled={!(name.length && description.length)}
            >{t("dashboard.add")}</DepartmentsAddBtn>
            </div>
          </FormWrapper>
        </DepartmentsPopup>
      </DepartmentsPopupWrapper>
    : null
  }

  renderManagersPopup = () => {
    const { name, email } = this.props.branchesStore.newManager
    const { t } = this.props
    return this.state.showAddManagerPopup
    ? <DepartmentsPopupWrapper>
        <DepartmentsPopup>
          <DepartmentsIconWrapper onClick={() => this.setState({showAddManagerPopup: false})}>
            <Icon icon="close"/>
          </DepartmentsIconWrapper>
          <FormWrapper>
            <DepartmentsPopupTitle>
              {t("dashboard.branches.managersTab.popup.title")}
            </DepartmentsPopupTitle>
            <Form.Group controlId="formGroupManagerName">
              <Form.Label style={{color: "#212121"}}>
              {t("dashboard.branches.managersTab.popup.nameInputTitle")}
              </Form.Label>
              <Control
                showerror={"false"}
                onFocus={() => this.setState({emailError: ""})}
                type="email"
                placeholder={t("dashboard.branches.managersTab.popup.namePlaceholder")}
                onChange={(e) => this.changeValue(e, "name", "setNewManagerData")}
              />
            </Form.Group>
            <Form.Group controlId="formGroupEmail">
              <Form.Label style={{color: "#212121"}}>
                {t("dashboard.branches.managersTab.popup.emailInputTitle")}
              </Form.Label>
              <Control
                showerror={"false"}
                onFocus={() => this.setState({emailError: ""})}
                type="email"
                placeholder={t("dashboard.branches.managersTab.popup.emailPlaceholder")}
                onChange={(e) => this.changeValue(e, "email", "setNewManagerData")}
              />
            </Form.Group>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentsCancelBtn
              variant="outline-secondary"
              onClick={() => this.setState({showAddManagerPopup: false})}
            >{t("dashboard.cancel")}</DepartmentsCancelBtn>
            <DepartmentsAddBtn
              variant="primary"
              onClick={this.addManager}
              disabled={!(name.length && email.length)}
            >{t("dashboard.add")}</DepartmentsAddBtn>
            </div>
          </FormWrapper>
        </DepartmentsPopup>
      </DepartmentsPopupWrapper>
    : null
  }

  renderDepartmentTable = () => {
    const { t } = this.props
    const departmentsHeaders = [
      {type: "name", desc: t("dashboard.branches.departmentsTab.table.firstCol")},
      {type: "patients", desc: t("dashboard.branches.departmentsTab.table.secondCol")},
      {type: "staff", desc: t("dashboard.branches.departmentsTab.table.thirdCol")},
      {type: "created", desc: t("dashboard.branches.departmentsTab.table.fourthCol")},
      {type: "btns", desc: ""}
    ]
    const {sortedDepartments, departments} = this.props.branchesStore
    const showMore = sortedDepartments.length !== departments.length
    return(
      <>
      {departments.length ? <TableHeaderRow>
        {departmentsHeaders.map((item, index) => 
        <TableHeaderCol
          key={index}
          onClick={() => this.props.branchesStore.sortBy(item.type, "sortedDepartments")}
        >
          {item.desc}
        </TableHeaderCol>)}
      </TableHeaderRow> : null}
      <TableDepartmentsContainer>
        {sortedDepartments && sortedDepartments.map(item => {
          return(
            <TableBodyRow  showhover="true" key={item.id} onClick={() => this.props.branchesStore.goToDepartment(item)}>
              <TableBodyCol>{item.name}</TableBodyCol>
              <TableBodyCol>{item.patients}</TableBodyCol>
              <TableBodyCol>{item.staff}</TableBodyCol>
              <TableBodyCol>{moment(item.created).format("DD/M/YYYY")}</TableBodyCol>
              <TableBodyCol>
                <TableBtns>
                  <TableEditBtn variant="outline-secondary">{t("dashboard.edit")}</TableEditBtn>
                  {/* <Button variant="outline-danger">{t("dashboard.delete")}</Button> */}
                </TableBtns>
              </TableBodyCol>
            </TableBodyRow>
          )}
        )}
      </TableDepartmentsContainer>
      {showMore ? <TableFooterRow>
        <TableEditBtn
          variant="outline-secondary"
          onClick={() => this.props.branchesStore.loadMore("sortedDepartments")}
        >{t("dashboard.loadMore")}</TableEditBtn>
      </TableFooterRow> : null}
      </>
    )
  }

  renderManagerTable = () => {
    const { t } = this.props
    const managersHeaders = [
      {type: "name", desc: t("dashboard.branches.managersTab.table.firstCol")},
      {type: "status", desc: t("dashboard.branches.managersTab.table.secondCol")},
      {type: "created", desc: t("dashboard.branches.managersTab.table.thirdCol")},
      {type: "btns", desc: ""}
    ]
    const {sortedManagers, managers} = this.props.branchesStore
    const showMore = sortedManagers.length !== managers.length
    return(
      <>
      {managers.length ? <TableHeaderRow>
        {managersHeaders.map((item,index) => 
        <TableHeaderCol
          key={index}
          onClick={() => this.props.branchesStore.sortBy(item.type, "sortedManagers")}
        >
          {item.desc}
        </TableHeaderCol>)}
      </TableHeaderRow> : null}
      <TableDepartmentsContainer>
        {sortedManagers && sortedManagers.map((item, index) => {
          return(
            <TableBodyRow key={index} showhover={"false"}>
              <TableBodyCol>{item.name}</TableBodyCol>
              <TableBodyCol>{
              item.status
              ? <ActiveBadge>{t("dashboard.branches.managersTab.statuses.active")}</ActiveBadge>
              : <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <PendingBadge>{t("dashboard.branches.managersTab.statuses.pending")}</PendingBadge>
                  <div
                    onClick={() => this.resendLink(item)}
                    style={{cursor: "pointer", color: "#38699E", fontSize: "14px", paddingLeft: "10px"}}
                  >
                    {t("dashboard.branches.managersTab.statuses.resend")}
                  </div>
                </div>
              }</TableBodyCol>
              <TableBodyCol>{moment(item.created).format("DD/M/YYYY")}</TableBodyCol>
              <TableBodyCol>
                <TableBtns>
                {/* {item.status
                ? <Button variant="outline-danger">{t("dashboard.delete")}</Button>
                : <Button variant="outline-secondary">{t("dashboard.cancel")}</Button>
                } */}
                </TableBtns>
              </TableBodyCol>
            </TableBodyRow>
          )}
        )}
      </TableDepartmentsContainer>
      {showMore ? <TableFooterRow>
        <TableEditBtn
          variant="outline-secondary"
          onClick={() => this.props.branchesStore.loadMore("sortedManagers")}
        >{t("dashboard.loadMore")}</TableEditBtn>
      </TableFooterRow> : null}
      </>
    )
  }

  renderInfoPage = () => {
    const {name, description} = this.props.branchesStore.newInfo
    const { t } = this.props
    return(
      <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
      <FormWrapper style={{margin: "45px"}}>
        <DepartmentsPopupTitle>
          {t("dashboard.branches.informationTab.title")}
        </DepartmentsPopupTitle>
        <Form.Group controlId="formGroupInfoName">
          <Form.Label style={{color: "#212121"}}>
          {t("dashboard.branches.informationTab.nameInputTitle")}
          </Form.Label>
          <Control
            showerror={"false"}
            type="text"
            value={name}
            placeholder={t("dashboard.branches.informationTab.namePlaceholder")}
            onChange={(e) => this.changeInfoValue(e, "name")}
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{color: "#212121"}}>
          {t("dashboard.branches.informationTab.descriptionInputTitle")}
          </Form.Label>
          <TextArea
            className="form-control"
            as="textarea"
            rows={5}
            value={description}
            placeholder={t("dashboard.branches.informationTab.descriptionPlaceholder")}
            onChange={(e) => this.changeInfoValue(e, "description")}
          />
        </Form.Group>
        <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
        <DepartmentsAddBtn
          style={{width: "100%"}}
          variant="primary"
          onClick={this.addCompanyInfo}
          disabled={!(name.length && description.length)}
        >{t("dashboard.branches.informationTab.btnText")}</DepartmentsAddBtn>
        </div>
      </FormWrapper>
      </div>
    )
  }

  renderDepartments = () => {
    const { user, isAOwner } = this.props.globalStore
    const {t} =this.props
    return(
      isAOwner
      ? <Tabs defaultActiveKey="Departments" id="uncontrolled-tab-example"
        style={{
          marginRight: "-15px",
          marginLeft: "-15px"
        }}
      >
        <Tab eventKey="Departments" title={t("dashboard.branches.tabs.first")}>
          {this.renderDepartmentHeader()}
          {this.state.isLoading ? <Loading/> : this.renderDepartmentTable()}
        </Tab>
        {user.role === "owner" ? <Tab eventKey="Managers" title={t("dashboard.branches.tabs.second")}>
          {this.renderManagersHeader()}
          {this.state.isLoading ? <Loading/> : this.renderManagerTable()}
        </Tab> : null}
        <Tab eventKey="Informations" title={t("dashboard.branches.tabs.third")}>
          {this.renderInfoPage()}
        </Tab>
      </Tabs>
      : <>
        {this.renderCompanies()}
        {this.renderDepartmentHeader()}
        {this.state.isLoading ? <Loading/> : this.renderDepartmentTable()}
      </>
    )
  }

  constructNavData = () => {
    const { t } = this.props
    const { user, currentCompany } = this.props.globalStore
    const name = currentCompany ? currentCompany.name : ""
    const ownerData = [
      {
        goTo: "companies",
        name: t("dashboard.branches.navTitle")
      },{
        name: name
      }
    ]
    const doctorData = [{
      name: t("dashboard.branches.navTitle")
    }
    ]
    const defaultData = [
      {
        name: name
      }
    ]
    switch (user.role) {
      case "owner": return ownerData
      case "companyowner": return defaultData
      case "doctor": return doctorData
      case "govdoctor": return doctorData
      case "support": return doctorData
      case "supervisor": return doctorData
      default: return defaultData
    }
  }

  renderNavigation = () => {
    return(
      <Breadcrumbs
        data={this.constructNavData()}
        globalStore={this.props.globalStore}
      />
    )
  }

  renderDashboard = () => {
    const { currentCompany, isAOwner } = this.props.globalStore
    return(
      <DashboardWrapper>
        <DashboardTitle>
          {this.renderNavigation()}
        </DashboardTitle>
        <DashboardContainer>
          {isAOwner && <CompanyTitle>{
            currentCompany
            ? currentCompany.name
            : "Your Company"
          }</CompanyTitle>}
          {this.renderDepartments()}
        </DashboardContainer>
        {this.renderDepartmentsPopup()}
        {this.renderManagersPopup()}
      </DashboardWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        <DashboardHeader globalStore={this.props.globalStore} t={this.props.t} isRootComponent />
        {this.renderDashboard()}
      </Wrapper>
    )
  }
}