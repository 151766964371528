import { types } from "mobx-state-tree"
import moment from "moment"

export const UserDataModel = types.model("UserData").props({
  language: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  role: types.optional(types.string, "")
})

export const DepartmentsDataModel = types.model("DepartmentsData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Mawi - Italy"),
  patients: types.optional(types.number, 0),
  staff: types.optional(types.number, 0),
  created: types.maybe(types.number),
  description: types.optional(types.string, "")
})

export const ManagersDataModel = types.model("ManagersData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Mawi - Italy"),
  status: types.optional(types.boolean, false),
  created: types.maybe(types.number)
})

export const SortedDepartmentsDataModel = types.model("SortedDepartmentsData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Mawi - Italy"),
  patients: types.optional(types.number, 0),
  staff: types.optional(types.number, 0),
  created: types.maybe(types.number),
  sorted: types.optional(types.boolean, true),
  description: types.optional(types.string, "")
})

export const SortedManagersDataModel = types.model("SortedManagersData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Mawi - Italy"),
  status: types.optional(types.boolean, false),
  created: types.maybe(types.number),
  sorted: types.optional(types.boolean, true)
})

export const NewDepartmentDataModel = types.model("NewDepartmentData").props({
  name: types.optional(types.string, ""),
  description: types.optional(types.string, "")
})

export const NewManagerDataModel = types.model("NewManagerData").props({
  name: types.optional(types.string, ""),
  email: types.optional(types.string, "")
})

export const constructUser = (dto) => {
  let user = UserDataModel.create({
    ...dto,
    role: dto.role[0].toUpperCase() + dto.role.slice(1)
  })
  return user
}

export const constructDepartments = (dto) => {
  return DepartmentsDataModel.create({
    id: dto.id,
    name: dto.name,
    staff: dto.staff_number,
    patients: dto.patients_number,
    created: +moment(dto.date_created),
    description: dto.description,
  })
}

export const constructManagers = (dto) => {
  return ManagersDataModel.create({
    id: dto.id,
    name: dto.name,
    status: dto.is_activated,
    created: +moment(dto.date_added)
  })
}

export type UserData = typeof UserDataModel.Type
export type DepartmentsData = typeof DepartmentsDataModel.Type
export type SortedDepartmentsData = typeof SortedDepartmentsDataModel.Type
export type NewDepartmentData = typeof NewDepartmentDataModel.Type
export type ManagersData = typeof ManagersDataModel.Type
export type SortedManagersData = typeof SortedManagersDataModel.Type
export type NewManagerData = typeof NewManagerDataModel.Type