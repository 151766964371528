import { types } from "mobx-state-tree"
import moment from "moment"

export const CompaniesDataModel = types.model("CompaniesData").props({
  id: types.optional(types.string, ""),
  company_type: types.optional(types.string, ""),
  name: types.optional(types.string, "Mawi - Italy"),
  departments: types.maybe(types.number),
  managers: types.optional(types.number, 0),
  created: types.maybe(types.number),
  description: types.optional(types.string, "")
})

export const SortedCompaniesDataModel = types.model("SortedCompaniesData").props({
  id: types.optional(types.string, ""),
  company_type: types.optional(types.string, ""),
  name: types.optional(types.string, "Mawi - Italy"),
  departments: types.optional(types.number, 0),
  managers: types.optional(types.number, 0),
  created: types.maybe(types.number),
  sorted: types.optional(types.boolean, true),
  description: types.optional(types.string, "")
})

export const NewCompanyDataModel = types.model("NewCompanyData").props({
  name: types.optional(types.string, ""),
  company_type: types.optional(types.string, ""),
  description: types.optional(types.string, "")
})

export const constructCompanies = (dto) => {
  return CompaniesDataModel.create({
    id: dto.id,
    company_type: dto.company_type ? dto.company_type : "",
    name: dto.name,
    departments: dto.departments_number,
    managers: dto.managers_number,
    created: +moment(dto.date_created),
    description: dto.description
  })
}

export const constructSortedCompanies = (dto) => {
  return SortedCompaniesDataModel.create({
    id: dto.id,
    company_type: dto.company_type ? dto.company_type : "",
    name: dto.name,
    departments: dto.departments_number,
    managers: dto.managers_number,
    created: +moment(dto.date_created),
    description: dto.description,
    sorted: true
  })
}

export type CompaniesData = typeof CompaniesDataModel.Type
export type SortedCompaniesData = typeof SortedCompaniesDataModel.Type
export type NewCompanyData = typeof NewCompanyDataModel.Type