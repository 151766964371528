import { AuthApi } from "./domains/auth-api"
import { OrganizationApi } from "./domains/organization-api"
import { CompanyApi } from "./domains/company-api"
import { DepartmentApi } from "./domains/department-api"
import { ManagerApi } from "./domains/manager-api"
import { DashboardApi } from "./domains/dashboard-api"
import { MeasurementApi } from "./domains/measurement-api"
import { ReportsApi } from "./domains/reports-api"
import { DeviceApi } from "./domains/device-api"

/**
 * Manages all requests to the API.
 */
export class Api {
  auth: AuthApi
  company: CompanyApi
  organization: OrganizationApi
  department: DepartmentApi
  manager: ManagerApi
  dashboard: DashboardApi
  measurement: MeasurementApi
  reports: ReportsApi
  device: DeviceApi

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    this.auth = new AuthApi()
    this.organization = new OrganizationApi()
    this.company = new CompanyApi()
    this.department = new DepartmentApi()
    this.manager = new ManagerApi()
    this.dashboard = new DashboardApi()
    this.measurement = new MeasurementApi()
    this.reports = new ReportsApi()
    this.device = new DeviceApi()
  }
}
