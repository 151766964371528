import { styled, theme } from "../../shared/theme"
import { Container, Row, Col, Button } from "react-bootstrap"

export const Wrapper = styled.div`
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.colors.bgColor};
`

export const Header = styled.div`
  height: 65px;
  width: 100%;
  padding: 13px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #38699E;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const HeaderTitleText = styled.div`
  color: white;
  padding-left: 10px;
  padding-right: 10px;
`

export const HeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify};
  align-items: center;
  width: 30%;
`

export const HeaderBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 8px;
  border: ${(props) => props.isBorder ? "1px solid #FFFFFF" : "none"};
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  cursor: pointer;
`

export const DashboardWrapper = styled.div`
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 5%;
  padding-right: 5%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const DashboardTitle = styled.span`
  width: 100%;
  padding-bottom: 15px;
  text-align: start;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #38699E;
`

export const DashboardContainer = styled(Container)`
  min-height: 90%;
  min-width: 100%;
  position: relative;
  border: 0.7px solid rgb(0,0,0,0.2);
  background-color: white;
  border-radius: 4px;
`
export const CompanyTitle = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 36px;
  text-align: center;
  color: #212121;
`

export const DashboardHeaderRow = styled(Row)`
  display: flex;
  align-items: baseline;
`

export const DashboardName = styled(Col)`
  font-size: 14px;
`

export const DashboardDate = styled(Col)`
  text-align: end; 
  font-size: 14px;
`

export const ChartBtn = styled(Button)`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
color: #38699E;
background-color: transparent;
border: 1px solid #E0E0E0;
:hover {
  border-radius: 4px;
  color: #38699E;
  background-color: #F5F5F5;
  box-shadow: none;
}
`

export const Btn = styled.div`
  text-align: center;
  display: inline-block;
  border-radius: 4px;
  background-color: ${props => props.bgColor};
  color: ${props => props.color};
  border: ${props => props.border && props.color ? `1px solid ${props.color}` : 'none'};
  padding: 5px 15px;
`

export const TableItem = styled(Row)`
  border-bottom: 1px solid #E0E0E0;
  margin-left: -30px;
  margin-right: -30px; 
  position: relative;
  cursor: pointer;
  :hover {
    background: rgba(0,0,0,0.1);
  }
`

export const Measurment = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #38699E;
  margin-top: 15px;
`

export const IDWrapper = styled.div`
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #757575;
`

export const LinkWrapper = styled.div`
  a:not(:last-child) {
    margin-right: 15px;
  }
`;

export const Link = styled.a`
  color: #007bff !important;
  font-weight: ${props => props.href ? '700' : '400'};
  pointer-events: ${props => props.href ? 'all' : 'none'};

  :hover {
    text-decoration: none;
  }
`;