import React from "react"
import {
  FormWrapper,
  StepTitle,
  DepartmentsCancelBtn,
  DepartmentsAddBtn,
  Control} from "../add_patient.presets"
import { Form, DropdownButton, Dropdown } from "react-bootstrap"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from '@date-io/date-fns'
import { ErrorMessageWrapper, ErrorMessageText, GenderBtn } from "../../branch/branch.presets"
import PhoneInput from "react-phone-input-2"
import moment from "moment"

interface IFirstStep {
  MRN: string;
  gender: string;
  phone: string;
  supervisor: string;
  firstName: string;
  lastName: string;
  birthday: string;
  supervisor_id: string;
}

class FirstStep extends React.Component<{
  isMRNRegistered: any,
  mrnExists: boolean,
  goNext: (obj) => void,
  goPrev: () => void,
  fBtnTitle: string,
  sBtnTitle: string,
  supervisors: any,
  data: IFirstStep,
  changeValue: (field: string, value: string) => void,
}>{

  state = {
    errorMRN: "",
    errorLastName: "",
    errorFirstName: "",
    errorSupervisor: "",
    errorPhone: "",
    errorGender: "",
    errorBirthday: ""
  }

  onDateChange = (value: any) => {
    this.props.changeValue("birthday", value)
  }

  goNext = async () => {
    const state = {
      errorMRN: "",
      errorFirstName: "",
      errorLastName: "",
      errorGender: "",
      errorPhone: "",
      errorSupervisor: "",
      errorBirthday: ""
    }
    const defaultError = "This field is required."
    await this.props.isMRNRegistered(this.props.data.MRN)
    if (this.props.mrnExists) {
      state.errorMRN = "This MRN already exists."
    }
    if (this.props.data.firstName.length === 0) {
      state.errorFirstName = defaultError
    }
    if (this.props.data.lastName.length === 0) {
      state.errorLastName = defaultError
    }
    if (this.props.data.gender.length === 0) {
      state.errorGender = defaultError
    }
    if (this.props.data.birthday.length === 0) {
      state.errorBirthday = defaultError
    }
    if (this.props.data.birthday == "Invalid date") {
      state.errorBirthday = "Invalid date"
    }
    if (
      this.props.data.phone.length == 0
    ) {
      state.errorPhone = 'Invalid phone format'
    }
    if (Object.values(state).find(item => item.length > 0)) {
      this.setState(state)
      return
    } else {
      this.setState(state)
      this.props.goNext({
        mrn: this.props.data.MRN || undefined,
        supervisor_name: this.props.data.supervisor !== 'Choose your supervisor' ? this.props.data.supervisor : undefined,
        supervisor_id: this.props.data.supervisor_id || undefined,
        phone: this.props.data.phone.length < 4 ? undefined : this.props.data.phone,
        gender: this.props.data.gender,
        first_name: this.props.data.firstName,
        last_name: this.props.data.lastName,
        birthday: this.props.data.birthday
      })
    }
  }

  renderErrorMessage = (type: string) => {
    return(
      <ErrorMessageWrapper>
        <ErrorMessageText>
          {this.state[type]}
        </ErrorMessageText>
      </ErrorMessageWrapper>
    )
  }

  renderMRN = () => {
    return(
      <Form.Group controlId="formMRN">
        <Form.Label style={{color: "#212121"}}>
          Patient MRN
        </Form.Label>
        <Control
          showerror={this.state.errorMRN.length ? "true" : "false"}
          onFocus={() => this.setState({errorMRN: ""})}
          type="text"
          placeholder={"Your MRN"}
          value={this.props.data.MRN}
          onChange={(e) => this.props.changeValue("MRN", e.target.value)}
        />
        {this.renderErrorMessage("errorMRN")}
      </Form.Group>
    )
  }

  // renderEmail = () => {
  //   return(
  //     <Form.Group controlId="formEmail">
  //       <Form.Label style={{color: "#212121"}}>
  //         * Email
  //       </Form.Label>
  //       <Control
  //         showerror={this.state.errorEmail.length ? "true" : "false"}
  //         onFocus={() => this.setState({errorEmail: ""})}
  //         type="email"
  //         placeholder={"Your Email"}
  //         onChange={(e) => this.changeValue("email", e)}
  //       />
  //       {this.renderErrorMessage("errorEmail")}
  //     </Form.Group>
  //   )
  // }

  renderFNAme = () => {
    return(
      <Form.Group controlId="formFirstName">
        <Form.Label style={{color: "#212121"}}>
          <span style={{color: "red"}}>*</span> First name
        </Form.Label>
        <Control
          showerror={this.state.errorFirstName.length ? "true" : "false"}
          onFocus={() => this.setState({errorFirstName: ""})}
          type="text"
          placeholder={"Your First name"}
          value={this.props.data.firstName}
          onChange={(e) => this.props.changeValue("firstName", e.target.value)}
        />
        {this.renderErrorMessage("errorFirstName")}
      </Form.Group>
    )
  }

  renderLName = () => {
    return(
      <Form.Group controlId="formLastName">
        <Form.Label style={{color: "#212121"}}>
          <span style={{color: "red"}}>*</span> Last name
        </Form.Label>
        <Control
          showerror={this.state.errorLastName.length ? "true" : "false"}
          onFocus={() => this.setState({errorLastName: ""})}
          type="text"
          placeholder={"Your Last name"}
          value={this.props.data.lastName}
          onChange={(e) => this.props.changeValue("lastName", e.target.value)}
        />
        {this.renderErrorMessage("errorLastName")}
      </Form.Group>
    )
  }

  renderSex = () => {
    return(
      <Form.Group controlId="formBiological">
        <Form.Label style={{color: "#212121"}}>
          <span style={{color: "red"}}>*</span> Sex
        </Form.Label>
        <div style={{display: "flex", flexDirection: "row"}}>
          <GenderBtn
            showgender={this.props.data.gender === "male" ? "true" : "false"}
            onClick={() => this.setState({errorGender: ""},() => this.props.changeValue("gender", "male"))}
          >Male</GenderBtn>
          <GenderBtn
            showgender={this.props.data.gender === "female" ? "true" : "false"}
            onClick={() => this.setState({errorGender: ""},() => this.props.changeValue("gender", "female"))}
          >Female</GenderBtn>
        </div>
        {this.renderErrorMessage("errorGender")}
      </Form.Group>
    )
  }

  renderDate = () => {
    return(
      <Form.Group controlId="formBirthday">
        <Form.Label style={{color: "#212121"}}>
          <span style={{color: "red"}}>*</span> Date of birth
        </Form.Label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            format="dd-MM-yyyy"
            margin="normal"
            onFocus={() => {
              this.setState({birthdayError: ""})
              const el: any = document.querySelectorAll(".MuiFormControl-marginNormal")
              el[0].setAttribute("style", "border: 1.5px solid #38699E!important;");
            }}
            onBlur={() => {
              const el: any = document.querySelectorAll(".MuiFormControl-marginNormal")
              el[0].setAttribute("style", "border: 1px solid lightgray!important;");
            }}
            id="date-picker-inline"
            placeholder="dd-mm-yyyy"
            value={this.props.data.birthday ? moment(this.props.data.birthday) : null}
            onChange={this.onDateChange}
            InputProps={{ style: {width: "100%", textAlign: "center", paddingLeft: "10px", cursor: "pointer"}, disableUnderline: true }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        {this.renderErrorMessage("errorBirthday")}
      </Form.Group>
    )
  }

  renderPhone = () => {
    return(
      <Form.Group controlId="formGroupEmployeePhone">
        <Form.Label style={{color: "#212121"}}>
          Phone number
        </Form.Label>
        <PhoneInput
          country="it"
          value={this.props.data.phone}
          inputProps={{style: {width: "100%"}}}
          onFocus={() => this.setState({errorPhone: ""})}
          onChange={phone => {
            this.props.changeValue("phone", phone)
          }}
        />
        {this.renderErrorMessage("errorPhone")}
      </Form.Group>
    )
  }

  renderSupervisor = () => {
    return(
      <Form.Group controlId="formGroupRole">
        <Form.Label style={{color: "#212121"}}>
          Supervisors
        </Form.Label>
        <DropdownButton id="dropdown-basic-button" title={this.props.data.supervisor}>
          {this.props.supervisors && this.props.supervisors.map((item, index) => <Dropdown.Item
            key={index}
            onClick={() => {
              this.setState({errorSupervisor: ""})
              this.props.changeValue("supervisor_id", item.id)
            }
          }>{item.name}</Dropdown.Item>)}
        </DropdownButton>
        {this.renderErrorMessage("errorSupervisor")}
      </Form.Group>
    )
  }

  renderFirstStep = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Patient information
          </StepTitle>
          {this.renderMRN()}
          {/* {this.renderEmail()} */}
          {this.renderFNAme()}
          {this.renderLName()}
          {this.renderSex()}
          {this.renderDate()}
          {this.renderPhone()}
          {this.renderSupervisor()}
        </FormWrapper>
      </div>
    )
  }

  renderBtns = () => {
    return(
      <div style={{width: "400px", margin: "20px auto", display: "flex", justifyContent: "space-between"}}>
        <DepartmentsCancelBtn
          variant="outline-secondary"
          onClick={() => this.props.goPrev()}
        >
          {this.props.fBtnTitle}
        </DepartmentsCancelBtn>
        <DepartmentsAddBtn
          variant="primary"
          onClick={() => this.goNext()}
          disabled={false}
        >
          {this.props.sBtnTitle}
        </DepartmentsAddBtn>
      </div>
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  render(){
    return <>
      {this.renderFirstStep()}
      {this.line()}
      {this.renderBtns()}
    </>
  }
}

export default FirstStep