import React from "react"
import Checkbox from '@material-ui/core/Checkbox';
import { observer } from "mobx-react";

@observer
export class CheckBox extends React.Component<{
  globalCheck: boolean,
  reset: boolean
  onChange: (e) => void
},{isChecked: boolean}>{

  state = {
    isChecked: this.props.globalCheck
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.globalCheck !== this.props.globalCheck){
      this.setState({isChecked: nextProps.globalCheck})
    }
    if(this.props.reset) this.setState({isChecked: false})
  }

  render(){
    return <Checkbox
      style={{padding: 0, margin: 0, marginTop: "15px"}}
      checked={this.state.isChecked}
      onChange={(e) => {
        this.setState({isChecked: e.target.checked})
        this.props.onChange(e.target.checked)
      }}
      size="small"
      color="default"
    />
  }
}