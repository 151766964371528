import React from 'react';
import classnames from 'classnames';
import FormControl from 'react-bootstrap/FormControl';
import defaultTimezones from './timezones.json';

import './index.css';
import { observer } from 'mobx-react';

const UP_KEY = 38;
const DOWN_KEY = 40;
const ENTER_KEY = 13;
const RETURN_KEY = 14;
const ESCAPE_KEY = 27;

@observer
class TimezonePicker extends React.Component<{
  timezones: any,
  value: string,
  onChange: (e) => void,
  onBlur: () => void,
  onFocus: () => void,
  onKeyDown: (e) => void,
  absolute: boolean,
  className?: any,
  placeholder: string,
  style: any,
  disabled: boolean,
},{
  value: string
  focused: number
  isOpen: boolean
  timezones: any
}>{

  static defaultProps = {
    absolute: true,
    defaultValue: '',
    initialValue: '',
    placeholder: '',
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    style: {},
    disabled: false,
    timezones: defaultTimezones,
  };

  refInput: any
  mouseX: any
  mouseY: any
  disableMouse: boolean
  list: any
  constructor(props) {
    super(props);
    this.mouseX = ""
    this.mouseY = ""
    this.disableMouse = false
    this.state = {
      focused: 0,
      isOpen: false,
      timezones: props.timezones,
      value: props.value || props.defaultValue || props.initialValue,
    };
  }

  zoneCompare(key, filter) {
    return key.toLowerCase().indexOf(filter.toLowerCase().trim()) !== -1;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      const value = nextProps.value.includes("GMT") ? nextProps.value : this.getTimezone(nextProps.value) || '';
      this.setState({ value });
    }
  }

  getTimezone = (query) => {
    if (!query) return null;
    return Object.keys(this.props.timezones).find(zone => zone === query || this.props.timezones[zone] === query);
  }

  filterItems = (filter) => {
    const { timezones } = this.props;

    if (filter.trim().length === 0) return timezones;
    const filteredTimezones = {};
    Object.keys(timezones).forEach((key) => {
      if (this.zoneCompare(key, filter) || this.zoneCompare(timezones[key], filter)) {
        filteredTimezones[key] = timezones[key];
      }
    });

    return filteredTimezones;
  }

  handleBlur = () => {
    this.setState({ isOpen: false });
    this.props.onBlur && this.props.onBlur();
  }

  handleFilterChange = (e) => {
    const timezones = this.filterItems(e.target.value);

    this.setState({
      focused: 0,
      isOpen: Object.keys(timezones).length > 0,
      value: e.target.value || '',
      timezones,
    });
  }

  handleFocus = (ev) => {
    const { value, timezones } = this.state;
    if (ev) {
      ev.target.select();
    }
    if (value) {
      const keyz = Object.keys(timezones);
      for (let i = 0; i < keyz.length; ++i) {
        if (keyz[i] === value || timezones[keyz[i]] === value) {
          this.setState(
            { isOpen: true, focused: i },
            () => { this.scrollToIndex(i); },
          );

          this.props.onFocus && this.props.onFocus();

          return;
        }
      }
    }
    this.setState({ isOpen: true });
    this.props.onFocus && this.props.onFocus();
  }

  handleKeyPress = (e) => {
    const { focused, timezones } = this.state;

    if (e.which === UP_KEY || e.which === DOWN_KEY) {
      e.preventDefault();

      let newFocused;

      if (e.which === UP_KEY) {
        newFocused = focused === 0 ? Object.keys(timezones).length - 1 : focused - 1;
      } else {
        newFocused = focused === Object.keys(timezones).length - 1 ? 0 : focused + 1;
      }

      this.setState({ focused: newFocused });

      this.scrollToIndex(newFocused);
    } else if (e.which === ENTER_KEY || e.which === RETURN_KEY) {
      this.handleSelect(focused);
    } else if (e.which === ESCAPE_KEY) {
      this.refInput.blur();
      this.handleBlur();
    }

    this.props.onKeyDown(e);
  }

  handleMouseEnter = (idx, e) => {
    if (e.pageX !== this.mouseX || e.pageY !== this.mouseY) {
      if (this.disableMouse) {
        this.disableMouse = false;
        this.mouseY = e.pageY;
        return;
      }
      this.mouseX = e.pageX;
      this.mouseY = e.pageY;
      this.setState({ focused: idx });
    }
  }

  handleSelect = (index) => {
    const { timezones } = this.state;

    const key = Object.keys(timezones)[index] || '';
    const value = timezones[key] || '';
    this.props.onChange(value)

    this.setState({
      focused: 0,
      isOpen: false,
      timezones: this.props.timezones,
      value: this.props.value || key,
    }, () => { this.refInput.blur() });
  }

  scrollToIndex = (idx) => {
    const index = Math.max(0, idx - 3);

    this.disableMouse = true;
    this.list.scrollTop = this.list.children[index].offsetTop;
  }

  render() {
    const { isOpen, value } = this.state;
    const {
      absolute,
      className,
      placeholder,
      style,
      disabled,
      ...restProps
    } = this.props;
    const rest = Object.assign({}, restProps);

    ['defaultValue', 'initialValue', 'onBlur', 'onChange', 'onFocus', 'onKeyDown', 'timezones', 'value']
      .forEach(p => delete rest[p]);

    const isSelected = !isOpen && value;
    const containerClasses = classnames(
      'timezone-picker',
      className,
      {
        'timezone-picker-open': isOpen,
        'timezone-picker-selected': isSelected,
      },
    );

    const listClass = classnames('timezone-picker-list', `timezone-picker-list-${absolute ? 'abs' : 'rel'}`);

    const timezones = Object.keys(this.state.timezones).map((zone, idx) => {
      const focused = this.state.focused === idx;

      return (
        <li
          className={classnames('timezone-picker-list-item', { 'timezone-picker-list-item-active': focused })}
          key={zone}
          onMouseDown={() => { this.handleSelect(idx); }}
          onMouseEnter={(e) => { this.handleMouseEnter(idx, e); }}
          onTouchStart={() => { this.handleSelect(idx); }}
        >
          {zone}
        </li>
      );
    });

    return (
      <div className={containerClasses} style={style}>
        <div className="timezone-picker-textfield">
          <FormControl
            onBlur={this.handleBlur}
            onChange={this.handleFilterChange}
            onFocus={this.handleFocus}
            disabled={disabled}
            onKeyDown={this.handleKeyPress}
            placeholder={placeholder}
            ref={(c) => { this.refInput = c; }}
            value={this.getTimezone(value) || value}
            {...rest}
          />
          {isOpen && (
            <ul className={listClass} ref={(c) => { this.list = c; }}>
              {timezones}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default TimezonePicker;