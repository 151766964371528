import React, { Fragment } from "react"
import { TableHeaderRow, TableHeaderCol, TableDepartmentContainer, TableBodyRow, TableBodyCol } from "../../department/department.presets";
// import { TableHeaderRow, TableHeaderCol, TableDepartmentContainer, TableBodyRow, TableBodyCol, TableBodyDiv, TableSubBodyDiv, DepartmentsSubHeaderItem, DepartmentsSubHeaderItemText, TableEditBtn, TableFooterRow } from "../../department/department.presets";
// import moment from "moment";
import { Btn, Measurment, IDWrapper, Link, LinkWrapper } from '../user.presets';
import { observer } from "mobx-react";
import moment from "moment";

@observer
export class MeasurmentsTable extends React.Component<{
  t: any
  data: any
}>{

  capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  departmentHeaders = [
    {type: "status", desc: this.props.t("dashboard.branch.patient.measurmentsTable.status")},
    {type: "id", desc: this.props.t("dashboard.branch.patient.measurmentsTable.id")},
    {type: "dateStart", desc: this.props.t("dashboard.branch.patient.measurmentsTable.dateStart")},
    {type: "dateEnd", desc: this.props.t("dashboard.branch.patient.measurmentsTable.dateEnd")},
    {type: "links", desc: this.props.t("dashboard.branch.patient.measurmentsTable.links")},
  ]

  mapStatusToName = (status) => {
    switch(status){
      case "pending": return "Pending";
      case "green": return "Normal";
      case "red": return "Risk";
      default: return ""
    }
  }

  backgroundByStatus = (status) => {
    switch(status){
      case "pending": return "white";
      case "green": return "rgba(46, 125, 50, 0.1)";
      case "red": return "rgba(191, 54, 12, 0.1)";
      default: return "rgba(46, 125, 50, 0.1)"
    }
  }
  
  colorTextByStatus = (status) => {
    switch(status){
      case "pending": return "#38699E";
      case "green": return "#2E7D32";
      case "red": return "#BF360C";
      default: return "#2E7D32"
    }
  }

  // renderLoadMoreBtn = () => {
  //   const{t}=this.props
  //   return(
  //     false ? <TableFooterRow>
  //         <TableEditBtn
  //           variant="outline-secondary"
  //           onClick={() => this.props.departmentStore.loadMore(this.props.type)}
  //         >{t("dashboard.department.dailyDashboard.table.loadMore")}</TableEditBtn>
  //       </TableFooterRow> : null
  //   )
  // }

  renderHeader = () => {
    const { data } = this.props;
    return(
      data.length ? (
        <TableHeaderRow>
          {
            this.departmentHeaders.map((item, index) => (
                <TableHeaderCol
                  key={index}
                >
                  {item.desc}
                </TableHeaderCol>
            )
          )}
        </TableHeaderRow> 
        ) : null
    )
  }

  render(){
      const { data } = this.props;
      return(
        <Fragment>
        {this.renderHeader()}
        <TableDepartmentContainer>
          {data.map((item, index) => {
            return(
              <TableBodyRow key={index}>
                <TableBodyCol>
                  <Btn
                    bgColor={this.backgroundByStatus(item.status)}
                    color={this.colorTextByStatus(item.status)}
                    border
                  >
                    {this.mapStatusToName(item.status)}
                  </Btn>
                  <Measurment>Cardio Measurment</Measurment>
                </TableBodyCol>
                <TableBodyCol><IDWrapper>{item.device_serial_number}</IDWrapper></TableBodyCol>
                <TableBodyCol>{item.date_start ? moment(item.date_start).format("DD/M/YYYY HH:MM") : "-"}</TableBodyCol>
                <TableBodyCol>{item.due_time ? moment(item.due_time).format("DD/M/YYYY HH:MM") : "-"}</TableBodyCol>
                <TableBodyCol>
                  <LinkWrapper>
                    <Link href={item.url} target="_blank">PDF</Link>
                    <Link href={item.measurement_url} target="_blank">ECG</Link>
                  </LinkWrapper>
                </TableBodyCol>
              </TableBodyRow>
            )}
          )}
        </TableDepartmentContainer>
        { /* this.renderLoadMoreBtn() */}
        </Fragment>
      )
  }
}