import { ApiBase } from "../api-base"

export class DepartmentApi extends ApiBase {

  get apiName() {
    return "department"
  }

  async initDepartment(data) {
    return await this.post("/create/", { body: data})
  }

  async getDepartments(id_company: string) {
    const resp = await this.post("/list/", {body: {id_company}})
    return resp.data
  }

  async getSchedule(id_department) {
    const resp = await this.post("/get_schedule/", {body: {id_department}})
    return resp.data
  }

  async setSchedule(data) {
    return await this.post("/set_schedule/", { body: data})
  }

  async getDepartmentsMoveList(data) {
    return await this.post("/list_department_names/", {body: data})
  }

  async getStaffList(data) {
    return await this.post("/list_staff/", {body: data})
  }

  async getStaffDepartments() {
    return await this.get("/list_staff_departments/")
  }

  async inviteStaff(data) {
    return await this.post("/invite_staff/", {body: data})
  }

  async listSupervisors(data) {
    return await this.post("/list_supervisors/", {body: data})
  }
}
