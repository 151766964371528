import React from 'react'
import { Chart } from 'react-charts'

export default function ChartComponent({recordType, measurements, t}) {

  const getZone = (zone) => {
    if(zone) {
      if(zone === "green") return "#2E7D32"
      if(zone === "red") return "#BF360C"
      if(zone === "grey") return "grey"
    }
    return "#2E7D32"
  }
  const tempData = measurements.filter(item => item.analysis_result && item.heart_rate).map(item => {
    const date = new Date(item.date)
    return {y: date.getDate(), x: +`${date.getHours()}.${date.getMinutes()}`, zone: getZone(item.analysis_result.zone)}
  })

  const hardXTime = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
  const newData: any = []
  hardXTime.forEach(x => {
    const data = tempData.find(item => item.x === x)
    if(!data) {
      newData.push({x})
    }else{
      newData.push(data)
    }
  })

  const getRecordType = () => {
    switch(recordType){
      case "all": return tempData
      case "red": return tempData.filter(item => item.zone === "#BF360C")
      case "grey": return tempData.filter(item => item.zone === "grey")
      default: return tempData
    }
  }

  const data = [
    {
      label: "ECG",
      data: getRecordType()
    }
  ]

  const axes = [
    { primary: true, type: 'linear', position: 'left', hardMin: 0, hardMax: 24 },
    { position: 'bottom', type: 'linear', stacked: false, hardMin: 1, hardMax: 31 }
  ]

  const lineChart = (
    <div
      style={{
        position: "relative",
        paddingTop: "20px",
        width: '100%',
        height: '350px'
      }}
    >
      <div style={{position: "absolute", top: "-5px", right: "-20px", display: "flex"}}>
        <div style={{display: "flex", alignItems: "center", padding: "0 10px"}}>
          <div style={{width: "10px", height: "10px", borderRadius: "10px", background: "#2E7D32", marginRight: "10px"}}/>
          <span
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#757575"
          }}>Good record</span>
        </div>
        <div style={{display: "flex", alignItems: "center", padding: "0 10px"}}>
          <div style={{width: "10px", height: "10px", borderRadius: "10px", background: "#BF360C", marginRight: "10px"}}/>
          <span
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#757575"
          }}>Bad record</span>
        </div>
        <div style={{display: "flex", alignItems: "center", padding: "0 10px"}}>
          <div style={{width: "10px", height: "10px", borderRadius: "10px", background: "grey", marginRight: "10px"}}/>
          <span
          style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#757575"
          }}>Unclassified record</span>
        </div>
      </div>

      <div style={{position: "absolute", top: "-5px", fontSize: "12px"}}>
        Time
      </div>
      <div style={{position: "absolute", bottom: "-12px", right: "-10px", fontSize: "12px"}}>
        Days
      </div>

      <Chart
        data={data}
        axes={axes}
        getSeriesStyle={series => ({
          color: `url(#gradient)`,
          opacity: series.index === 1 ? 0 : 1
        })}
        getDatumStyle={datum => ({
          strokeWidth: 3,
          color: datum.originalDatum.zone
        })}
        primaryCursor
        secondaryCursor
      />
    </div>
  )
  return lineChart
}