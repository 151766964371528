import { types } from "mobx-state-tree"
import moment from "moment"

export const UserDataModel = types.model("UserData").props({
  language: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
  role: types.optional(types.string, "")
})

export const SupervisorsDataModel = types.model("ManagersData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Mawi - Italy"),
  status: types.optional(types.boolean, false),
  created: types.maybe(types.number),
  role: types.maybe(types.string)
})

export const SortedSupervisorsDataModel = types.model("ManagersData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Mawi - Italy"),
  status: types.optional(types.boolean, false),
  created: types.maybe(types.number),
  role: types.maybe(types.string),
  sorted: types.optional(types.boolean, true)
})

export const EmployeeDataModel = types.model("EmployeeData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Jorden Stokes"),
  status: types.optional(types.string, "Pending"),
  screening: types.optional(types.string, ""),
  phone: types.optional(types.string, ""),
  created: types.maybe(types.number)
})

export const EmployeeStatusDataModel = types.model("EmployeeStatusData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Jorden Stokes"),
  is_registered: types.optional(types.boolean, false),
  last_measurement_date: types.optional(types.string, ""),
  status: types.optional(types.string, ""),
  created: types.maybe(types.number),
  account_type: types.optional(types.string, ""),
})

export const SortedEmployeeDataModel = types.model("SortedEmployeeData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Jorden Stokes"),
  status: types.optional(types.string, "Pending"),
  screening: types.optional(types.string, ""),
  created: types.maybe(types.number),
  phone: types.optional(types.string, ""),
  sorted: types.optional(types.boolean, true)
})


export const SortedEmployeeStatusDataModel = types.model("SortedEmployeeStatusData").props({
  id: types.optional(types.string, ""),
  name: types.optional(types.string, "Jorden Stokes"),
  is_registered: types.optional(types.boolean, false),
  last_measurement_date: types.optional(types.string, ""),
  status: types.optional(types.string, ""),
  created: types.maybe(types.number),
  sorted: types.optional(types.boolean, true),
  account_type: types.optional(types.string, ""),
})


export const NewEmployeeDataModel = types.model("NewEmployeeData").props({
  first_name: types.optional(types.string, ""),
  last_name: types.optional(types.string, ""),
  height: types.optional(types.string, ""),
  weight: types.optional(types.string, ""),
  birthday: types.maybe(types.number),
  gender: types.optional(types.string, ""),
  code: types.optional(types.string, ""),
  phone: types.optional(types.string, "")
})

export const ScheduleDataModel = types.model("ScheduleData").props({
  from: types.optional(types.string, "12:30"),
  to: types.optional(types.string, "13:30")
})

export const constructUser = (dto) => {
  let user = UserDataModel.create({
    ...dto,
    role: dto.role[0].toUpperCase() + dto.role.slice(1)
  })
  return user
}

const remapStatus = (status) => {
  switch(status) {
    case "red": return "risk";
    case "green": return "normal";
    case "pending": return "pending";
    default: return "pending";
  }
}

const remapStatusEmployee = (dto) => {
  if(dto){
    if (dto.is_registered && dto.last_measurement_date) {
      return "onboard"
    } else if (dto.is_registered && !dto.last_measurement_date) {
      return "registered"
    } else {
      return "created"
    }
  }
    return ""
}

export const constructEmployee = (dto) => {
  return EmployeeDataModel.create({
    id: dto.id_unique ? dto.id_unique : "123-456-789",
    name: `${dto.first_name} ${dto.last_name}`,
    status: dto.status ? remapStatus(dto.status) : "normal",
    screening: dto.screening_date ? dto.screening_date : "",
    phone: dto.phone ? dto.phone : "",
    created: dto.date_add ? +moment(dto.date_add) : 0
  })
}

export const constructStatusEmployee = (dto) => {
  return EmployeeStatusDataModel.create({
    id: dto.id_unique ? dto.id_unique : "123-456-789",
    name: `${dto.first_name} ${dto.last_name}`,
    is_registered: dto.is_registered ? dto.is_registered : false,
    status: remapStatusEmployee(dto),
    last_measurement_date: dto.last_measurement_date ? dto.last_measurement_date : "",
    created: dto.date_add ? +moment(dto.date_add) : 0,
    account_type: dto.account_type ? dto.account_type : "",
  })
}

export const constructSupervisors = (dto) => {
  return SupervisorsDataModel.create({
    id: dto.id,
    name: dto.name,
    status: dto.is_activated,
    created: +moment(dto.date_added),
    role: dto.role
  })
}

export const constructSortedSupervisors = (dto) => {
  return SortedSupervisorsDataModel.create({
    id: dto.id,
    name: dto.name,
    status: dto.is_activated,
    created: +moment(dto.date_added),
    role: dto.role,
    sorted: true
  })
}

export type UserData = typeof UserDataModel.Type
export type EmployeeData = typeof EmployeeDataModel.Type
export type SortedEmployeeData = typeof SortedEmployeeDataModel.Type
export type NewEmployeeData = typeof NewEmployeeDataModel.Type
export type ScheduleData = typeof ScheduleDataModel.Type
export type EmployeeStatusData = typeof EmployeeStatusDataModel.Type
export type SortedEmployeeStatusData = typeof SortedEmployeeStatusDataModel.Type
export type SupervisorsDataModel = typeof SupervisorsDataModel.Type
export type SortedSupervisorsDataModel = typeof SortedSupervisorsDataModel.Type