import { types, getEnv, getRoot } from "mobx-state-tree"
import { EmployeeDataModel } from "../department/types/dashboard.model"
import { Environment } from "../../app/environment"
import { RootStore } from "../../app/root-store"
import moment from "moment"

export const UserStoreModel = types.model("UserStore")
  .props({
    currentEmployee: types.optional(EmployeeDataModel, {}),
    measurements: types.optional(types.frozen(), [])
  })
  .actions((self) => ({
    setMeasurements(measurements){
      self.measurements = measurements
    }
  }))
  .actions((self) => ({
    async getTemperature(type: "day" | "month" | "year", date) {
      const getDateByType = () => {
        switch(type) {
          case "day": return moment(date).format("YYYY-MM-DD");
          case "month": return moment(date).format("YYYY-MM");
          case "year": return moment(date).format("YYYY");
        }
      }
      const env: Environment = getEnv(self)
      const root = getRoot(self) as RootStore
      const {currentEmployee} = root.globalStore
      if(!currentEmployee) return
      const dataReq = {
        id_user: root.globalStore.currentEmployee.unique_id,
        id_department: root.globalStore.currentDepartment.id,
        date: getDateByType(),
        type
      }
      const managerResp = await env.api.measurement.getTemperature(dataReq)
      if(managerResp) {
        const data = managerResp.data
        if(!data) return
        self.setMeasurements(data.data)
      }
    }
  }))

export type UserStore = typeof UserStoreModel.Type