import React from "react"
import { DepartmentsCancelBtn, DepartmentsAddBtn, FormWrapper, StepTitle, Control } from "../../add_patient/add_patient.presets"
import { Form, DropdownButton, Dropdown } from "react-bootstrap"
import { Checkbox } from "@material-ui/core"
import { ErrorMessageWrapper, ErrorMessageText } from "../../department/department.presets"
import _ from "lodash"

export class FiltersPopup extends React.Component<{
  goNext: (obj) => void,
  goPrev: () => void,
  currentFilters: any,
  fBtnTitle: string,
  sBtnTitle: string
}>{

  state = {
    AFIb: false,
    AFIbheartrate: false,
    unclassResult: false,
    heartRateUnder: false,
    heartRateUnderValue: "",
    heartRateOver: false,
    heartRateOverValue: "",
    tempUnder: false,
    tempUnderValue: "",
    tempOver: false,
    tempOverValue: "",
    pressureUnderSys: false,
    pressureUnderSysValue: "",
    pressureOverSys: false,
    pressureOverSysValue: "",
    pressureUnderDis: false,
    pressureUnderDisValue: "",
    pressureOverDis: false,
    pressureOverDisValue: "",
    SpO2Under: false,
    SpO2UnderValue: "",
    AFIbheartrateValue: "",
    recordings: "Custom"
  }

  componentDidMount(){
    const state = {
      ...this.state,
      recordings: this.props.currentFilters ? this.props.currentFilters.name : "Custom"
    }
    this.props.currentFilters && this.props.currentFilters.filters && this.props.currentFilters.filters.forEach(element => {
      if(element.value === "afib"){ state.AFIb = true }
      if(element.value === "unclassified"){ state.unclassResult = true }
      if(element.key === "heart_rate"){
        if(element.filter === "lt"){
          state.heartRateUnder = true
          state.heartRateUnderValue = element.value
        } else {
          state.heartRateOver = true
          state.heartRateOverValue = element.value
        }
      }
      if(element.key === "temperature"){
        if(element.filter === "lt"){
          state.tempUnder = true
          state.tempUnderValue = element.value
        } else {
          state.tempOver = true
          state.tempOverValue = element.value
        }
      }
      if(element.key === "bp_diastolic"){
        if(element.filter === "lt"){
          state.pressureUnderDis = true
          state.pressureUnderDisValue = element.value
        } else {
          state.pressureOverDis = true
          state.pressureOverDisValue = element.value
        }
      }
      if(element.key === "bp_systolic"){
        if(element.filter === "lt"){
          state.pressureUnderSys = true
          state.pressureUnderSysValue = element.value
        } else {
          state.pressureOverSys = true
          state.pressureOverSysValue = element.value
        }
      }
      if(element.key === "saturation"){
        state.SpO2Under = true
        state.SpO2UnderValue = element.value
      }
    })
    this.setState(state)
  }

  changeValue = (type: string, value: string | boolean | any) => {
    this.setState({
      [type]: value
    })
  }

  checkValue = (data) => {
    const state: any = {
      heartRateUnderValueError: "",
      heartRateOverValueError: "",
      tempUnderValueError: "",
      tempOverValueError: "",
      pressureUnderSysValueError: "",
      pressureOverSysValueError: "",
      pressureOverDisValueError: "",
      pressureUnderDisValueError: "",
      SpO2UnderValueError: ""
    }
    const defaultError = "This field is required."
    if (this.state.heartRateUnder && this.state.heartRateUnderValue.length === 0) {
      state.heartRateUnderValueError = defaultError
    }
    if (this.state.heartRateOver && this.state.heartRateOverValue.length === 0) {
      state.heartRateOverValueError = defaultError
    }
    if (this.state.tempUnder && this.state.tempUnderValue.length === 0) {
      state.tempUnderValueError = defaultError
    }
    if (this.state.tempOver && this.state.tempOverValue.length === 0) {
      state.tempOverValueError = defaultError
    }
    if (this.state.pressureUnderSys && this.state.pressureUnderSysValue.length === 0) {
      state.pressureUnderSysValueError = defaultError
    }
    if (this.state.pressureOverSys && this.state.pressureOverSysValue.length === 0) {
      state.pressureOverSysValueError = defaultError
    }
    if (this.state.pressureOverDis && this.state.pressureOverDisValue.length === 0) {
      state.pressureOverDisValueError = defaultError
    }
    if (this.state.pressureUnderDis && this.state.pressureUnderDisValue.length === 0) {
      state.pressureUnderDisValueError = defaultError
    }
    if (this.state.SpO2Under && this.state.SpO2UnderValue.length === 0) {
      state.SpO2UnderValueError = defaultError
    }
    if (!_.some(state, _.isEmpty)) {
      this.setState(state)
      return
    } else {
      this.props.goNext({
        name: this.state.recordings,
        filters: data
      })
    }
  }

  goNext = () => {
    const data: any = []
    if(this.state.AFIb) { data.push({key: "ecg", value: "afib", filter: "e"}) }
    if(this.state.unclassResult) { data.push({key: "ecg", value: "unclassified", filter: "e"}) }
    if(this.state.heartRateUnder) { data.push({key: "heart_rate", value: this.state.heartRateUnderValue, filter: "lt"}) }
    if(this.state.heartRateOver) { data.push({key: "heart_rate", value: this.state.heartRateOverValue, filter: "gt"}) }

    if(this.state.tempUnder) { data.push({key: "temperature", value: this.state.tempUnderValue, filter: "lt"}) }
    if(this.state.tempOver) { data.push({key: "temperature", value: this.state.tempOverValue, filter: "gt"}) }

    if(this.state.SpO2Under) { data.push({key: "saturation", value: this.state.SpO2UnderValue, filter: "lt"}) }

    if(this.state.pressureUnderDis) { data.push({key: "bp_diastolic", value: this.state.pressureUnderDisValue, filter: "lt"}) }
    if(this.state.pressureOverDis) { data.push({key: "bp_diastolic", value: this.state.pressureOverDisValue, filter: "gt"}) }
    if(this.state.pressureUnderSys) { data.push({key: "bp_systolic", value: this.state.pressureUnderSysValue, filter: "lt"}) }
    if(this.state.pressureOverSys) { data.push({key: "bp_systolic", value: this.state.pressureOverSysValue, filter: "gt"}) }
    this.checkValue(data)
  }

  renderErrorMessage = (type: string) => {
    return(
      <ErrorMessageWrapper>
        <ErrorMessageText>
          {this.state[type]}
        </ErrorMessageText>
      </ErrorMessageWrapper>
    )
  }

  changedRecordings = (type) => {
    if(type === "All"){
      this.setState({
        AFIb: true,
        AFIbheartrate: true,
        unclassResult: true,
        heartRateUnder: true,
        heartRateOver: true,
        tempUnder: true,
        tempOver: true,
        pressureUnderSys: true,
        pressureOverSys: true,
        pressureUnderDis: true,
        pressureOverDis: true,
        SpO2Under: true,
      })
    } else {
      this.setState({
        AFIb: false,
        AFIbheartrate: false,
        unclassResult: false,
        heartRateUnder: false,
        heartRateOver: false,
        tempUnder: false,
        tempOver: false,
        pressureUnderSys: false,
        pressureOverSys: false,
        pressureUnderDis: false,
        pressureOverDis: false,
        SpO2Under: false,
      })
    }
    this.changeValue("recordings", type)
  }

  renderRecordingsToFlag = () => {
    return(
      <Form.Group controlId="formRecordings">
        <Form.Label style={{color: "#212121"}}>
          Recordings to flag
        </Form.Label>
        <DropdownButton id="dropdown-basic-button" title={this.state.recordings}>
          <Dropdown.Item onClick={(e) => this.changedRecordings("All")}>All</Dropdown.Item>
          <Dropdown.Item onClick={(e) => this.changedRecordings("None")}>None</Dropdown.Item>
          <Dropdown.Item onClick={(e) => this.changedRecordings("Custom")}>Custom</Dropdown.Item>
        </DropdownButton>
      </Form.Group>
    )
  }

  renderAFIbresult = () => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <Checkbox
          style={{padding: 0, margin: 0}}
          checked={this.state.AFIb}
          onChange={(e) => this.changeValue("AFIb", e.target.checked)}
          size="small"
          color="default"
        />
        <p style={{margin: 10, cursor: "pointer"}} onClick={() => this.changeValue("AFIb", !this.state.AFIb)}>Possible AFib result</p>
        </div>
      </Form.Group>
    )
  }

  renderUnclassResult = () => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <Checkbox
          style={{padding: 0, margin: 0}}
          checked={this.state.unclassResult}
          onChange={(e) => this.changeValue("unclassResult", e.target.checked)}
          size="small"
          color="default"
        />
        <p style={{margin: 10, cursor: "pointer"}} onClick={() => this.changeValue("unclassResult", !this.state.unclassResult)}>
          Unclassified result
        </p>
        </div>
      </Form.Group>
    )
  }

  renderAFIbHeartresult = () => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.state.AFIbheartrate}
              onChange={(e) => {
                this.changeValue("AFIbheartrate", e.target.checked)
                this.changeValue("AFIbheartrateValue", "")
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.changeValue("AFIbheartrate", !this.state.AFIbheartrate)
              this.changeValue("AFIbheartrateValue", "")
            }}>Possible AFib result with heart rate over</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.state.AFIbheartrateValue}
              disabled={!this.state.AFIbheartrate}
              placeholder={"-"}
              onChange={(e) => this.changeValue("AFIbheartrateValue", e.target.value)}
            />
          </div>
        </div>
      </Form.Group>
    )
  }

  renderHeartRateUnder = () => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.state.heartRateUnder}
              onChange={(e) => {
                this.changeValue("heartRateUnder", e.target.checked)
                this.changeValue("heartRateUnderValue", "")
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.changeValue("heartRateUnder", !this.state.heartRateUnder)
              this.changeValue("heartRateUnderValue", "")
            }}>Heart rate under</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.state.heartRateUnderValue}
              disabled={!this.state.heartRateUnder}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {heartRateUnderValueError: ""},
                  () => this.changeValue("heartRateUnderValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("heartRateUnderValueError")}
      </Form.Group>
    )
  }

  renderHeartRateOver = () => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.state.heartRateOver}
              onChange={(e) => {
                this.changeValue("heartRateOver", e.target.checked)
                this.changeValue("heartRateOverValue", "")
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.changeValue("heartRateOver", !this.state.heartRateOver)
              this.changeValue("heartRateOverValue", "")
            }}>Heart rate over</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.state.heartRateOverValue}
              disabled={!this.state.heartRateOver}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {heartRateOverValueError: ""},
                  () => this.changeValue("heartRateOverValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("heartRateOverValueError")}
      </Form.Group>
    )
  }

  renderTempUnder = () => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.state.tempUnder}
              onChange={(e) => {
                this.changeValue("tempUnder", e.target.checked)
                this.changeValue("tempUnderValue", "")
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.changeValue("tempUnder", !this.state.tempUnder)
              this.changeValue("tempUnderValue", "")
            }}>Temperature under</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.state.tempUnderValue}
              disabled={!this.state.tempUnder}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {tempUnderValueError: ""},
                  () => this.changeValue("tempUnderValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("tempUnderValueError")}
      </Form.Group>
    )
  }

  inputConstructor = (type, title, error) => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.state[type]}
              onChange={(e) => {
                this.changeValue(type, e.target.checked)
                this.changeValue(`${type}Value`, "")
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.changeValue(type, !this.state[type])
              this.changeValue(`${type}Value`, "")
            }}>{title}</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.state[`${type}Value`]}
              disabled={!this.state[type]}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {[`${type}ValueError`]: ""},
                  () => this.changeValue(`${type}Value`, value)
                )
              }}
            />
          </div>
        </div>
        <div>
        {error}
        </div>
      </Form.Group>
    )
  }

  renderTempOver = () => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.state.tempOver}
              onChange={(e) => {
                this.changeValue("tempOver", e.target.checked)
                this.changeValue("tempOverValue", "")
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.changeValue("tempOver", !this.state.tempOver)
              this.changeValue("tempOverValue", "")
            }}>Temperature over</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.state.tempOverValue}
              disabled={!this.state.tempOver}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {tempOverValueError: ""},
                  () => this.changeValue("tempOverValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("tempOverValueError")}
      </Form.Group>
    )
  }

  renderSpO2Under = () => {
    return(
      <Form.Group controlId="formRecordings">
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <Checkbox
              style={{padding: 0, margin: 0}}
              checked={this.state.SpO2Under}
              onChange={(e) => {
                this.changeValue("SpO2Under", e.target.checked)
                this.changeValue("SpO2UnderValue", "")
              }}
              size="small"
              color="default"
            />
            <p style={{margin: 10, cursor: "pointer"}} onClick={() => {
              this.changeValue("SpO2Under", !this.state.SpO2Under)
              this.changeValue("SpO2UnderValue", "")
            }}>SpO2 under</p>
          </div>
          <div>
            <Control
              style={{width: "60px", height: "30px", textAlign: "center"}}
              showerror={"false"}
              type="text"
              value={this.state.SpO2UnderValue}
              disabled={!this.state.SpO2Under}
              placeholder={"-"}
              onChange={(e) => {
                const value = e.target.value
                this.setState(
                  {SpO2UnderValueError: ""},
                  () => this.changeValue("SpO2UnderValue", value)
                )
              }}
            />
          </div>
        </div>
        {this.renderErrorMessage("SpO2UnderValueError")}
      </Form.Group>
    )
  }

  renderECG = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          {this.renderRecordingsToFlag()}
          <StepTitle>
            ECG recordings
          </StepTitle>
          {this.renderAFIbresult()}
          {/* {this.renderAFIbHeartresult()} */}
          {this.renderUnclassResult()}
          {this.renderHeartRateUnder()}
          {this.renderHeartRateOver()}
        </FormWrapper>
      </div>
    )
  }

  renderTemp = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Temperature recordings
          </StepTitle>
          {/* {this.renderRecordingsToFlag()} */}
          {this.renderTempUnder()}
          {this.renderTempOver()}
        </FormWrapper>
      </div>
    )
  }

  renderSpO2 = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            SpO2 recordings
          </StepTitle>
          {/* {this.renderRecordingsToFlag()} */}
          {this.renderSpO2Under()}
        </FormWrapper>
      </div>
    )
  }

  renderPressure = () => {
    return(
      <div style={{margin: "40px auto", width: "400px"}}>
        <FormWrapper>
          <StepTitle>
            Blood pressure recordings
          </StepTitle>
          {/* {this.renderRecordingsToFlag()} */}
          <p style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#212121"
          }}>Systolic</p>
          {this.inputConstructor("pressureUnderSys", "Blood pressure under", this.renderErrorMessage("pressureUnderSysValueError"))}
          {this.inputConstructor("pressureOverSys", "Blood pressure over", this.renderErrorMessage("pressureOverSysValueError"))}
          <p style={{
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#212121"
          }}>Diastolic</p>
          {this.inputConstructor("pressureUnderDis", "Blood pressure under", this.renderErrorMessage("pressureOverSysValueError"))}
          {this.inputConstructor("pressureOverDis", "Blood pressure over", this.renderErrorMessage("pressureOverDisValueError"))}
        </FormWrapper>
      </div>
    )
  }

  renderBtns = () => {
    return(
      <div style={{width: "400px", margin: "20px auto", display: "flex", justifyContent: "space-between"}}>
        <DepartmentsCancelBtn
          variant="outline-secondary"
          onClick={() => this.props.goPrev()}
        >
          {this.props.fBtnTitle}
        </DepartmentsCancelBtn>
        <DepartmentsAddBtn
          variant="primary"
          onClick={() => this.goNext()}
          disabled={false}
        >
          {this.props.sBtnTitle}
        </DepartmentsAddBtn>
      </div>
    )
  }

  line = () => <div style={{height: "1px", background: "#E0E0E0", marginLeft: "-15px", marginRight: "-15px"}}/>

  render(){
    return <div>
      {this.renderECG()}
      {this.line()}
      {this.renderTemp()}
      {this.line()}
      {this.renderSpO2()}
      {this.line()}
      {this.renderPressure()}
      {this.line()}
      {this.renderBtns()}
    </div>
  }
}