import { ApiErrorResponse, ErrorResponseStatus, GeneralApiProblem } from "./api.types"

export function getGeneralApiProblem(response: ApiErrorResponse): GeneralApiProblem {
  if (!response) {
    return { ok: false, status: ErrorResponseStatus.cannotConnect, isUnauthorized: false }
  }

  let status: ErrorResponseStatus = ErrorResponseStatus.unknown

  if (Object.values(ErrorResponseStatus).includes(response.status)) {
    status = response.status
  }

  let isUnauthorized = [ErrorResponseStatus.unauthorized, ErrorResponseStatus.forbidden].includes(response.status)

  const apiProblemResponse: GeneralApiProblem =  {
    ok: false,
    status,
    isUnauthorized,
    message: response.data && (response.data.message || response.data.Message || response.data.error),
    data: response.data
  }

  return apiProblemResponse
}
