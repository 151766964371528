import { ApiBase } from "../api-base"

export class OrganizationApi extends ApiBase {

  get apiName() {
    return "organization"
  }

  async registration(data) {
    const params = {
      invitation_token: data.id,
      email: data.email.toLowerCase(),
      name: data.fullName,
      password: data.password,
      organization_name: data.companyName,
      organization_description: data.companyDesc,
      language: "eng"
    }
    return await this.post("/register/", { body: params }, false)
  }

}
