import React, { Fragment } from "react"
import { TableHeaderRow, TableHeaderCol, TableDepartmentContainer, TableBodyRow, TableBodyCol } from "../../department/department.presets";
import { DepartmentPopupWrapper, DepartmentPopup, DepartmentIconWrapper, FormWrapper, DepartmentPopupTitle, DepartmentCancelBtn } from "../../branch/branch.presets"
import { Icon } from "../../../icons/icon"
import { IDWrapper, Btn } from '../user.presets';
import { Button } from "react-bootstrap"
import { observer } from "mobx-react";

@observer
export class DevicesTable extends React.Component<{
  t: any
  data: any,
  medicalUserStore: any
}>{

  componentWillUnmount() {
    this.props.medicalUserStore.setSerialNumber('')
    this.props.medicalUserStore.setShowDisconnectDevicePopup(false)
  }

  capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  departmentHeaders = [
    {type: "serialNumber", desc: this.props.t("dashboard.branch.patient.devicesTable.serialNumber")},
    {type: "deviceType", desc: this.props.t("dashboard.branch.patient.devicesTable.deviceType")},
    {type: "productType", desc: this.props.t("dashboard.branch.patient.devicesTable.productType")},
    {type: "externalOrganisationId", desc: this.props.t("dashboard.branch.patient.devicesTable.externalOrganisationId")},
    {type: "", desc: ""},
  ]

  renderDisconectModal = () => {
    const {t} =this.props
    return this.props.medicalUserStore.showDisconnectDevicePopup
    ? <DepartmentPopupWrapper>
        <DepartmentPopup>
          <DepartmentIconWrapper onClick={() => {
            this.props.medicalUserStore.setShowDisconnectDevicePopup(false)
            this.props.medicalUserStore.setSerialNumber('')
          }}>
            <Icon icon="close"/>
          </DepartmentIconWrapper>
          <FormWrapper>
            <DepartmentPopupTitle>
              
            </DepartmentPopupTitle>
            <div style={{
              fontSize: "14px",
              color: "#212121",
              textAlign: "center",
              paddingBottom: "25px"
            }}>Are you sure that you want disconnect your device?</div>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
            <DepartmentCancelBtn
              variant="outline-secondary"
              onClick={() => {
                this.props.medicalUserStore.setShowDisconnectDevicePopup(false)
                this.props.medicalUserStore.setSerialNumber('')
              }}
            >{t("dashboard.cancel")}</DepartmentCancelBtn>
            <Button
              variant="outline-danger"
              onClick={() => {
                this.props.medicalUserStore.disconnectDeviceFromPatient();
              }}
            >
              Yes, disconnect
            </Button>
            </div>
          </FormWrapper>
        </DepartmentPopup>
      </DepartmentPopupWrapper>
    : null
  }
  
  renderHeader = () => {
    const { data } = this.props;
    return(
      data.length ? (
        <TableHeaderRow>
          {
            this.departmentHeaders.map((item, index) => (
                <TableHeaderCol
                  key={index}
                >
                  {item.desc}
                </TableHeaderCol>
            )
          )}
        </TableHeaderRow> 
        ) : null
    )
  }

  render(){
      const { data } = this.props;
      return(
        <Fragment>
          {this.renderDisconectModal()}
          {this.renderHeader()}
          <TableDepartmentContainer>
            {data.map((item, index) => {
              return(
                <TableBodyRow woHover key={index}>
                  <TableBodyCol mwNone><IDWrapper>{item.serial_number}</IDWrapper></TableBodyCol>
                  <TableBodyCol mwNone>{item.device_type}</TableBodyCol>
                  <TableBodyCol mwNone>{item.product_type}</TableBodyCol>
                  <TableBodyCol mwNone>{item.externalOrganisationId || '-'}</TableBodyCol>
                  <TableBodyCol mwNone>
                    {
                      item.is_disconnectable && (
                        <Btn
                          bgColor="#BF360C"
                          color="white"
                          onClick={() => {
                            this.props.medicalUserStore.setShowDisconnectDevicePopup(true)
                            this.props.medicalUserStore.setSerialNumber(item.serial_number)
                          }}
                        >
                          Disconnect
                        </Btn> 
                      )
                    }
                  </TableBodyCol>
                </TableBodyRow>
              )}
            )}
          </TableDepartmentContainer>
        </Fragment>
      )
  }
}