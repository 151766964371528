import { ApiBase } from "../api-base"

export class DeviceApi extends ApiBase {
  get apiName() {
    return "dashboard"
  }

  async isDeviceExist(data) {
    return await this.post("/is_exists/", {body: data}, true, "device")
  }

  async connectDevice(data) {
    return await this.post("/connect/", {body: data}, true, "device")
  }

  async listPatientsDevices(data) {
    return await this.post("/list_patient_devices/", {body: data}, true, "device")
  }

  async disconnectDevice(data) {
    return await this.post("/disconnect/", {body: data}, true, "device")
  }
}