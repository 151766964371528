import React from "react"
import { 
  Wrapper,
  DashboardWrapper,
  DashboardTitle,
  DashboardContainer,
  CompanyTitle,
  DashboardHeaderRow,
  DashboardName,
  DashboardDate,
} from "./user.presets"
import { smartInject } from "../../utils/smartInject"
import { Tabs, Tab, Row, Col } from "react-bootstrap"
import { GlobalProps } from "../global/global.props"
import { DashboardHeader } from "../../components/dashboard-header"
import { withTranslation, WithTranslation } from 'react-i18next'
import moment from "moment"
import { Icon } from "../../icons/icon"
import { DepartmentsSubHeaderItem, DepartmentsSubHeaderItemText } from "../department/department.presets"
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'
import ChartComponent from "./components/chart"
import "./style.css"
import { Breadcrumbs } from "../../shared/Breadcrumbs"
var QRCode = require('qrcode.react')

@(withTranslation() as any)
@smartInject(GlobalProps)
export class User extends React.Component<GlobalProps & WithTranslation> {
  state = {
    date: this.props.globalStore.userInfoCurrentDate || moment(),
    currentBtn: "Day"
  }

  componentDidMount(){
    this.chooseBtn({btnTitle: "Day"})
    this.setCustomDateStyles()
  }

  setCustomDateStyles = () => {
    const el: any = document.querySelectorAll(".MuiInputBase-input")
    const el2: any = document.querySelectorAll(".MuiFormControl-root")
    el[0].setAttribute("id", "CustomUserMuiWrapper");
    el2[0].setAttribute("id", "CustomUserContainer");
  }

  removeCustomDateStyles = () => {
    const el: any = document.querySelectorAll(".MuiInputBase-input")
    const el2: any = document.querySelectorAll(".MuiFormControl-root")
    el[0].setAttribute("id", "");
    el2[0].setAttribute("id", "");
  }

  componentWillUnmount(){
    this.removeCustomDateStyles()
    this.props.globalStore.setCurrent(this.props.globalStore.todaysDate, "userInfoCurrentDate")
  }

  chooseBtn = async (item) => {
    this.setState({currentBtn: item.btnTitle})
    await this.props.userStore.getTemperature(item.btnTitle.toLowerCase(), this.state.date)
  }

  chooseDate = (e) => {
    this.setState({date: e}, async () => {
      await this.props.userStore.getTemperature(
        (this.state.currentBtn.toLowerCase() as any),
        this.state.date
      )
    })
  }

  getDateInfo = () => {
    if(this.state.currentBtn === "Day"){
      return {
        format: "day",
        dateTitle: moment(this.state.date).format("MMM DD") === moment().format("MMM DD")
        ? `Today, ${moment(this.state.date).format("MMM DD")}`
        : `${moment(this.state.date).format("YYYY")}, ${moment(this.state.date).format("MMM DD")}`
      }
    }
    if(this.state.currentBtn === "Month"){
      return {
        format: "month",
        dateTitle: `${moment(this.state.date).format("YYYY")}, ${moment(this.state.date).format("MMM")}`
      }
    }
    if(this.state.currentBtn === "Year"){
      return {
        format: "year",
        dateTitle: `Year, ${moment(this.state.date).format("YYYY")}`
      }
    }
    return {
      format: "day",
      dateTitle: moment(this.state.date).format("MMM DD") === moment().format("MMM DD")
      ? `Today, ${moment(this.state.date).format("MMM DD")}`
      : `${moment(this.state.date).format("YYYY")}, ${moment(this.state.date).format("MMM DD")}`
    }
  }

  renderInfo = () => {
    const { currentEmployee } = this.props.globalStore
    if(!currentEmployee) return
    const {t}=this.props
    const titles = [
      t("dashboard.user.personalInfo.id"),
      t("dashboard.user.personalInfo.name"),
      t("dashboard.user.personalInfo.gender"),
      t("dashboard.user.personalInfo.birthday"),
      t("dashboard.user.personalInfo.height"),
      t("dashboard.user.personalInfo.weight")
    ]
    const values = [
      currentEmployee.unique_id,
      `${currentEmployee.first_name} ${currentEmployee.last_name}`,
      currentEmployee.gender,
      currentEmployee.birthday,
      currentEmployee.height,
      currentEmployee.weight
    ]
    return(
      <Row style={{padding: "40px 20px"}}>
        <Row style={{width: "400px"}}>
          <Col>
            {titles.map(item => <div key={item} style={{paddingBottom: "20px"}}>{item}</div>)}
          </Col>
          <Col style={{position: "relative"}}>
            <div
              style={{position: "absolute", right: 40, cursor: "pointer"}}
              onClick={() => navigator.clipboard.writeText(currentEmployee.unique_id)}>
              <Icon icon={"copy"}/>
            </div>
            {values.map(item => <div key={item} style={{paddingBottom: "20px", color: "black"}}>{item}</div>)}
          </Col>
        </Row>
        <Col style={{flexDirection: "row-reverse", display: "flex"}}>
          <QRCode value={currentEmployee.unique_id} />
        </Col>
      </Row>
    )
  }

  renderStatus = () => {
    const { currentEmployee } = this.props.globalStore
    if(!currentEmployee) return
    const {status, screening} = currentEmployee
    const {t}=this.props
    function backgroundByStatus(status){
      switch(status){
        case "pending": return "white";
        case "normal": return "rgba(46, 125, 50, 0.1)";
        case "risk": return "rgba(191, 54, 12, 0.1)";
        default: return "white"
      }
    }
    function colorTextByStatus(status){
      switch(status){
        case "pending": return "#38699E";
        case "normal": return "#2E7D32";
        case "risk": return "#BF360C";
        default: return "#38699E"
      }
    }
    return(
      <DepartmentsSubHeaderItem
        style={{width: "55%", marginTop: "15px"}}
        background={backgroundByStatus(status)}
        border={"0.5px solid rgba(0,0,0,0.1)"}
      >
        <DepartmentsSubHeaderItemText color={colorTextByStatus(status)}
          style={{paddingRight: "10px"}}
        >
          {
            !screening && status === "risk" 
            ? t("dashboard.user.noMeasured")
            : status.charAt(0).toUpperCase() + status.slice(1)
          }
        </DepartmentsSubHeaderItemText>
      </DepartmentsSubHeaderItem>
    )
  }

  renderHealthboarder = () => {
    const { currentEmployee } = this.props.globalStore
    if(!currentEmployee) return
    const textStyle = {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#212121"           
    }
    const {t}=this.props
    return(
      <Row style={{padding: "40px 20px"}}>
        <Row style={{width: "400px"}}>
          <Col>
            <div style={textStyle}>
              {t("dashboard.user.healthboard.firstTitle")}
            </div>
            {this.renderStatus()}
          </Col>
          <Col style={{position: "relative"}}>
            <div style={textStyle}>
              {t("dashboard.user.healthboard.secondTitle")}
            </div>
            <div style={{
              color: "#212121",
              marginTop: "20px"
            }}>{currentEmployee.screening}</div>
          </Col>
        </Row>
      </Row>
    )
  }

  renderChartHeader = () => {
    const textStyle = {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#212121"           
    }
    const {t}=this.props
    const btns = [{
      id: 1,
      btnTitle: "Day",
      title: t("dashboard.user.healthboard.calendar.day")
    }, {
      id: 2,
      btnTitle: "Month",
      title: t("dashboard.user.healthboard.calendar.month")
    }, {
      id: 3,
      btnTitle: "Year",
      title: t("dashboard.user.healthboard.calendar.year")
    }]
    const dateInfo: any = this.getDateInfo()
    return(
      <Row style={{display: "flex", alignItems: "center"}}>
        <Col>
          <div style={textStyle}>{t("dashboard.user.healthboard.thirdTitle")}</div>
        </Col>
        <Col md={4} style={{alignItems: "center", justifyContent: "center", display: "flex"}}>
          <span
            style={{color: "#38699E", cursor: "pointer", paddingRight: "10px", paddingBottom: "4px", fontWeight: 500}}
            onClick={() => this.chooseDate(+moment(this.state.date).subtract(1, dateInfo.format))}>
              {"<"}
          </span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              value={this.state.date}
              onChange={(e) => this.chooseDate(e)}
              labelFunc={() => dateInfo.dateTitle}
              InputProps={{ style: {textAlign: "center", color: "#38699E", cursor: "pointer"}, disableUnderline: true }}
            />
          </MuiPickersUtilsProvider>
          <span
            style={{color: "#38699E", cursor: "pointer", paddingLeft: "10px", paddingBottom: "4px", fontWeight: 500}}
            onClick={() => this.chooseDate(+moment(this.state.date).add(1, dateInfo.format))}>
              {">"}
          </span>
        </Col>
        <Col>
          <Row>
            {btns.map((item, key) => <Col
              key={key}
              onClick={() => this.chooseBtn(item)}
              style={{
                border: item.btnTitle === this.state.currentBtn ? "1px solid #38699E" : "",
                cursor: "pointer", padding: "6.5px 10px", background: "#F5F5F5", borderRadius: "4px",
                textAlign: "center", margin: "0 10px", color: "#38699E"
              }}
            >{item.title}</Col>)}
          </Row>
        </Col>
      </Row>
    )
  }

  renderChart = () => {
    return(
      <ChartComponent
        measurements={this.props.userStore.measurements}
        typeChart={this.state.currentBtn}
        t={this.props.t}
      />
    )
  }

  renderUserInfo = () => {
    const {t}=this.props
    return(
      <Tabs defaultActiveKey="Healthboard" id="uncontrolled-tab-example"
        style={{
          marginRight: "-15px",
          marginLeft: "-15px"
        }}
        onSelect={(k) => {
          this.setState(
            {tab: k},
            () => {if(k === "Healthboard") this.setCustomDateStyles()}
          )
        }}
      >
        <Tab eventKey="Healthboard" title={t("dashboard.user.tabs.first")} style={{padding: "20px"}}>
          {this.renderHealthboarder()}
          {this.renderChartHeader()}
          {this.renderChart()}
        </Tab>
        <Tab eventKey="PersonalInformation" title={t("dashboard.user.tabs.second")}>
          {this.renderInfo()}
        </Tab>
      </Tabs>
    )
  }

  constructNavData = () => {
    const { t } = this.props
    const { currentCompany, user, currentDepartment, currentEmployee } = this.props.globalStore
    const fName = currentEmployee ? currentEmployee.first_name : ""
    const lName = currentEmployee ? currentEmployee.last_name : ""
    const ownerData = [
      user.role === "owner" && {
        goTo: "companies",
        name: t("dashboard.user.home")
      },{
        goTo: "departments",
        name: currentCompany ? currentCompany.name : ""
      },{
        goTo: "department",
        name: currentDepartment ? currentDepartment.name : ""
      },{
        name: `${fName} ${lName}`
      }
    ]
    const doctorData = [{
      goTo: "departments",
      name: t("dashboard.user.home")
    },{
      goTo: "department",
      name: currentDepartment ? currentDepartment.name : ""
    },{
      name: `${fName} ${lName}`
    }]
    const defaultData = [
      {
        name: `${fName} ${lName}`
      }
    ]
    switch (user.role) {
      case "owner": return ownerData
      case "companyowner": return ownerData
      case "doctor": return doctorData
      case "govdoctor": return doctorData
      case "support": return doctorData
      default: return defaultData
    }
  }

  renderNavigation = () => {
    return(
      <Breadcrumbs
        data={this.constructNavData()}
        globalStore={this.props.globalStore}
      />
    )
  }

  renderDashboard = () => {
    const { currentEmployee, currentDepartment } = this.props.globalStore
    const { t } = this.props
    const name = currentDepartment ? currentDepartment.name : ""
    const fName = currentEmployee ? currentEmployee.first_name : ""
    const lName = currentEmployee ? currentEmployee.last_name : ""
    const date = currentDepartment
    ? moment(currentDepartment.created).format("DD/MM/YYYY")
    : moment().format("DD/MM/YYYY")
    return(
      <DashboardWrapper>
        <DashboardTitle>
          {this.renderNavigation()}
        </DashboardTitle>
        <DashboardContainer>
          <DashboardHeaderRow>
            <DashboardName>
              {name} {t("dashboard.user.department")}
            </DashboardName>
            <Col>
              <CompanyTitle>{fName} {lName}</CompanyTitle>
            </Col>
            <DashboardDate>
              {t("dashboard.user.dateText")}: {date}
            </DashboardDate>
          </DashboardHeaderRow>
          {this.renderUserInfo()}
        </DashboardContainer>
      </DashboardWrapper>
    )
  }

  render(){
    return(
      <Wrapper>
        <DashboardHeader
          globalStore={this.props.globalStore}
          t={this.props.t}
        />
        {this.renderDashboard()}
      </Wrapper>
    )
  }
}