import { Api } from "../services/api"
import { History } from "history"

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  api: Api
  navigation: History<any>
}
