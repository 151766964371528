import { ErrorPresenter } from "../../lib/error-presenter/error-presenter"

let errorPresenter = new ErrorPresenter("ui")

export const emailRule = {
  email: {
    message: errorPresenter.getMessageTemplate("FIELD_IS_INVALID")({ label: "Email Address" })
  }
}

export const dateRule = {
  format: {
    /* eslint-disable-next-line */
    pattern: /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/,
    message: errorPresenter.getMessageTemplate("DATE_IS_NOT_VALID")({ label: "Date" })
  }
}

export const passwordRule = {
  format: {
    pattern: /^(?=.*[a-z])(?=.*\d)(?=.*[#$"№;.?><|}{£€±§^+=!*()@%&]).{8,}$/,
    message: errorPresenter.getMessageTemplate("FIELD_IS_TOO_SHORT")({ label: "Password", arg: 8 })
  }
}

export const phoneRule = {
  format: {
    pattern: /^(\+?1)?[2-9]\d{2}[2-9](?!11)\d{6}$/,
    message: errorPresenter.getMessageTemplate("FIELD_IS_INVALID")({ label: "Phone number" })
  }
}

export const alphabeticRule = (field?: string) => ({
  format: {
    pattern: /^([A-Za-zА-Яа-я ,]+)$/,
    message: errorPresenter.getMessageTemplate("FIELD_DOES_NOT_ALPHABETIC")({ label: field || "This field" })
  },
  presence: {
    allowEmpty: false,
    message: errorPresenter.getMessageTemplate("FIELD_IS_REQUIRED")({ label: field || "This field" })
  }
})

export const requiredRule = (field?: string) => ({
  presence: {
    allowEmpty: false,
    message: errorPresenter.getMessageTemplate("FIELD_IS_REQUIRED")({ label: field || "This field" })
  }
})
